import React from "react";
import SessionManager from "../components/IAM/SessionManager";

const withSessionManager = WrappedComponent => props => {
	return (
		<SessionManager>
			<WrappedComponent {...props} />
		</SessionManager>
	);
};

export default withSessionManager;