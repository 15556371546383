import Box from "@mui/material/Box";
import Button from "../components/Button/Button";
import DefaultTemplate from "./templates/DefaultTemplate";
import { Link } from "react-router-dom";
import React from "react";
import Typography from "@mui/material/Typography";
import { connect } from "react-redux";
import {
	PATHNAME_HOME_VIEW_AUTHENTICATED,
	PATHNAME_HOME_VIEW_UNAUTHENTICATED
} from "../config/common";

const NoContentView = props => {

	let homeURL = PATHNAME_HOME_VIEW_UNAUTHENTICATED;

	const {
		authAccessExpiresAt,
		isAuthenticated
	} = props;

	if (isAuthenticated && authAccessExpiresAt) {
		homeURL = PATHNAME_HOME_VIEW_AUTHENTICATED;
	}

	return (
		<DefaultTemplate>
			<Box
				sx={{
					alignItems: "center",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					margin: "7rem auto 4rem",
					maxWidth: "sm"
				}}
			>
				<Typography align="center" fontSize={24} gutterBottom={true} component="h1" variant="h2">
					Something went wrong...
				</Typography>
				<Box
					alt="Page Not Found"
					component="img"
					src={`${process.env.PUBLIC_URL}/assets/general/not-found.gif`}
					sx={{
						display: "block",
						height: 225,
						margin: "0 auto 2rem",
						width: 235
					}}
				/>
				<Typography align="center" fontSize={16} gutterBottom={true} paragraph={true} >
					We encountered an unexpected error.
				</Typography>
				<Typography align="center" fontSize={12} gutterBottom={true} paragraph={true} >
					404 - Page Not Found
				</Typography>
				<Button component={Link} to={homeURL} variant="text">Return to Home Page</Button>
			</Box>
		</DefaultTemplate>
	);
};

const mapStateToProps = state => {
	return {
		authAccessExpiresAt: state.auth.authAccessExpiresAt,
		isAuthenticated: state.auth.isAuthenticated
	};
};

export default connect(mapStateToProps)(NoContentView);