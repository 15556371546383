import { arrayMergeOverwrite } from "./common";
import { createReducer } from "@reduxjs/toolkit";
import merge from "deepmerge";
import { resetData, setData } from "../actions/profile";

const initialState = {
	data: null,
	interfaceErrors: [],
	isFetchFailed: false,
	isFetching: false,
	isUpdatingContactEmail: false,
	isUpdatingPaperlessPreferences: false,
	paperlessPreferencesPayload: {},
	systemErrors: []
};

const profileReducer = createReducer(initialState, (builder) => {
	builder
		.addCase(resetData, (state, action) => {
			return { ...initialState, ...action.payload };
		})
		.addCase(setData, (state, action) => {
			return merge(state, action.payload, { arrayMerge: arrayMergeOverwrite });
		});
});

export default profileReducer;