import Box from "@mui/material/Box";
import Button from "../../components/Button/Button";
import DefaultTemplate from "../templates/DefaultTemplate";
import { Form } from "@pheaa/channels-component-library";
import IAMErrorMessages from "../../components/ErrorMessages/IAMErrorMessages";
import React from "react";
import TaskComplete from "../../components/Task/TaskComplete";
import TaskHeader from "../../components/Task/TaskHeader";
import Typography from "@mui/material/Typography";
import { connect } from "react-redux";
import { resetData } from "../../store/actions/auth";
import useNavigateQSA from "../../utils/hooks/useNavigateQSA";
import {
	IAM_FLOW_AUTHENTICATE,
	IAM_FLOW_RESET_PASSWORD,
	getFlowRootStage,
	getPathForFlowAndStage,
	hasAuthId,
	withAuthCondition
} from "../utils/iamUtils";

const ResetPasswordCompleteView = props => {

	const { resetAuthData } = props;

	const navigate = useNavigateQSA();

	const handleSubmit = () => {
		resetAuthData({
			currentFlow: IAM_FLOW_AUTHENTICATE,
			currentStage: getFlowRootStage(IAM_FLOW_AUTHENTICATE)
		});
		navigate(`/${getPathForFlowAndStage(IAM_FLOW_AUTHENTICATE, getFlowRootStage(IAM_FLOW_AUTHENTICATE))}`);
	};

	return (
		<DefaultTemplate isTask={true}>
			<TaskHeader
				closeResetsAuthFlow={true}
				closeTaskDestination={`/${getPathForFlowAndStage(IAM_FLOW_AUTHENTICATE, getFlowRootStage(IAM_FLOW_AUTHENTICATE))}`}
				title="Password Reset"
			/>
			<IAMErrorMessages />
			<TaskComplete>
				<Typography
					align="center"
					gutterBottom={true}
					paragraph={true}
				>
					You have successfully created your password.
				</Typography>
				<Form
					id="defaultFormNarrow"
					onSubmit={handleSubmit}
				>
					<Box
						mb={1.75}
						mt={5.625}
					>
						<Button
							type="submit"
							variant="contained"
						>
							Continue to Sign In
						</Button>
					</Box>
				</Form>
			</TaskComplete>
		</DefaultTemplate>
	);
};

const mapStateToProps = state => {
	return {};
};

const mapDispatchToProps = dispatch => {
	return {
		resetAuthData: data => dispatch(resetData(data))
	};
};

export default withAuthCondition(connect(mapStateToProps, mapDispatchToProps)(ResetPasswordCompleteView), hasAuthId, IAM_FLOW_RESET_PASSWORD);