import Button from "../../components/Button/Button";
import Container from "@mui/material/Container";
import DefaultTemplate from "../templates/DefaultTemplate";
import IAMErrorMessages from "../../components/ErrorMessages/IAMErrorMessages";
import React from "react";
import SelectDevice from "../../components/IAM/SelectDevice";
import TaskHeader from "../../components/Task/TaskHeader";
import Typography from "@mui/material/Typography";
import useNavigateQSA from "../../utils/hooks/useNavigateQSA";
import {
	IAM_FLOW_AUTHENTICATE,
	IAM_STG_KEY_NPPI,
	getFlowRootStage,
	getPathForFlowAndStage,
	hasAuthId,
	withAuthCondition
} from "../utils/iamUtils";

const AuthSelectDeviceView = props => {

	const navigate = useNavigateQSA();

	const handleClick = () => {
		navigate(`/${getPathForFlowAndStage(IAM_FLOW_AUTHENTICATE, IAM_STG_KEY_NPPI)}`, { replace: true });
	};

	return (
		<DefaultTemplate isTask={true}>
			<TaskHeader
				closeResetsAuthFlow={true}
				closeTaskDestination={`/${getPathForFlowAndStage(IAM_FLOW_AUTHENTICATE, getFlowRootStage(IAM_FLOW_AUTHENTICATE))}`}
				title="Sign In"
			/>
			<Container maxWidth="sm">
				<Typography
					align="center"
					gutterBottom={true}
					paragraph={true}
				>
					You have signed in from an unrecognized device. We will send you a verification code to confirm your identity.
				</Typography>
				<Typography
					align="center"
					gutterBottom={true}
					paragraph={true}
					variant="h5"
				>
					We will send the verification code to:
				</Typography>
				<IAMErrorMessages />
				<SelectDevice />
				<Typography
					align="center"
					gutterBottom={true}
					paragraph={true}
					variant="h6"
				>
					I do not have access to these contact methods.
				</Typography>
				<Button
					align="center"
					onClick={handleClick}
				>
					Try Another Method
				</Button>
			</Container>
		</DefaultTemplate>
	);
};

export default withAuthCondition(AuthSelectDeviceView, hasAuthId, IAM_FLOW_AUTHENTICATE);