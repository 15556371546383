import * as authEvents from "../../store/events/auth";
import Box from "@mui/material/Box";
import Button from "../Button/Button";
import { Form } from "@pheaa/channels-component-library";
import FormTextField from "../Form/FormTextField";
import { HTML_ATTR_PATTERN_PASSCODE } from "../../config/common";
import IAMErrorMessages from "../ErrorMessages/IAMErrorMessages";
import React from "react";
import Typography from "@mui/material/Typography";
import { connect } from "react-redux";
import { setData as setAuthData } from "../../store/actions/auth";
import useNavigateQSA from "../../utils/hooks/useNavigateQSA";
import {
	IAM_FLOW_RECOVER_ID,
	IAM_FLOW_REGISTER,
	IAM_FLOW_RESET_PASSWORD,
	IAM_STG_KEY_NPPI,
	IAM_STG_KEY_SECOND_FACTOR_SELECTION,
	getPathForFlowAndStage
} from "../../views/utils/iamUtils";

const VerifyPasscode = props => {

	let noAccessMessageElem = null;
	let tryAnotherMethodElem = null;

	const {
		currentFlow,
		isPasscodeMaxDeliveryAttempts,
		isPasscodeMaxVerificationAttempts,
		isPendingFlowRestart,
		isPendingIAM,
		secondFactorDevice,
		setData,
		submitResendPasscode,
		submitVerifyPasscode
	} = props;

	const navigate = useNavigateQSA();

	const handleResend = e => {
		submitResendPasscode();
	};

	const handleSubmit = (e, formData) => {
		submitVerifyPasscode(formData);
	};

	const handleTryAnotherMethod = () => {
		setData({ interfaceErrors: [], systemErrors: [] });

		if (currentFlow === IAM_FLOW_RECOVER_ID) {
			navigate(`/${getPathForFlowAndStage(currentFlow, IAM_STG_KEY_NPPI)}`, { replace: true });
		} else {
			navigate(`/${getPathForFlowAndStage(currentFlow, IAM_STG_KEY_SECOND_FACTOR_SELECTION)}`, { replace: true });
		}
	};

	if (currentFlow !== IAM_FLOW_REGISTER) {
		noAccessMessageElem = (
			<Typography
				align="center"
				paragraph={true}
			>
				I do not have access to this contact method.
			</Typography>
		);
		tryAnotherMethodElem = (
			<Box mb={1.75}>
				<Button
					disabled={isPendingIAM || isPendingFlowRestart}
					onClick={handleTryAnotherMethod}
					variant="text"
				>
					Try Another Method
				</Button>
			</Box>
		);
	}

	return (
		<React.Fragment>
			<Typography
				align="center"
				gutterBottom={true}
				paragraph={true}
			>
				Your verification code has been sent to:<br />{secondFactorDevice.description}
			</Typography>
			<Form
				id="defaultFormNarrow"
				onSubmit={handleSubmit}
			>
				<IAMErrorMessages />
				<FormTextField
					name="passcode"
					required={true}
					pattern={HTML_ATTR_PATTERN_PASSCODE}
					title="Verification Code"
				/>
				<Box
					mb={1.75}
					mt={5.625}
				>
					<Button
						disabled={isPendingIAM || isPendingFlowRestart || isPasscodeMaxVerificationAttempts || ([IAM_FLOW_REGISTER, IAM_FLOW_RESET_PASSWORD].includes(currentFlow) && isPasscodeMaxDeliveryAttempts)}
						type="submit"
						variant="contained"
					>
						Next
					</Button>
				</Box>
				<Box
					mb={5.625}
					mt={1.75}
				>
					<Button
						disabled={isPendingIAM || isPendingFlowRestart || isPasscodeMaxDeliveryAttempts}
						onClick={handleResend}
						variant="outlined"
					>
						Resend Code
					</Button>
				</Box>
				{noAccessMessageElem}
				{tryAnotherMethodElem}
				<Typography
					align="center"
					paragraph={true}
					variant="small"
				>
					If you have not received the email containing the verification code, you may want to check your spam folder.
				</Typography>
			</Form>
		</React.Fragment>
	);
};

const mapStateToProps = state => {
	return {
		currentFlow: state.auth.currentFlow,
		isPasscodeMaxDeliveryAttempts: state.auth.data.isPasscodeMaxDeliveryAttempts,
		isPasscodeMaxVerificationAttempts: state.auth.data.isPasscodeMaxVerificationAttempts,
		isPendingFlowRestart: state.auth.isPendingFlowRestart,
		isPendingIAM: state.auth.isPendingIAM,
		secondFactorDevice: state.auth.data.secondFactorDevice
	};
};

const mapDispatchToProps = dispatch => {
	return {
		setData: data => dispatch(setAuthData(data)),
		submitResendPasscode: data => dispatch(authEvents.submitResendPasscode(data)),
		submitVerifyPasscode: data => dispatch(authEvents.submitVerifyPasscode(data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyPasscode);