import DefaultTemplate from "../templates/DefaultTemplate";
import React from "react";
import RememberDevice from "../../components/IAM/RememberDevice";
import TaskHeader from "../../components/Task/TaskHeader";
import {
	IAM_FLOW_AUTHENTICATE,
	IAM_FLOW_REGISTER,
	getFlowRootStage,
	getPathForFlowAndStage,
	hasAuthId,
	withAuthCondition
} from "../utils/iamUtils";

const RegistrationRememberDeviceView = props => {
	return (
		<DefaultTemplate isTask={true}>
			<TaskHeader
				closeResetsAuthFlow={true}
				closeTaskDestination={`/${getPathForFlowAndStage(IAM_FLOW_AUTHENTICATE, getFlowRootStage(IAM_FLOW_AUTHENTICATE))}`}
				title="Device Not Recognized"
			/>
			<RememberDevice />
		</DefaultTemplate>
	);
};

export default withAuthCondition(RegistrationRememberDeviceView, hasAuthId, IAM_FLOW_REGISTER);