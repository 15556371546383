import DefaultTemplate from "../templates/DefaultTemplate";
import React from "react";
import TaskHeader from "../../components/Task/TaskHeader";
import VerifyInfo from "../../components/IAM/VerifyInfo";
import {
	IAM_FLOW_AUTHENTICATE,
	IAM_FLOW_RESET_PASSWORD,
	getFlowRootStage,
	getPathForFlowAndStage,
	hasAuthId,
	withAuthCondition
} from "../utils/iamUtils";

const ResetPasswordVerifyInfoView = props => {
	return (
		<DefaultTemplate isTask={true}>
			<TaskHeader
				closeResetsAuthFlow={true}
				closeTaskDestination={`/${getPathForFlowAndStage(IAM_FLOW_AUTHENTICATE, getFlowRootStage(IAM_FLOW_AUTHENTICATE))}`}
				title="Personal Information"
			/>
			<VerifyInfo fullSSN={true} suppressDOB={true} />
		</DefaultTemplate>
	);
};

export default withAuthCondition(ResetPasswordVerifyInfoView, hasAuthId, IAM_FLOW_RESET_PASSWORD);