import Typography from "@mui/material/Typography";
import { connect } from "react-redux";
import useNavigateQSA from "../../utils/hooks/useNavigateQSA";
import { Form, format, isNumber, isObject } from "@pheaa/channels-component-library";
import { IAM_FLOW_AUTHENTICATE, getFlowRootStage, getPathForFlowAndStage } from "../../views/utils/iamUtils";
import React, { useEffect, useState } from "react";

const calculateSecondsRemaining = expiryEpoch => {
	let now = new Date().getTime();
	let diff = expiryEpoch - now;
	let returnVal = null;

	if (diff > 0) {
		returnVal = Math.floor(diff / 1000);
	}

	return returnVal;
};

const AccountLocked = props => {

	const { authLockExpiry } = props;
	const [secondsRemaining, setSecondsRemaining] = useState(calculateSecondsRemaining(authLockExpiry));
	const formatted = format.asHourMinSec(secondsRemaining);
	const navigate = useNavigateQSA();
	const secondsRemainingTitle = isObject(formatted) ? formatted.title : null;
	const secondsRemainingValue = isObject(formatted) ? formatted.value : null;


	useEffect(() => {
		let timer;

		if (isNumber(secondsRemaining)) {
			timer = setTimeout(() => {
				setSecondsRemaining(calculateSecondsRemaining(authLockExpiry));
			}, 1000);
		} else {
			navigate(`/${getPathForFlowAndStage(IAM_FLOW_AUTHENTICATE, getFlowRootStage(IAM_FLOW_AUTHENTICATE))}`);
		}

		return () => {
			clearTimeout(timer);
			timer = null;
		};
	}, [
		authLockExpiry,
		navigate,
		secondsRemaining
	]);

	return (

		<Form id="defaultFormNarrow">
			<Typography
				align="center"
				gutterBottom={true}
				paragraph={true}
			>
				Your account is temporarily locked. You will be able to sign in after:
			</Typography>
			<Typography
				align="center"
				gutterBottom={true}
				paragraph={true}
				title={secondsRemainingTitle}
				variant="h2"
			>
				{secondsRemainingValue}
			</Typography>
		</Form>
	);
};

const mapStateToProps = state => {
	return {
		authLockExpiry: state.auth.authLockExpiry
	};
};

export default connect(mapStateToProps)(AccountLocked);