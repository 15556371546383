import * as authEvents from "../../store/events/auth";
import Box from "@mui/material/Box";
import Button from "../../components/Button/Button";
import DefaultTemplate from "../templates/DefaultTemplate";
import { Form } from "@pheaa/channels-component-library";
import FormTextField from "../../components/Form/FormTextField";
import IAMErrorMessages from "../../components/ErrorMessages/IAMErrorMessages";
import { InteractionProcessTimeoutA11y } from "../../components/IAM/A11y";
import React from "react";
import TaskHeader from "../../components/Task/TaskHeader";
import Typography from "@mui/material/Typography";
import { connect } from "react-redux";
import useNavigateQSA from "../../utils/hooks/useNavigateQSA";
import { HTML_ATTR_PATTERN_DOB, HTML_ATTR_PATTERN_USER_ID } from "../../config/common";
import {
	IAM_FLOW_AUTHENTICATE,
	IAM_FLOW_RECOVER_ID,
	getFlowRootStage,
	getPathForFlowAndStage
} from "../utils/iamUtils";

const ResetPasswordVerifyIdView = props => {

	const {
		isPendingIAM,
		submitResetPasswordVerifyId
	} = props;

	const navigate = useNavigateQSA();

	const handleForgotUsername = () => {
		navigate(`/${getPathForFlowAndStage(IAM_FLOW_RECOVER_ID, getFlowRootStage(IAM_FLOW_RECOVER_ID))}`);
	};

	const handleSubmit = (e, formData) => {
		submitResetPasswordVerifyId(formData);
	};

	return (
		<DefaultTemplate isTask={true}>
			<TaskHeader
				closeResetsAuthFlow={true}
				closeTaskDestination={`/${getPathForFlowAndStage(IAM_FLOW_AUTHENTICATE, getFlowRootStage(IAM_FLOW_AUTHENTICATE))}`}
				title="Forgot Password"
			/>
			<Typography
				align="center"
				gutterBottom={true}
				paragraph={true}
			>
				Your email address will be used to send you a code to verify your identity.
			</Typography>
			<Form
				id="defaultFormNarrow"
				onSubmit={handleSubmit}
			>
				<IAMErrorMessages />
				<InteractionProcessTimeoutA11y />
				<FormTextField
					name="userId"
					pattern={HTML_ATTR_PATTERN_USER_ID}
					required={true}
					title="Username"
				/>
				<FormTextField
					helpMessage="MM/DD/YYYY"
					name="birthDate"
					pattern={HTML_ATTR_PATTERN_DOB}
					required={true}
					title="Date of Birth"
				/>
				<Box
					mb={1.75}
					mt={5.625}
				>
					<Button
						disabled={isPendingIAM}
						type="submit"
						variant="contained"
					>
						Next
					</Button>
				</Box>
				<Box mt={1.75}>
					<Button
						disabled={isPendingIAM}
						onClick={handleForgotUsername}
						variant="text"
					>
						Forgot Username
					</Button>
				</Box>
			</Form>
		</DefaultTemplate>
	);
};

const mapStateToProps = state => {
	return {
		isPendingIAM: state.auth.isPendingIAM
	};
};

const mapDispatchToProps = dispatch => {
	return {
		submitResetPasswordVerifyId: data => dispatch(authEvents.submitResetPasswordVerifyId(data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordVerifyIdView);