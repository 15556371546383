import * as commonActions from "../actions/common";
import * as profileActions from "../actions/profile";
import { IAM_DURATION_INTERACTION } from "../../config/auth";
// import { clearInputValue } from "../../config/common";
import commonApiRequest from "../../utils/commonApiRequest";
import { createEventGA4 } from "../../utils/analytics";
import { currentClientId } from "../../config/clients";
import { devicePrintString } from "../../utils/client-browser-scraping-script-v0.1";
import { getFullProfile } from "./profile";
import { ps } from "../../components/Application/Application";
import { sendErrorReport } from "../../utils/logging";
import { setData } from "../actions/auth";
import {
	API_NSP_AUTH,
	API_NSP_OPERATION_AUTHENTICATE,
	API_NSP_OPERATION_AUTH_TO_PORTAL,
	API_NSP_OPERATION_LEGACY_PORTAL,
	API_NSP_OPERATION_RECOVER_ID_CONTACT_IDENTIFIER,
	API_NSP_OPERATION_RECOVER_ID_NPPI,
	API_NSP_OPERATION_REGISTRATION,
	API_NSP_OPERATION_RESET_PASSWORD,
	API_NSP_OPERATION_SESSION,
	API_NSP_OPERATION_SESSION_EXTEND,
	API_NSP_TRANSFER,
	getApiUrl
} from "../../config/api";
import {
	ERROR_MESSAGE_TYPE_CONFIRM,
	ERROR_MESSAGE_TYPE_DEFAULT
} from "../../components/ErrorMessages/ErrorMessages";
import {
	IAM_FAILURE_REASONS_CODE_ACD,
	IAM_FAILURE_REASONS_CODE_BOT,
	IAM_FAILURE_REASONS_CODE_CTB,
	IAM_FAILURE_REASONS_CODE_EOP,
	IAM_FAILURE_REASONS_CODE_EUS,
	IAM_FAILURE_REASONS_CODE_FOD,
	IAM_FAILURE_REASONS_CODE_HLK,
	IAM_FAILURE_REASONS_CODE_ICR,
	IAM_FAILURE_REASONS_CODE_IEU,
	IAM_FAILURE_REASONS_CODE_IOP,
	IAM_FAILURE_REASONS_CODE_MOP,
	IAM_FAILURE_REASONS_CODE_MRO,
	IAM_FAILURE_REASONS_CODE_MRR,
	IAM_FAILURE_REASONS_CODE_NFD,
	IAM_FAILURE_REASONS_CODE_NPE,
	IAM_FAILURE_REASONS_CODE_OLE,
	IAM_FAILURE_REASONS_CODE_RPT,
	IAM_FAILURE_REASONS_CODE_SLK,
	IAM_FAILURE_REASONS_CODE_VCR,
	IAM_FLOW_AUTHENTICATE,
	IAM_FLOW_RECOVER_ID,
	IAM_FLOW_REGISTER,
	IAM_FLOW_RESET_PASSWORD,
	IAM_STG_ACCOUNT_BLOCKED,
	IAM_STG_ACCOUNT_CONFLICT,
	IAM_STG_ACCOUNT_LOCKED,
	IAM_STG_KEY_FINAL,
	IAM_STG_KEY_NPPI,
	IAM_STG_KEY_OTP,
	IAM_STG_KEY_RECOVER_USER_ID,
	getPathForFlowAndStage,
} from "../../views/utils/iamUtils";
import {
	PS_EVENT_AUTH_INTERACTION_RESET_DISMISS,
	PS_EVENT_LOGOUT_INVALID_TOKEN,
	RETURN_KEY_CONSUMER_PORTAL,
	SYSTEM_ERROR_CNX,
	SYSTEM_ERROR_INV,
	SYSTEM_ERROR_RSP
} from "../../config/common";
import {
	REQUEST_METHOD,
	errorReporting,
	format,
	isArray,
	isArrayOfLength,
	isFunction,
	isNumber,
	isObject,
	isString,
	isStringOfLength,
	parseJWT,
	sendRequest
} from "@pheaa/channels-component-library";
import store, { persistor } from "../index";

const SYNTHETIC_AUTH_ID = "synthentic-auth-id";

const handleAuthErrors = ({ body, headers, status }) => {
	if (isNumber(status)) {
		store.dispatch(setData({ systemErrors: [{ code: status, type: ERROR_MESSAGE_TYPE_DEFAULT }] }));
	} else {
		store.dispatch(setData({ systemErrors: [{ code: SYSTEM_ERROR_INV, type: ERROR_MESSAGE_TYPE_DEFAULT }] }));
		throw new Error("Expected HTTP Error Response Code Not Received.");
	}
};

const handleAuthFailures = error => {
	if (isObject(error) && isStringOfLength(error.message) && error.message.toLowerCase() === "failed to fetch") {
		store.dispatch(setData({ systemErrors: [{ code: SYSTEM_ERROR_CNX, type: ERROR_MESSAGE_TYPE_DEFAULT }] }));
	} else {
		store.dispatch(setData({ systemErrors: [{ code: SYSTEM_ERROR_INV, type: ERROR_MESSAGE_TYPE_DEFAULT }] }));
	}
};

const authApiRequest = (url, { ...rest }) => {

	const {
		alwaysCallback,
		beforeCallback,
		data = {},
		errorCallback,
		failCallback,
		headers = {},
		method = REQUEST_METHOD.POST,
		successNegativeCallback,
		successPositiveCallback
	} = rest;

	return sendRequest(url, {
		alwaysCallback: () => {
			store.dispatch(setData({ isPendingIAM: false }));
			isFunction(alwaysCallback) && alwaysCallback();
		},
		beforeCallback: () => {
			store.dispatch(setData({
				interfaceErrors: [],
				isPendingIAM: true,
				systemErrors: []
			}));
			isFunction(beforeCallback) && beforeCallback();
		},
		data,
		errorCallback: ({ body, headers, status }) => {
			try {
				handleAuthErrors({ body, headers, status });
				isFunction(errorCallback) && errorCallback({ body, headers, status });
			} catch (e) {
				console.error(e);
			}
		},
		failCallback: error => {
			handleAuthFailures(error);
			isFunction(failCallback) && failCallback(error);
		},
		headers: { ...headers, "X-Client-Id": currentClientId },
		method,
		successCallback: response => {

			try {

				const { body, status } = response;
				let updatedAuthState = { data: {} };

				if (isObject(body)) {

					if (isNumber(status) && [200, 201, 204].includes(status)) {
						updatedAuthState.responseDataIAM = body;
					}

					if (isStringOfLength(body.authId)) {

						let { payload } = parseJWT(body.authId);

						if (isObject(payload) && isNumber(payload.iat) && isNumber(payload.exp)) {
							let now = Math.floor(new Date() / 1000) * 1000;
							let nowAsDate = new Date(now);
							let interactionExpiry = nowAsDate.setSeconds(nowAsDate.getSeconds() + IAM_DURATION_INTERACTION);
							// Convert seconds to milliseconds when storing;
							updatedAuthState.apiInteractionFirst = payload.iat * 1000;
							updatedAuthState.apiInteractionLatest = now;
							updatedAuthState.authExpiryInteraction = interactionExpiry;
							updatedAuthState.authExpiryProcess = payload.exp * 1000;
							// Publish event to reset Interaction/Process Timer Modal Dismissal;
							ps.publish(PS_EVENT_AUTH_INTERACTION_RESET_DISMISS);
						}
					}

					if (isStringOfLength(body.lastLoginOn)) {
						updatedAuthState.lastLoginOn = body.lastLoginOn;
					}

					if (isStringOfLength(body.status)) {
						const { auth } = store.getState();

						if (isStringOfLength(body.stage)) {
							updatedAuthState.currentStage = body.stage;
							updatedAuthState.targetPathname = getPathForFlowAndStage(auth.currentFlow, body.stage);
						}

						let successPositiveData = null;
						let successNegativeData = null;

						if (body.status.toLowerCase() === "success") {
							isFunction(successPositiveCallback) && (successPositiveData = successPositiveCallback(response));

							// If the flow is complete (recovery has non-standard stage key);
							if (body.stage === IAM_STG_KEY_FINAL || body.stage === IAM_STG_KEY_RECOVER_USER_ID) {

								updatedAuthState.authExpiryInteraction = null;
								updatedAuthState.authExpiryProcess = null;

								if (auth.currentFlow === IAM_FLOW_AUTHENTICATE || auth.currentFlow === IAM_FLOW_REGISTER) {

									if (isStringOfLength(body.userId)) {
										updatedAuthState.data.userId = body.userId;
									}

									if (isNumber(body.accessExpiresIn)) {
										let tokenExpiry = new Date();
										tokenExpiry.setSeconds(tokenExpiry.getSeconds() + body.accessExpiresIn);
										updatedAuthState.authAccessExpiresAt = tokenExpiry.getTime();
										updatedAuthState.tokenLastVerifiedAt = tokenExpiry.getTime();
									}

									// TODO: (Future Enhancement) Enable this call to getLegacyTransferData once /auth-to-portal call no longer kills token;
									// store.dispatch(getLegacyTransferData());
									store.dispatch(getFullProfile());
								}

								if (auth.currentFlow === IAM_FLOW_AUTHENTICATE) {
									updatedAuthState.isAuthenticated = true;
									// GA4 Custom Event;
									createEventGA4("successfulAuthentication");
								}

								if (auth.currentFlow === IAM_FLOW_REGISTER) {
									updatedAuthState.isAuthenticated = true;
									// GA4 Custom Event;
									createEventGA4("successfulRegistration");
								}

								if (auth.currentFlow === IAM_FLOW_RECOVER_ID) {
									// GA4 Custom Event;
									createEventGA4("successfulUsernameRecovery");

									if (isStringOfLength(body.userId)) {
										updatedAuthState.data.userId = body.userId;
									}
								}

								if (auth.currentFlow === IAM_FLOW_RESET_PASSWORD) {
									// GA4 Custom Event;
									createEventGA4("successfulPasswordReset");
								}
							}

						} else if (body.status.toLowerCase() === "fail") {
							isFunction(successNegativeCallback) && (successNegativeData = successNegativeCallback(response));
						} else {
							updatedAuthState.systemErrors = [{ code: SYSTEM_ERROR_RSP, type: ERROR_MESSAGE_TYPE_DEFAULT }];
							console.warn("API Response Body Invalid.");
						}

						if (isObject(successPositiveData)) {
							updatedAuthState = { ...updatedAuthState, ...successPositiveData };
						}

						if (isObject(successNegativeData)) {
							updatedAuthState = { ...updatedAuthState, ...successNegativeData };
						}
					} else {
						updatedAuthState.systemErrors = [{ code: SYSTEM_ERROR_INV, type: ERROR_MESSAGE_TYPE_DEFAULT }];
						console.warn(`API Response Status '${body.status}' Invalid.`);
					}
				}

				// Dispatch action to set updated state in store;
				store.dispatch(setData(updatedAuthState));

			} catch (e) {
				console.warn(e);
			}
		}
	});
};

export const clearErrors = () => {
	store.dispatch(setData({
		interfaceErrors: [],
		systemErrors: []
	}));
};

export const submitCredentials = data => (dispatch, getState) => {
	const url = getApiUrl(API_NSP_AUTH, API_NSP_OPERATION_AUTHENTICATE);
	// If device print string is valid string of length;
	if (isStringOfLength(devicePrintString)) {
		// Set devicePrint property on data;
		data.devicePrint = devicePrintString;
	}

	authApiRequest(url, {
		beforeCallback: () => {
			store.dispatch(setData({
				authAccountBlocked: false,
				authAccountConflict: false,
				lastLoginOn: null
			}));
			store.dispatch(commonActions.resetData());
			store.dispatch(profileActions.resetData());
		},
		data,
		failCallback: error => {
			sendErrorReport(error, { message: "UI failed to submit credentials.", severity: errorReporting.severity.MEDIUM });
		},
		successNegativeCallback: ({ body }) => {
			const { failureReasons } = body;

			let updatedAuthState = {
				interfaceErrors: []
			};

			if (isArrayOfLength(failureReasons)) {
				failureReasons.forEach(reason => {

					if (reason.code === IAM_FAILURE_REASONS_CODE_SLK || reason.code === IAM_FAILURE_REASONS_CODE_BOT) {
						let now = Math.floor(new Date() / 1000) * 1000;
						let nowAsDate = new Date(now);
						let authLockExpiry = nowAsDate.setSeconds(nowAsDate.getSeconds() + reason.secondsRemaining);
						updatedAuthState.targetPathname = getPathForFlowAndStage(IAM_FLOW_AUTHENTICATE, IAM_STG_ACCOUNT_LOCKED);
						isNumber(reason.secondsRemaining) && (updatedAuthState.authLockExpiry = authLockExpiry);
					} else if (reason.code === IAM_FAILURE_REASONS_CODE_HLK) {
						updatedAuthState.targetPathname = getPathForFlowAndStage(IAM_FLOW_AUTHENTICATE, IAM_STG_ACCOUNT_BLOCKED);
						updatedAuthState.authAccountBlocked = true;
					} else if (reason.code === IAM_FAILURE_REASONS_CODE_ICR) {
						updatedAuthState.interfaceErrors.push({ code: reason.code, type: ERROR_MESSAGE_TYPE_DEFAULT });
					} else if (reason.code === IAM_FAILURE_REASONS_CODE_ACD) {
						updatedAuthState.targetPathname = getPathForFlowAndStage(IAM_FLOW_AUTHENTICATE, IAM_STG_ACCOUNT_CONFLICT);
						updatedAuthState.authAccountConflict = true;
					}
				});
			}

			return updatedAuthState;
		},
		successPositiveCallback: ({ body }) => {

			let updatedAuthState = {};

			if (isObject(body.otpInfo) && isArray(body.otpInfo.contactOptions)) {
				updatedAuthState.contactOptions = body.otpInfo.contactOptions;
			}

			if (isStringOfLength(body.authId)) {
				updatedAuthState.secondFactorAuthId = body.authId;
			}

			if (isStringOfLength(body.stage) && body.stage === IAM_STG_KEY_FINAL) {
				// Must set an authId string to prevent restart of flow due to hasAuthId();
				updatedAuthState.responseDataIAM = { authId: SYNTHETIC_AUTH_ID };
			}

			return updatedAuthState;
		}
	});
};

export const submitSelectedDevice = formData => (dispatch, getState) => {
	const { auth } = getState();
	const { currentFlow } = auth;
	let url;

	if (currentFlow === IAM_FLOW_RESET_PASSWORD) {
		url = getApiUrl(API_NSP_AUTH, API_NSP_OPERATION_RESET_PASSWORD);
	} else {
		url = getApiUrl(API_NSP_AUTH, API_NSP_OPERATION_AUTHENTICATE);
	}

	const secondFactorDevice = {
		channel: formData.channel,
		contactId: formData.id,
		description: formData.description
	};

	authApiRequest(url, {
		beforeCallback: () => {
			store.dispatch(setData({
				data: {
					isPasscodeMaxDeliveryAttempts: false,
					secondFactorDevice
				}
			}));
		},
		data: {
			authId: auth.secondFactorAuthId,
			delivery: secondFactorDevice,
			secondFactor: IAM_STG_KEY_OTP
		}
	});
};

export const submitVerifyInfo = formData => (dispatch, getState) => {
	const { currentFlow, data, responseDataIAM, secondFactorAuthId } = getState().auth;
	let url;

	if (currentFlow === IAM_FLOW_RECOVER_ID) {
		url = getApiUrl(API_NSP_AUTH, API_NSP_OPERATION_RECOVER_ID_NPPI);
	} else if (currentFlow === IAM_FLOW_RESET_PASSWORD) {
		url = getApiUrl(API_NSP_AUTH, API_NSP_OPERATION_RESET_PASSWORD);
	} else {
		url = getApiUrl(API_NSP_AUTH, API_NSP_OPERATION_AUTHENTICATE);
	}

	let requestData = {
		...formData,
		accountIdentifier: formData.accountIdentifier.replace(/\D/g, ""),
		birthDate: format.asDate(formData.birthDate ? formData.birthDate : data.userBirthDate, {
			outputPattern: "yyyy-mm-dd"
		})
	};

	if (currentFlow === IAM_FLOW_AUTHENTICATE) {
		requestData = {
			authId: isStringOfLength(secondFactorAuthId) ? secondFactorAuthId : responseDataIAM.authId,
			secondFactor: IAM_STG_KEY_NPPI,
			nppi: { ...requestData }
		};
	} else if (currentFlow === IAM_FLOW_RECOVER_ID) {
		requestData = {
			nppi: { ...requestData }
		};
	} else if (currentFlow === IAM_FLOW_RESET_PASSWORD) {
		requestData = {
			authId: isStringOfLength(secondFactorAuthId) ? secondFactorAuthId : responseDataIAM.authId,
			nppi: { ...requestData }
		};
	}

	authApiRequest(url, {
		data: requestData,
		successNegativeCallback: ({ body }) => {

			const updatedAuthState = {
				interfaceErrors: [],
				systemErrors: []
			};

			if (isArrayOfLength(body.failureReasons)) {
				body.failureReasons.forEach(reason => {
					if (reason.code === IAM_FAILURE_REASONS_CODE_HLK) {
						updatedAuthState.targetPathname = getPathForFlowAndStage(currentFlow, IAM_STG_ACCOUNT_BLOCKED);
						updatedAuthState.authAccountBlocked = true;
					} else {
						updatedAuthState.interfaceErrors.push({ code: reason.code, type: ERROR_MESSAGE_TYPE_DEFAULT });

						if (reason.code === IAM_FAILURE_REASONS_CODE_NPE) {
							updatedAuthState.data = { userMaxInfoVerificationAttempts: true };
						}
					}
				});
			} else {
				updatedAuthState.systemErrors = [{ code: SYSTEM_ERROR_RSP, type: ERROR_MESSAGE_TYPE_DEFAULT }];
			}

			return updatedAuthState;
		}
	});
};

export const submitVerifyPasscode = formData => (dispatch, getState) => {
	const { currentFlow, responseDataIAM } = getState().auth;
	let url;

	if (currentFlow === IAM_FLOW_REGISTER) {
		url = getApiUrl(API_NSP_AUTH, API_NSP_OPERATION_REGISTRATION);
	} else if (currentFlow === IAM_FLOW_RECOVER_ID) {
		url = getApiUrl(API_NSP_AUTH, API_NSP_OPERATION_RECOVER_ID_CONTACT_IDENTIFIER);
	} else if (currentFlow === IAM_FLOW_RESET_PASSWORD) {
		url = getApiUrl(API_NSP_AUTH, API_NSP_OPERATION_RESET_PASSWORD);
	} else {
		url = getApiUrl(API_NSP_AUTH, API_NSP_OPERATION_AUTHENTICATE);
	}

	authApiRequest(url, {
		beforeCallback: () => {
			store.dispatch(setData({
				data: {
					isPasscodeExpired: false,
					isPasscodeMaxDeliveryAttempts: false,
					isPasscodeMaxVerificationAttempts: false
				}
			}));
		},
		data: {
			authId: responseDataIAM.authId,
			passcode: formData.passcode
		},
		successNegativeCallback: ({ body }) => {

			const updatedAuthState = {
				data: {},
				interfaceErrors: [],
				systemErrors: []
			};

			if (isArrayOfLength(body.failureReasons)) {
				body.failureReasons.forEach(reason => {
					switch (reason.code) {
						case IAM_FAILURE_REASONS_CODE_OLE:
						case IAM_FAILURE_REASONS_CODE_MOP:
							updatedAuthState.interfaceErrors.push({ code: reason.code, type: ERROR_MESSAGE_TYPE_DEFAULT });
							updatedAuthState.data.isPasscodeMaxVerificationAttempts = true;
							break;
						case IAM_FAILURE_REASONS_CODE_EOP:
							updatedAuthState.interfaceErrors.push({ code: reason.code, type: ERROR_MESSAGE_TYPE_DEFAULT });
							updatedAuthState.data.isPasscodeExpired = true;
							break;
						case IAM_FAILURE_REASONS_CODE_FOD:
						case IAM_FAILURE_REASONS_CODE_IOP:
							updatedAuthState.interfaceErrors.push({ code: reason.code, type: ERROR_MESSAGE_TYPE_DEFAULT });
							break;
						case IAM_FAILURE_REASONS_CODE_MRO:
							updatedAuthState.interfaceErrors.push({ code: (currentFlow === IAM_FLOW_REGISTER ? IAM_FAILURE_REASONS_CODE_MRR : reason.code), type: ERROR_MESSAGE_TYPE_DEFAULT });
							updatedAuthState.data.isPasscodeMaxDeliveryAttempts = true;
							break;
						default:
							break;
					}
				});
			} else {
				updatedAuthState.systemErrors = [{ code: SYSTEM_ERROR_RSP, type: ERROR_MESSAGE_TYPE_DEFAULT }];
			}

			return updatedAuthState;
		}
	});
};

export const submitResendPasscode = () => (dispatch, getState) => {
	const { currentFlow, data, responseDataIAM } = getState().auth;
	let url;

	if (currentFlow === IAM_FLOW_REGISTER) {
		url = getApiUrl(API_NSP_AUTH, API_NSP_OPERATION_REGISTRATION);
	} else if (currentFlow === IAM_FLOW_RECOVER_ID) {
		url = getApiUrl(API_NSP_AUTH, API_NSP_OPERATION_RECOVER_ID_CONTACT_IDENTIFIER);
	} else if (currentFlow === IAM_FLOW_RESET_PASSWORD) {
		url = getApiUrl(API_NSP_AUTH, API_NSP_OPERATION_RESET_PASSWORD);
	} else {
		url = getApiUrl(API_NSP_AUTH, API_NSP_OPERATION_AUTHENTICATE);
	}

	let requestData = { authId: responseDataIAM.authId };

	if (currentFlow === IAM_FLOW_REGISTER) {
		requestData = {
			...requestData,
			email: data.userEmail,
			resend: true
		};
	} else {
		requestData = {
			...requestData,
			delivery: data.secondFactorDevice,
			secondFactor: IAM_STG_KEY_OTP,
			resendOtp: true
		};
	}

	authApiRequest(url, {
		data: requestData,
		successNegativeCallback: ({ body }) => {

			const updatedAuthState = {
				data: {},
				interfaceErrors: []
			};

			if (isArrayOfLength(body.failureReasons)) {
				body.failureReasons.forEach(reason => {
					switch (reason.code) {
						case IAM_FAILURE_REASONS_CODE_MRO:
							updatedAuthState.interfaceErrors.push({ code: (currentFlow === IAM_FLOW_REGISTER ? IAM_FAILURE_REASONS_CODE_MRR : reason.code), type: ERROR_MESSAGE_TYPE_DEFAULT });
							updatedAuthState.data.isPasscodeMaxDeliveryAttempts = true;
							break;
						default:
							break;
					}
				});
			} else {
				updatedAuthState.systemErrors = [{ code: SYSTEM_ERROR_RSP, type: ERROR_MESSAGE_TYPE_DEFAULT }];
			}

			return updatedAuthState;
		},
		successPositiveCallback: ({ body }) => {

			const updatedAuthState = {
				interfaceErrors: [{ code: IAM_FAILURE_REASONS_CODE_VCR, type: ERROR_MESSAGE_TYPE_CONFIRM }],
				isPasscodeExpired: false,
				isPasscodeMaxDeliveryAttempts: false,
				isPasscodeMaxVerificationAttempts: false
			};

			// TODO: Find way to clear input of controlled component;
			// clearInputValue("input[name='passcode']");
			return updatedAuthState;
		}
	});
};

export const submitRememberDevice = formData => (dispatch, getState) => {
	const { currentFlow, responseDataIAM } = getState().auth;
	let url;
	let requestData = { authId: responseDataIAM.authId };

	if (currentFlow === IAM_FLOW_REGISTER) {
		url = getApiUrl(API_NSP_AUTH, API_NSP_OPERATION_REGISTRATION);
		requestData.devicePrint = (formData.saveDevice === "yes" && isStringOfLength(devicePrintString)) ? devicePrintString : "{}";
	} else {
		url = getApiUrl(API_NSP_AUTH, API_NSP_OPERATION_AUTHENTICATE);
		requestData.saveDevice = formData.saveDevice === "yes";
	}

	authApiRequest(url, { data: requestData });
};

export const submitRegistrationIdentification = formData => () => {
	const url = getApiUrl(API_NSP_AUTH, API_NSP_OPERATION_REGISTRATION);

	let requestData = {
		accountIdentifier: formData.accountIdentifier.replace(/\D/g, ""),
		birthDate: format.asDate(formData.birthDate, { outputPattern: "yyyy-mm-dd" }),
		name: { first: formData.firstName, last: formData.lastName }
	};

	authApiRequest(url, {
		data: requestData,
		successNegativeCallback: ({ body }) => {

			const updatedAuthState = {
				interfaceErrors: []
			};

			if (isArrayOfLength(body.failureReasons)) {
				body.failureReasons.forEach(reason => {
					switch (reason.code) {
						case IAM_FAILURE_REASONS_CODE_IEU:
						case IAM_FAILURE_REASONS_CODE_NFD:
						case IAM_FAILURE_REASONS_CODE_EUS:
							updatedAuthState.interfaceErrors.push({ code: reason.code, type: ERROR_MESSAGE_TYPE_DEFAULT });
							break;
						default:
							break;
					}
				});
			} else {
				updatedAuthState.systemErrors = [{ code: SYSTEM_ERROR_RSP, type: ERROR_MESSAGE_TYPE_DEFAULT }];
			}

			return updatedAuthState;
		}
	});
};

export const submitCreateCredentials = formData => (dispatch, getState) => {
	const { responseDataIAM } = getState().auth;
	const url = getApiUrl(API_NSP_AUTH, API_NSP_OPERATION_REGISTRATION);

	authApiRequest(url, {
		beforeCallback: () => {
			dispatch(setData({ data: { userMaxCreateCredentialsAttempts: false } }));
		},
		data: { authId: responseDataIAM.authId, userId: formData.userId, password: formData.password },
		successNegativeCallback: ({ body }) => {

			const updatedAuthState = {
				data: {},
				interfaceErrors: []
			};

			if (isArrayOfLength(body.failureReasons)) {
				body.failureReasons.forEach(reason => {
					updatedAuthState.interfaceErrors.push({ code: reason.code, type: ERROR_MESSAGE_TYPE_DEFAULT });
					if (reason.code === IAM_FAILURE_REASONS_CODE_CTB) {
						updatedAuthState.data.userMaxCreateCredentialsAttempts = true;
					}
				});
			} else {
				updatedAuthState.systemErrors = [{ code: SYSTEM_ERROR_RSP, type: ERROR_MESSAGE_TYPE_DEFAULT }];
			}

			return updatedAuthState;
		},
		successPositiveCallback: ({ body }) => {
			const updatedAuthState = { data: {} };

			if (isString(body.contactEmail)) {
				updatedAuthState.data.userEmail = body.contactEmail;
			}

			return updatedAuthState;
		}
	});

};

export const submitVerifyEmail = formData => (dispatch, getState) => {
	const { data, responseDataIAM } = getState().auth;
	const url = getApiUrl(API_NSP_AUTH, API_NSP_OPERATION_REGISTRATION);

	const requestData = { ...formData, authId: responseDataIAM.authId };
	const secondFactorDevice = {
		channel: "email",
		description: formData.email
	};

	if (
		isObject(data) &&
		isObject(data.secondFactorDevice) &&
		isStringOfLength(data.secondFactorDevice.description) &&
		formData.email.toLowerCase() !== data.secondFactorDevice.description.toLowerCase()) {
		requestData.resend = true;
	}

	authApiRequest(url, {
		beforeCallback: () => {
			dispatch(setData({
				data: {
					isPasscodeExpired: false,
					isPasscodeMaxDeliveryAttempts: false,
					isPasscodeMaxVerificationAttempts: false,
					secondFactorDevice
				}
			}));
		},
		data: requestData,
		successNegativeCallback: ({ body }) => {
			const updatedAuthState = {
				data: {},
				interfaceErrors: []
			};

			if (isArrayOfLength(body.failureReasons)) {
				body.failureReasons.forEach(reason => {
					if (reason.code === IAM_FAILURE_REASONS_CODE_MRO) {
						updatedAuthState.interfaceErrors.push({ code: IAM_FAILURE_REASONS_CODE_MRR, type: ERROR_MESSAGE_TYPE_DEFAULT });
						updatedAuthState.isPendingFlowRestart = true;
						updatedAuthState.data.isPasscodeMaxDeliveryAttempts = true;
					} else {
						updatedAuthState.interfaceErrors.push({ code: reason.code, type: ERROR_MESSAGE_TYPE_DEFAULT });
					}
				});
			}

			return updatedAuthState;
		},
		successPositiveCallback: ({ body }) => {
			const updatedAuthState = {
				data: { userEmail: requestData.email }
			};

			return updatedAuthState;
		}
	});
};

export const submitAgreement = () => (dispatch, getState) => {
	const { currentFlow, responseDataIAM } = getState().auth;
	let url;

	const requestData = { authId: responseDataIAM.authId };

	if (currentFlow === IAM_FLOW_REGISTER) {
		url = getApiUrl(API_NSP_AUTH, API_NSP_OPERATION_REGISTRATION);
		requestData.accept = true;
	} else {
		url = getApiUrl(API_NSP_AUTH, API_NSP_OPERATION_AUTHENTICATE);
		requestData.tosAccept = true;
	}

	authApiRequest(url, { data: requestData });
};

export const submitRecoverIdEmail = data => (dispatch, getState) => {
	const { currentFlow } = getState().auth;
	const url = getApiUrl(API_NSP_AUTH, API_NSP_OPERATION_RECOVER_ID_CONTACT_IDENTIFIER);

	authApiRequest(url, {
		data,
		successNegativeCallback: ({ body }) => {

			const updatedAuthState = {
				data: {},
				interfaceErrors: []
			};

			if (isArrayOfLength(body.failureReasons)) {
				body.failureReasons.forEach(reason => {
					if (reason.code === IAM_FAILURE_REASONS_CODE_HLK) {
						updatedAuthState.targetPathname = getPathForFlowAndStage(currentFlow, IAM_STG_ACCOUNT_BLOCKED);
						updatedAuthState.authAccountBlocked = true;
					} else {
						updatedAuthState.interfaceErrors.push({ code: reason.code, type: ERROR_MESSAGE_TYPE_DEFAULT });
					}
				});
			} else {
				updatedAuthState.systemErrors = [{ code: SYSTEM_ERROR_RSP, type: ERROR_MESSAGE_TYPE_DEFAULT }];
			}

			return updatedAuthState;
		},
		successPositiveCallback: ({ body }) => {
			const updatedAuthState = {
				data: { userEmail: data.contactIdentifier }
			};

			if (isObject(body.delivery)) {
				updatedAuthState.data.secondFactorDevice = {
					...body.delivery,
					description: data.contactIdentifier
				};
			}

			return updatedAuthState;
		}
	});

};

export const submitResetPasswordVerifyId = formData => (dispatch, getState) => {
	const { currentFlow } = getState().auth;
	const url = getApiUrl(API_NSP_AUTH, API_NSP_OPERATION_RESET_PASSWORD);

	let requestData = { ...formData };

	requestData.birthDate = format.asDate(formData.birthDate, {
		outputPattern: "yyyy-mm-dd"
	});

	authApiRequest(url, {
		data: requestData,
		successNegativeCallback: ({ body }) => {

			const updatedAuthState = {
				data: {},
				interfaceErrors: []
			};

			if (isArrayOfLength(body.failureReasons)) {
				body.failureReasons.forEach(reason => {
					if (reason.code === IAM_FAILURE_REASONS_CODE_HLK) {
						updatedAuthState.targetPathname = getPathForFlowAndStage(currentFlow, IAM_STG_ACCOUNT_BLOCKED);
						updatedAuthState.authAccountBlocked = true;
					} else {
						updatedAuthState.interfaceErrors.push({ code: reason.code, type: ERROR_MESSAGE_TYPE_DEFAULT });
					}
				});
			} else {
				updatedAuthState.systemErrors = [{ code: SYSTEM_ERROR_RSP, type: ERROR_MESSAGE_TYPE_DEFAULT }];
			}

			return updatedAuthState;
		},
		successPositiveCallback: ({ body }) => {
			const updatedAuthState = {
				data: { userBirthDate: requestData.birthDate }
			};

			if (isObject(body.otpInfo) && isArray(body.otpInfo.contactOptions)) {
				updatedAuthState.contactOptions = body.otpInfo.contactOptions;
			}

			if (isStringOfLength(body.authId)) {
				updatedAuthState.secondFactorAuthId = body.authId;
			}

			return updatedAuthState;
		}
	});

};

export const submitCreatePassword = formData => (dispatch, getState) => {
	const { responseDataIAM } = getState().auth;
	const url = getApiUrl(API_NSP_AUTH, API_NSP_OPERATION_RESET_PASSWORD);

	let requestData = {
		authId: responseDataIAM.authId,
		password: formData.password
	};

	authApiRequest(url, {
		data: requestData,
		successNegativeCallback: ({ body }) => {

			const updatedAuthState = {
				data: {},
				interfaceErrors: []
			};

			if (isArrayOfLength(body.failureReasons)) {
				body.failureReasons.forEach(reason => {
					if (reason.code === IAM_FAILURE_REASONS_CODE_RPT) {
						updatedAuthState.data.userMaxResetPasswordAttempts = true;
					}

					updatedAuthState.interfaceErrors.push({ code: reason.code, type: ERROR_MESSAGE_TYPE_DEFAULT });
				});
			} else {
				updatedAuthState.systemErrors = [{ code: SYSTEM_ERROR_RSP, type: ERROR_MESSAGE_TYPE_DEFAULT }];
			}

			return updatedAuthState;
		}
	});
};

export const extendSession = () => (dispatch, getState) => {
	const url = getApiUrl(API_NSP_AUTH, API_NSP_OPERATION_SESSION_EXTEND);
	commonApiRequest(url, {
		alwaysCallback: () => { dispatch(setData({ isPendingSessionExtension: false })); },
		beforeCallback: () => { dispatch(setData({ interfaceErrors: [], isPendingSessionExtension: true, systemErrors: [] })); },
		errorCallback: ({ body, headers, status }) => {
			try {
				handleAuthErrors({ body, headers, status });
			} catch (e) {
				console.error(e);
			}
		},
		failCallback: error => { handleAuthFailures(error); },
		method: REQUEST_METHOD.PUT,
		successCallback: ({ body }) => {
			let tokenExpiry = new Date();
			tokenExpiry.setSeconds(tokenExpiry.getSeconds() + body.accessExpiresIn);
			dispatch(setData({ authAccessExpiresAt: tokenExpiry.getTime() }));
		}
	});
};

export const endSession = (callback) => (dispatch, getState) => {
	const url = getApiUrl(API_NSP_AUTH, API_NSP_OPERATION_SESSION);
	commonApiRequest(url, {
		alwaysCallback: () => {
			dispatch(setData({ isPendingSessionEnd: false }));
			isFunction(callback) && callback();
		},
		beforeCallback: () => { dispatch(setData({ interfaceErrors: [], isPendingSessionEnd: true, systemErrors: [] })); },
		errorCallback: ({ body, headers, status }) => {
			try {
				handleAuthErrors({ body, headers, status });
			} catch (e) {
				console.error(e);
			}
		},
		failCallback: error => { handleAuthFailures(error); },
		method: REQUEST_METHOD.DELETE,
		successCallback: () => {
			persistor.purge();
		}
	});
};

export const verifySession = () => (dispatch, getState) => {
	const url = getApiUrl(API_NSP_AUTH, API_NSP_OPERATION_SESSION);

	commonApiRequest(url, {
		beforeCallback: () => {
			dispatch(setData({ isPendingSessionVerification: true }));
		},
		errorCallback: ({ body, headers, status }) => {
			dispatch(setData({
				authAccessExpiresAt: null,
				isAuthenticated: false,
				isPendingSessionVerification: false
			}));
			persistor.purge();
			ps.publish(PS_EVENT_LOGOUT_INVALID_TOKEN, { nextEvent: window.location.pathname });
		},
		failCallback: error => {
			dispatch(setData({ isPendingSessionVerification: false }));
		},
		successCallback: ({ body }) => {
			if (isNumber(body.accessExpiresIn)) {
				let tokenExpiry = new Date();
				tokenExpiry.setSeconds(tokenExpiry.getSeconds() + body.accessExpiresIn);
				dispatch(setData({
					authAccessExpiresAt: tokenExpiry.getTime(),
					isAuthenticated: true,
					isPendingSessionVerification: false,
					tokenLastVerifiedAt: new Date(body.now).getTime()
				}));
			}
		}
	});
};

export const getLegacyTransferData = () => (dispatch, getState) => {
	const currentState = getState();
	const url = getApiUrl(API_NSP_TRANSFER, API_NSP_OPERATION_AUTH_TO_PORTAL);
	const { returnKey } = currentState.common;
	const { lastLoginOn } = currentState.auth;
	const requestData = {
		destination: isStringOfLength(returnKey) ? returnKey : RETURN_KEY_CONSUMER_PORTAL
	};

	isStringOfLength(lastLoginOn) && (requestData.lastLoginOn = lastLoginOn);

	commonApiRequest(url, {
		beforeCallback: () => {
			dispatch(setData({ legacyTransferDataPending: true }));
		},
		data: requestData,
		errorCallback: ({ status }) => {
			dispatch(setData({
				legacyTransferDataPending: false,
				legacyTransferFailed: true
			}));
		},
		failCallback: error => {
			dispatch(setData({
				legacyTransferDataPending: false,
				legacyTransferFailed: true
			}));
			sendErrorReport(error, { message: "UI failed to acquire transfer token.", severity: errorReporting.severity.MEDIUM });
		},
		method: REQUEST_METHOD.POST,
		successCallback: ({ body }) => {
			dispatch(setData({
				legacyTransferDataPending: false,
				legacyTransferFailed: false,
				legacyTransferUrl: `${getApiUrl(API_NSP_TRANSFER, API_NSP_OPERATION_LEGACY_PORTAL)}?appKey=${body.key}&event=common.entrance`
			}));
		}
	});
};