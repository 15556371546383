import { IAM_DURATION_PROCESS } from "../../config/auth";
import { INTERACTION_PROCESS_TIMEOUT_ALERT_DURATION } from "./InteractionProcessTimeout";
import React from "react";
import Typography from "@mui/material/Typography";
import { format } from "@pheaa/channels-component-library";

const InteractionProcessTimeoutA11y = () => {

	const formatConfig = {
		leadingZeroes: false,
		requireSeconds: false
	};

	const formattedProcess = format.asHourMinSec(IAM_DURATION_PROCESS, formatConfig);
	const formattedAlert = format.asHourMinSec(INTERACTION_PROCESS_TIMEOUT_ALERT_DURATION, formatConfig);

	return (
		<Typography className="a11y-hidden" paragraph={true}>
			Once you submit the information on this screen, you will have {formattedProcess.title} to complete the process
			with a reminder at {formattedAlert.title} of inactivity.
		</Typography>
	);
};

export { InteractionProcessTimeoutA11y };