import React from "react";
import { connect } from "react-redux";
import useNavigateQSA from "../../utils/hooks/useNavigateQSA";
import withRequireProfile from "../../utils/withRequireProfile";

const ProfileView = props => {

	// const { profile } = props;
	const navigate = useNavigateQSA();

	// TODO: Remove useEffect once Consumer Servicing Portal includes "/profile" view for Profile;
	React.useEffect(() => {
		navigate("/transfer");
	}, [navigate]);

	// return (
	// 	<React.Fragment>
	// 		<div>ProfileView</div>
	// 		<pre>{JSON.stringify(profile.data, null, 2)}</pre>
	// 	</React.Fragment>
	// );
	return null;
};

const mapStateToProps = state => {
	return {
		profile: {
			data: state.profile.data
		}
	};
};

export default connect(mapStateToProps)(withRequireProfile(ProfileView));