import * as authEvents from "../../store/events/auth";
import Box from "@mui/material/Box";
import Button from "../../components/Button/Button";
import DefaultTemplate from "../templates/DefaultTemplate";
import { Form } from "@pheaa/channels-component-library";
import FormTextField from "../../components/Form/FormTextField";
import { HTML_ATTR_PATTERN_PASSWORD } from "../../config/common";
import IAMErrorMessages from "../../components/ErrorMessages/IAMErrorMessages";
import React from "react";
import TaskHeader from "../../components/Task/TaskHeader";
import Typography from "@mui/material/Typography";
import { additionalDetailsPassword } from "../registration/RegistrationCreateCredentialsView";
import { connect } from "react-redux";
import {
	IAM_FLOW_AUTHENTICATE,
	IAM_FLOW_RESET_PASSWORD,
	getFlowRootStage,
	getPathForFlowAndStage,
	hasAuthId,
	withAuthCondition
} from "../utils/iamUtils";

const ResetPasswordCreateView = props => {

	const {
		isPendingIAM,
		isUserMaxResetPasswordAttempts,
		submitCreatePassword
	} = props;

	const handleSubmit = (e, formData) => {
		submitCreatePassword(formData);
	};

	return (
		<DefaultTemplate isTask={true}>
			<TaskHeader
				closeResetsAuthFlow={true}
				closeTaskDestination={`/${getPathForFlowAndStage(IAM_FLOW_AUTHENTICATE, getFlowRootStage(IAM_FLOW_AUTHENTICATE))}`}
				title="Forgot Password"
			/>
			<Typography
				align="center"
				gutterBottom={true}
				paragraph={true}
			>
				Please create a new password.
			</Typography>
			<Form
				id="defaultFormNarrow"
				onSubmit={handleSubmit}
			>
				<IAMErrorMessages />
				<FormTextField
					additionalDetails={additionalDetailsPassword}
					additionalDetailsOnFocus={true}
					includeConfirmation={true}
					name="password"
					passwordRevealEnabled={true}
					pattern={HTML_ATTR_PATTERN_PASSWORD}
					required={true}
					title="Password"
					type="password"
				/>
				<Box
					mb={1.75}
					mt={5.625}
				>
					<Button
						disabled={isPendingIAM || isUserMaxResetPasswordAttempts}
						type="submit"
						variant="contained"
					>
						Continue
					</Button>
				</Box>
			</Form>
		</DefaultTemplate>
	);
};

const mapStateToProps = state => {
	return {
		isPendingIAM: state.auth.isPendingIAM,
		isUserMaxResetPasswordAttempts: state.auth.data.userMaxResetPasswordAttempts
	};
};

const mapDispatchToProps = dispatch => {
	return {
		submitCreatePassword: data => dispatch(authEvents.submitCreatePassword(data))
	};
};

export default withAuthCondition(connect(mapStateToProps, mapDispatchToProps)(ResetPasswordCreateView), hasAuthId, IAM_FLOW_RESET_PASSWORD);