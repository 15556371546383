

const createEventGA4 = (eventName = "customEvent", analyticsEventData = {}) => {
	if (process.env.NODE_ENV === "production" && window.gtag) {
		window.gtag("event", eventName, analyticsEventData);
	}
};

const createVirtualPageViewGA4 = () => {
	createEventGA4("virtualPageview");
};

export { createEventGA4, createVirtualPageViewGA4 };