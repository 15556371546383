import { currentDate } from "../config/common";
import { isDate } from "@pheaa/channels-component-library";

const getManualMaintenanceStatus = ({ enabled = false, schedule = [] } = {}) => {
	let isInMaintenanceWindow = false;

	if (!enabled) {

		isInMaintenanceWindow = schedule.some(({ beginOn, endOn } = {}, i) => {
			try {
				const currentTime = currentDate.getTime();
				const maintBegin = new Date(beginOn);
				const maintEnd = new Date(endOn);

				if (!isDate(maintBegin)) {
					throw new Error(`Invalid configuration maintenance.schedule[${i}].beginOn: ${beginOn}`);
				}

				if (!isDate(maintEnd)) {
					throw new Error(`Invalid configuration maintenance.schedule[${i}].endOn: ${endOn}`);
				}

				return (maintBegin.getTime() < currentTime) && (currentTime < maintEnd.getTime());
			} catch (e) {
				console.warn("Invalid Maintenance Schedule Configuration.", e);
			}

			return false;
		});
	}

	return enabled || isInMaintenanceWindow;
};

export default getManualMaintenanceStatus;