import Button from "@mui/material/Button";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PropTypes from "prop-types";
import React from "react";
import { styled } from "@mui/material/styles";

const ExpandMoreIconStyled = styled((props) => {
	return <ExpandMoreIcon {...props} />;
})(({ theme, expand }) => {
	return {
		transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
		marginLeft: "auto",
		transition: theme.transitions.create("transform", {
			duration: theme.transitions.duration.shortest,
		}),
	};
});

const ExpandMore = props => {
	const { expand, label, ...other } = props;
	return (
		<Button
			aria-expanded={expand}
			aria-label="show more"
			endIcon={<ExpandMoreIconStyled expand={expand ? 1 : 0} />}
			{...other}
		>
			{label}
		</Button>
	);
};

// Define propTypes;
ExpandMore.propTypes = {
	expand: PropTypes.bool,
	label: PropTypes.string
};

// Define defaultProps;
ExpandMore.defaultProps = {};

// Export component as default;
export default ExpandMore;