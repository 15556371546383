import { getThemeForClientId } from "./themes";

const CLIENT_AES = "aes";
const CLIENT_ELF = "elfi";

const clients = [{
	client: CLIENT_AES,
	clientAssetRoot: `${process.env.PUBLIC_URL}/assets/${CLIENT_AES}`,
	clientHostnamePatterns: [
		"localhost",
		"access(.(dev|qa))?.aessuccess.org"
	],
	clientId: "webaesconsmr",
	clientName: "American Education Services",
	clientTheme: getThemeForClientId(CLIENT_AES)
}, {
	client: CLIENT_ELF,
	clientAssetRoot: `${process.env.PUBLIC_URL}/assets/${CLIENT_ELF}`,
	clientHostnamePatterns: [
		"access(.(dev|qa))?.elfi.aessuccess.org"
	],
	clientId: "webelfconsmr",
	clientName: "Education Loan Finance",
	clientTheme: getThemeForClientId(CLIENT_ELF)
}];

const getDefaultClient = () => {
	return clients[0];
};

const getClientFromHostname = ({ hostname } = window.location) => {
	let clientObj = clients.find(client => {
		let clientMatchesHostname = false;
		client.clientHostnamePatterns.forEach(pattern => {
			const re = new RegExp(pattern, "i");
			if (re.test(hostname)) {
				clientMatchesHostname = true;
			}
		});
		return clientMatchesHostname;
	});

	return clientObj || getDefaultClient();
};

let currentClientObj = getClientFromHostname();
let currentClient = currentClientObj.client;
let currentClientAssetRoot = currentClientObj.clientAssetRoot;
let currentClientId = currentClientObj.clientId;
let currentClientName = currentClientObj.clientName;
let currentClientTheme = currentClientObj.clientTheme;

export default clients;
export {
	CLIENT_AES,
	CLIENT_ELF,
	currentClient,
	currentClientAssetRoot,
	currentClientId,
	currentClientName,
	currentClientTheme
};