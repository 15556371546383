import * as authEvents from "../../store/events/auth";
import * as commonActions from "../../store/actions/common";
import { QUEUE_TASK_PAPERLESS_PRESENTMENT } from "../PostAuthTaskQueue/PostAuthTaskQueue";
import { connect } from "react-redux";
import { isStringOfLength } from "@pheaa/channels-component-library";
import { useEffect } from "react";
import useNavigateQSA from "../../utils/hooks/useNavigateQSA";
import {
	PATHNAME_HOME_VIEW_AUTHENTICATED,
	PS_EVENT_LOGOUT_INVALID_TOKEN,
	PS_EVENT_SESSION_END,
	PS_EVENT_SESSION_EXPIRED,
	PS_EVENT_SESSION_EXTEND,
	PS_EVENT_TASK_PAPERLESS_PREFS_COMPLETE
} from "../../config/common";
import {
	getLoginLocation,
	getLogoutLocation
} from "../../config/auth";
import store, { persistor } from "../../store";

let appSubscriptionsApplied = false;

const SubscriptionManager = ({ ps, ...rest }) => {

	const {
		endSession,
		extendSession,
		taskComplete
	} = rest;

	const navigate = useNavigateQSA();

	useEffect(() => {

		const createAppSubscriptions = () => {

			const handleLogoutInvalidToken = ({ returnEvent } = {}) => {
				// console.log("Token Invalid. Logging out...", returnEvent);
				navigate(getLoginLocation());
			};

			const handleSessionEnd = () => {
				// console.log("Session Ending. Invoking API to Terminate. Logging out...");
				endSession(() => {
					persistor.purge();
					navigate(getLogoutLocation());
				});
			};

			const handleSessionExpired = ({ returnEvent } = {}) => {
				// console.log("Session Expired.", returnEvent);
				persistor.purge();
				navigate(getLogoutLocation(true));
			};

			const handleSessionExtend = () => {
				// console.log("Session Extending. Invoking API to Extend.");
				extendSession();
			};

			const handleTaskPaperlessPreferencesComplete = () => {
				// console.log("Handling End of Paperless Preferences Task");
				const { taskManager } = store.getState().common;
				const { currentId, onEndTask } = taskManager;

				if (isStringOfLength(currentId) && isStringOfLength(onEndTask) && taskManager.currentId === QUEUE_TASK_PAPERLESS_PRESENTMENT) {
					taskComplete(QUEUE_TASK_PAPERLESS_PRESENTMENT);
				}

				navigate(isStringOfLength(onEndTask) ? onEndTask : PATHNAME_HOME_VIEW_AUTHENTICATED);
			};

			ps.subscribe(PS_EVENT_LOGOUT_INVALID_TOKEN, handleLogoutInvalidToken);
			ps.subscribe(PS_EVENT_SESSION_END, handleSessionEnd);
			ps.subscribe(PS_EVENT_SESSION_EXPIRED, handleSessionExpired);
			ps.subscribe(PS_EVENT_SESSION_EXTEND, handleSessionExtend);
			ps.subscribe(PS_EVENT_TASK_PAPERLESS_PREFS_COMPLETE, handleTaskPaperlessPreferencesComplete);
		};

		if (!appSubscriptionsApplied) {
			createAppSubscriptions(navigate);
			appSubscriptionsApplied = true;
		}
	}, [
		endSession,
		extendSession,
		navigate,
		ps,
		taskComplete
	]);

	return null;
};

const mapStateToProps = state => {
	return {};
};

const mapDispatchToProps = dispatch => {
	return {
		endSession: data => dispatch(authEvents.endSession(data)),
		extendSession: data => dispatch(authEvents.extendSession(data)),
		taskComplete: data => dispatch(commonActions.taskComplete(data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionManager);