import { currentClientName } from "../../config/clients";
import { isStringOfLength } from "@pheaa/channels-component-library";
import { useEffect, useRef } from "react";

const useDocumentTitle = (title, prevailOnUnmount = false) => {
	const defaultTitle = useRef(currentClientName);

	useEffect(() => {
		if (isStringOfLength(title)) {
			document.title = title;
		} else {
			document.title = defaultTitle.current;
		}
	}, [title]);

	useEffect(() => () => {
		if (!prevailOnUnmount) {
			document.title = defaultTitle.current;
		}
	}, [prevailOnUnmount]);
};

export default useDocumentTitle;