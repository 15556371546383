import Box from "@mui/material/Box";
import Button from "../../components/Button/Button";
import DefaultTemplate from "../templates/DefaultTemplate";
import { Form } from "@pheaa/channels-component-library";
import IAMErrorMessages from "../../components/ErrorMessages/IAMErrorMessages";
import TaskComplete from "../../components/Task/TaskComplete";
import TaskHeader from "../../components/Task/TaskHeader";
import Typography from "@mui/material/Typography";
import { connect } from "react-redux";
import useNavigateQSA from "../../utils/hooks/useNavigateQSA";
import withSessionManager from "../../utils/withSessionManager";
import {
	IAM_FLOW_AUTHENTICATE,
	hasAuthId,
	withAuthCondition
} from "../utils/iamUtils";
import React, { useEffect, useState } from "react";

const MINIMUM_WAIT = 1250;

const AuthCompleteView = props => {

	const {
		autoForward = true,
		isFetchingProfile,
		isPendingTransfer,
		isPendingTransferData,
		isTransferFailed
	} = props;

	const [isForwarding, setIsForwarding] = useState(autoForward);
	const [isForwardingAllowed, setIsForwardingAllowed] = useState(false);

	const navigate = useNavigateQSA();

	const handleSubmit = () => {
		setIsForwarding(true);
	};

	useEffect(() => {
		let fwdTimeout = null;

		if (!isForwardingAllowed) {
			fwdTimeout = setTimeout(() => {
				setIsForwardingAllowed(true);
			}, MINIMUM_WAIT);
		}

		return () => {
			clearTimeout(fwdTimeout);
			fwdTimeout = null;
		};
	}, [isForwardingAllowed]);

	useEffect(() => {
		if (isForwarding && isForwardingAllowed) {
			navigate("/post-auth", { replace: true });
		}
	}, [
		isForwarding,
		isForwardingAllowed,
		navigate
	]);

	return (
		<DefaultTemplate isTask={true}>
			<TaskHeader title="Authentication Complete" />
			<IAMErrorMessages />
			<TaskComplete>
				<Typography
					align="center"
					gutterBottom={true}
					paragraph={true}
				>
					Signing in...
				</Typography>
				<Form
					id="defaultFormNarrow"
					onSubmit={handleSubmit}
				>
					<Box
						mb={1.75}
						mt={5.625}
					>
						<Button
							disabled={isForwarding || isFetchingProfile || isPendingTransfer || isPendingTransferData || !(!isPendingTransfer && !isPendingTransferData && !isTransferFailed)}
							type="submit"
							variant="contained"
						>
							View Account
						</Button>
					</Box>
				</Form>
			</TaskComplete>
		</DefaultTemplate>
	);
};

const mapStateToProps = state => {
	return {
		isFetchingProfile: state.profile.isFetching,
		isPendingTransfer: state.auth.legacyTransferPending,
		isPendingTransferData: state.auth.legacyTransferDataPending,
		isTransferFailed: state.auth.legacyTransferFailed
	};
};

export default withSessionManager(withAuthCondition(connect(mapStateToProps)(AuthCompleteView), hasAuthId, IAM_FLOW_AUTHENTICATE));