import * as authEvents from "../../store/events/auth";
import Box from "@mui/material/Box";
import Button from "../../components/Button/Button";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import IAMErrorMessages from "../../components/ErrorMessages/IAMErrorMessages";
import { IAM_FLOW_AUTHENTICATE } from "../../views/utils/iamUtils";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Typography from "@mui/material/Typography";
import { connect } from "react-redux";
import { Form, generateId } from "@pheaa/channels-component-library";
import React, { useState } from "react";

const FormControlLabelDetails = props => {
	const { details, label } = props;
	return (
		<React.Fragment>
			<Typography className="cmp-remember-device__value" paragraph={true}>{label}</Typography>
			<Typography className="cmp-remember-device__details" paragraph={true}>{details}</Typography>
		</React.Fragment>
	);
};

const RememberDevice = props => {

	let alreadyTrustedElem = null;

	const {
		currentFlow,
		isPendingIAM,
		submitRememberDevice
	} = props;

	const [anchorElTrustDevice, setAnchorElTrustDevice] = useState(null);
	const [anchorElAlreadyTrusted, setAnchorElAlreadyTrusted] = useState(null);
	const [idFormLabel] = useState(generateId());

	const handleClickTrustDevice = event => {
		setAnchorElTrustDevice(event.currentTarget);
	};

	const handleClickAlreadyTrusted = event => {
		setAnchorElAlreadyTrusted(event.currentTarget);
	};

	const handleCloseTrustDevice = () => {
		setAnchorElTrustDevice(null);
	};

	const handleCloseAlreadyTrusted = () => {
		setAnchorElAlreadyTrusted(null);
	};

	const handleSubmit = (e, formData) => {
		submitRememberDevice(formData);
	};

	const openTrustDevice = Boolean(anchorElTrustDevice);
	const openAlreadyTrusted = Boolean(anchorElAlreadyTrusted);

	const idTrustDevice = openTrustDevice ? "trust-device" : undefined;
	const idAlreadyTrusted = openAlreadyTrusted ? "already-trusted" : undefined;

	if (currentFlow === IAM_FLOW_AUTHENTICATE) {
		alreadyTrustedElem = (
			<Box
				mb={1.75}
				mt={5.625}
			>
				<Typography
					component="p"
					textAlign="center"
					variant="h6"
				>
					Have you already trusted this device?
					<IconButton
						aria-describedby={idAlreadyTrusted}
						color="primary"
						onClick={handleClickAlreadyTrusted}
					>
						<HelpOutlineOutlinedIcon fontSize="medium" />
					</IconButton>
					<Popover
						id={idAlreadyTrusted}
						open={openAlreadyTrusted}
						anchorEl={anchorElAlreadyTrusted}
						onClose={handleCloseAlreadyTrusted}
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "center",
						}}
						transformOrigin={{
							vertical: "top",
							horizontal: "center",
						}}
					>
						<Box sx={{ m: 2, maxWidth: 325 }}>
							<Typography paragraph={true}>
								Trusted devices are recognized using cookies saved to your device. If you clear your browser cache or erase your cookies, you will be asked to re-verify your device.
							</Typography>
						</Box>
					</Popover>
				</Typography>
			</Box>
		);
	}

	return (
		<React.Fragment>
			<Typography
				align="center"
				gutterBottom={true}
				paragraph={true}
			>
				This device is not a trusted device.
			</Typography>
			<IAMErrorMessages />
			<Form
				id="rememberDeviceForm"
				onSubmit={handleSubmit}
			>
				<FormControl sx={{ width: "100%" }}>
					<FormLabel id={idFormLabel}>
						<Typography
							component="p"
							variant="h5"
						>
							Do you want to make this a trusted device?
							<IconButton
								aria-describedby={idTrustDevice}
								color="primary"
								onClick={handleClickTrustDevice}
							>
								<HelpOutlineOutlinedIcon fontSize="medium" />
							</IconButton>
							<Popover
								id={idTrustDevice}
								open={openTrustDevice}
								anchorEl={anchorElTrustDevice}
								onClose={handleCloseTrustDevice}
								anchorOrigin={{
									vertical: "bottom",
									horizontal: "center",
								}}
								transformOrigin={{
									vertical: "top",
									horizontal: "center",
								}}
							>
								<Box sx={{ m: 2, maxWidth: 325 }}>
									<Typography
										component="h2"
										gutterBottom={true}
										variant="h3"
									>
										Trusted Device
									</Typography>
									<Typography paragraph={true}>
										<strong>Yes</strong><br />
										This option allows you to securely sign in using this device in the future.
									</Typography>
									<Typography paragraph={true}>
										<strong>No</strong><br />
										This option will require an additional verification step each time you sign in to your account using this device.
									</Typography>
								</Box>
							</Popover>
						</Typography>
					</FormLabel>
					<RadioGroup
						aria-labelledby={idFormLabel}
						className="cmp-remember-device-list"
						name="saveDevice"
					>
						<FormControlLabel
							control={<Radio required size="small" />}
							label={
								<FormControlLabelDetails
									details="This option allows you to securely sign in using this device in the future."
									label="Yes"
								/>
							}
							value="yes"
						/>
						<FormControlLabel
							control={<Radio required size="small" />}
							label={
								<FormControlLabelDetails
									details="This option will require an additional verification step each time you sign in to your account using this device."
									label="No"
								/>
							}
							value="no"
						/>
					</RadioGroup>
				</FormControl>
				<Box
					mb={1.75}
					mt={5.625}
				>
					<Button
						disabled={isPendingIAM}
						type="submit"
						variant="contained"
					>
						Next
					</Button>
				</Box>
				{alreadyTrustedElem}
			</Form>
		</React.Fragment>
	);
};

const mapStateToProps = state => {
	return {
		currentFlow: state.auth.currentFlow,
		isPendingIAM: state.auth.isPendingIAM
	};
};

const mapDispatchToProps = dispatch => {
	return {
		submitRememberDevice: data => dispatch(authEvents.submitRememberDevice(data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(RememberDevice);