import { IAM_STATE } from "../../config/auth";
import InteractionProcessTimeout from "../../components/IAM/InteractionProcessTimeout";
import { setData } from "../../store/actions/auth";
import store from "../../store";
import useNavigateQSA from "../../utils/hooks/useNavigateQSA";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import React, { useEffect } from "react";
import {
	SYSTEM_ERRORS,
	SYSTEM_ERROR_MESSAGE_DEFAULT
} from "../../config/common";
import {
	isFunction,
	isNumber,
	isObject,
	isStringOfLength
} from "@pheaa/channels-component-library";

/** IAM variables declarations here, rather than config, to avoid undefined (circular) references */
export const IAM_FAILURE_REASONS_CODE_ACD = "acd";
export const IAM_FAILURE_REASONS_CODE_HLK = "hlk";
export const IAM_FAILURE_REASONS_CODE_CPI = "cpi";
export const IAM_FAILURE_REASONS_CODE_CTB = "ctb";
export const IAM_FAILURE_REASONS_CODE_EUS = "eus";
export const IAM_FAILURE_REASONS_CODE_EOP = "eop";
export const IAM_FAILURE_REASONS_CODE_FOD = "fod";
export const IAM_FAILURE_REASONS_CODE_IEU = "ieu";
export const IAM_FAILURE_REASONS_CODE_IOP = "iop";
export const IAM_FAILURE_REASONS_CODE_ICR = "icr";
export const IAM_FAILURE_REASONS_CODE_INP = "inp";
export const IAM_FAILURE_REASONS_CODE_SLK = "slk";
export const IAM_FAILURE_REASONS_CODE_OLE = "ole";
export const IAM_FAILURE_REASONS_CODE_MOP = "mop";
export const IAM_FAILURE_REASONS_CODE_MRO = "mro";
export const IAM_FAILURE_REASONS_CODE_MRR = "mro-registration";
export const IAM_FAILURE_REASONS_CODE_NFD = "nfd";
export const IAM_FAILURE_REASONS_CODE_NPE = "npe";
export const IAM_FAILURE_REASONS_CODE_PNA = "pna";
export const IAM_FAILURE_REASONS_CODE_REP = "rep";
export const IAM_FAILURE_REASONS_CODE_RPT = "rpt";
export const IAM_FAILURE_REASONS_CODE_BOT = "bot";
export const IAM_FAILURE_REASONS_CODE_ULI = "uli";
export const IAM_FAILURE_REASONS_CODE_EUN = "eun";
export const IAM_FAILURE_REASONS_CODE_PTO = "pto";
export const IAM_FAILURE_REASONS_CODE_VCR = "vcr";

export const IAM_FAILURE_REASONS = {
	[IAM_FAILURE_REASONS_CODE_ACD]: { message: "Account Conflict" },
	[IAM_FAILURE_REASONS_CODE_HLK]: { message: "Account Locked Indefinitely (Hard Lock)" },
	[IAM_FAILURE_REASONS_CODE_CPI]: { message: "Your credentials may not contain sensitive personal information." },
	[IAM_FAILURE_REASONS_CODE_CTB]: { message: "You have exceeded the allowed number of attempts to create a username and password." },
	[IAM_FAILURE_REASONS_CODE_EUS]: { message: "This account already exists, or the information entered may be incorrect." },
	[IAM_FAILURE_REASONS_CODE_EOP]: { message: "Your verification code expired. Please try again with the new verification code." },
	[IAM_FAILURE_REASONS_CODE_FOD]: { message: "We were unable to send your verification code." },
	[IAM_FAILURE_REASONS_CODE_IEU]: { message: "Based on the information you provided, you are not eligible to create an online account." },
	[IAM_FAILURE_REASONS_CODE_IOP]: { message: "Invalid Verification Code." },
	[IAM_FAILURE_REASONS_CODE_ICR]: { message: "Invalid username/password combination." },
	[IAM_FAILURE_REASONS_CODE_INP]: { message: "Incorrect information. Please try again." },
	[IAM_FAILURE_REASONS_CODE_SLK]: { message: "Account Locked Temporarily (Soft Lock)" },
	[IAM_FAILURE_REASONS_CODE_OLE]: { message: "You reached the limit on the amount of attempts you have to enter your verification code." },
	[IAM_FAILURE_REASONS_CODE_MOP]: { message: "You reached the limit on the amount of attempts you have to enter your verification code." },
	[IAM_FAILURE_REASONS_CODE_MRO]: { message: "We reached the limit on how many times we can resend your verification code." },
	[IAM_FAILURE_REASONS_CODE_MRR]: { message: "We reached the limit on how many times we can resend your verification code. As a result, please restart the registration process." },
	[IAM_FAILURE_REASONS_CODE_NFD]: { message: "We could not find your account, or the information entered may be incorrect." },
	[IAM_FAILURE_REASONS_CODE_NPE]: { message: "You reached the limit on the amount of attempts you have to enter your personal information." },
	[IAM_FAILURE_REASONS_CODE_PNA]: { message: "The requested password fails to meet the required criteria." },
	[IAM_FAILURE_REASONS_CODE_REP]: { message: "Your request to create a new password failed." },
	[IAM_FAILURE_REASONS_CODE_RPT]: { message: "You reached the limit on the amount of attempts you have to reset your password." },
	[IAM_FAILURE_REASONS_CODE_BOT]: { message: "Robot Detected" },
	[IAM_FAILURE_REASONS_CODE_ULI]: { message: "The requested username does not meet the required length criteria." },
	[IAM_FAILURE_REASONS_CODE_EUN]: { message: "The requested username is not available." },
	[IAM_FAILURE_REASONS_CODE_PTO]: { message: "Your session timed out. Please try again." },
	[IAM_FAILURE_REASONS_CODE_VCR]: { message: "Verification Code Resent" }
};

export const IAM_FLOW_AUTHENTICATE = "sign-in";
export const IAM_FLOW_REGISTER = "create-account";
export const IAM_FLOW_RECOVER_ID = "forgot-username";
export const IAM_FLOW_RESET_PASSWORD = "forgot-password";

export const IAM_STG_KEY_CREATE_CREDENTIALS = "create-credentials";
export const IAM_STG_KEY_CREATE_PASSWORD = "create-password";
export const IAM_STG_KEY_DEVICE = "device";
export const IAM_STG_KEY_EMAIL = "email";
export const IAM_STG_KEY_FINAL = "final";
export const IAM_STG_KEY_IDENTIFICATION = "identification";
export const IAM_STG_KEY_NPPI = "nppi";
export const IAM_STG_KEY_OTP = "otp";
export const IAM_STG_KEY_RECOVER_USER_ID = "recover-user-id";
export const IAM_STG_KEY_SECOND_FACTOR_SELECTION = "second-factor";
export const IAM_STG_KEY_TOS = "tos";

export const IAM_STG_COMPLETE = "complete";
export const IAM_STG_CREATE = "create";
export const IAM_STG_CREDENTIALS = "credentials";
export const IAM_STG_ACCOUNT_BLOCKED = "account-blocked";
export const IAM_STG_ACCOUNT_CONFLICT = "account-conflict";
export const IAM_STG_ACCOUNT_LOCKED = "account-locked";
export const IAM_STG_ELIGIBILITY = "eligibility";
export const IAM_STG_REMEMBER_DEVICE = "remember-device";
export const IAM_STG_SELECT_DEVICE = "select-device";
export const IAM_STG_TERMS = "terms";
export const IAM_STG_TRANSFER = "auth-to-portal";
export const IAM_STG_VERIFY_EMAIL = "verify-email";
export const IAM_STG_VERIFY_INFO = "verify-info";
export const IAM_STG_VERIFY_PASSCODE = "verify-passcode";
/** End IAM variable declarations */

export const getErrorMessageForCode = code => {
	const errors = { ...SYSTEM_ERRORS, ...IAM_FAILURE_REASONS };
	return (isObject(errors[code]) && isStringOfLength(errors[code].message)) ? errors[code].message : SYSTEM_ERROR_MESSAGE_DEFAULT;
};

export const getFlowRootStage = flow => {
	return IAM_STATE.find(state => (state.flow === flow && state.flowRoot === true)).stage;
};

export const getPathForFlowAndStage = (flow, stage) => {
	return IAM_STATE.find(state => state.flow === flow && state.stage === stage).path;
};

const hasAuthAccountBlocked = () => {
	const { auth } = store.getState();
	return isObject(auth) && auth.authAccountBlocked;
};

const hasAuthAccountConflict = () => {
	const { auth } = store.getState();
	return isObject(auth) && auth.authAccountConflict;
};

const hasAuthDataUserId = () => {
	const { auth } = store.getState();
	return isObject(auth) && isObject(auth.data) && isStringOfLength(auth.data.userId);
};

const hasAuthId = () => {
	const { auth } = store.getState();
	return isObject(auth) && isObject(auth.responseDataIAM) && isStringOfLength(auth.responseDataIAM.authId);
};

const hasAuthLockExpiry = () => {
	const { auth } = store.getState();
	return isObject(auth) && isNumber(auth.authLockExpiry);
};

const isAuthView = (path = window.location.pathname) => {
	return IAM_STATE.filter(state => `/${state.path}` === path || `/${state.pathAlias}` === path).length > 0;
};

const withAuthCondition = (WrappedComponent, conditionFn, fallbackFlow = IAM_FLOW_AUTHENTICATE) => props => {

	if (isFunction(conditionFn) && conditionFn()) {
		return <WrappedComponent {...props} />;
	} else {
		if (fallbackFlow === IAM_FLOW_REGISTER) {
			return <Navigate to={`/${getPathForFlowAndStage(IAM_FLOW_REGISTER, getFlowRootStage(IAM_FLOW_REGISTER))}`} replace={true} />;
		} else if (fallbackFlow === IAM_FLOW_RECOVER_ID) {
			return <Navigate to={`/${getPathForFlowAndStage(IAM_FLOW_RECOVER_ID, getFlowRootStage(IAM_FLOW_RECOVER_ID))}`} replace={true} />;
		} else if (fallbackFlow === IAM_FLOW_RESET_PASSWORD) {
			return <Navigate to={`/${getPathForFlowAndStage(IAM_FLOW_RESET_PASSWORD, getFlowRootStage(IAM_FLOW_RESET_PASSWORD))}`} replace={true} />;
		} else {
			return <Navigate to={`/${getPathForFlowAndStage(IAM_FLOW_AUTHENTICATE, getFlowRootStage(IAM_FLOW_AUTHENTICATE))}`} replace={true} />;
		}
	}
};

const withAuthNavigator = (WrappedComponent, flow, stage) => props => {

	const { auth } = useSelector(state => state);
	const location = useLocation();
	const navigate = useNavigateQSA();

	useEffect(() => {
		if (auth.currentFlow !== flow || auth.currentStage !== stage) {
			store.dispatch(setData({ currentFlow: flow, currentStage: stage }));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	useEffect(() => {
		if (isStringOfLength(auth.targetPathname)) {
			const { targetPathname } = auth;
			store.dispatch(setData({ targetPathname: null }));
			navigate(`/${targetPathname}`, { replace: true });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [auth.targetPathname]);

	return (
		<React.Fragment>
			<InteractionProcessTimeout />
			<WrappedComponent {...props} />
		</React.Fragment>
	);
};

export {
	hasAuthAccountBlocked,
	hasAuthAccountConflict,
	hasAuthDataUserId,
	hasAuthId,
	hasAuthLockExpiry,
	isAuthView,
	withAuthCondition,
	withAuthNavigator
};