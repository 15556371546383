import AccountLocked from "../../components/IAM/AccountLocked";
import DefaultTemplate from "../templates/DefaultTemplate";
import React from "react";
import TaskHeader from "../../components/Task/TaskHeader";
import {
	IAM_FLOW_AUTHENTICATE,
	getFlowRootStage,
	getPathForFlowAndStage,
	hasAuthLockExpiry,
	withAuthCondition
} from "../utils/iamUtils";

const AuthAccountLockedView = props => {
	return (
		<DefaultTemplate isTask={true}>
			<TaskHeader
				closeResetsAuthFlow={true}
				closeTaskDestination={`/${getPathForFlowAndStage(IAM_FLOW_AUTHENTICATE, getFlowRootStage(IAM_FLOW_AUTHENTICATE))}`}
				title="Account Temporarily Locked"
			/>
			<AccountLocked />
		</DefaultTemplate>
	);
};

export default withAuthCondition(AuthAccountLockedView, hasAuthLockExpiry, IAM_FLOW_AUTHENTICATE);