import * as authActions from "../../store/actions/auth";
import * as authEvents from "../../store/events/auth";
import Box from "@mui/material/Box";
import Button from "../../components/Button/Button";
import CircularIndeterminate from "../../components/Loading/CircularIndeterminate";
import DefaultTemplate from "../templates/DefaultTemplate";
import { SYSTEM_ERROR_MESSAGE_LTK } from "../../config/common";
import TaskHeader from "../../components/Task/TaskHeader";
import { connect } from "react-redux";
import { persistor } from "../../store";
import ErrorMessages, { ERROR_MESSAGE_TYPE_DEFAULT } from "../../components/ErrorMessages/ErrorMessages";
import { Form, isStringOfLength } from "@pheaa/channels-component-library";
import React, { useEffect, useState } from "react";

const MAX_RETRIES = 2;

const AuthTransferView = props => {

	const {
		getLegacyTransferData,
		isAuthenticated,
		legacyTransferDataPending,
		legacyTransferFailed,
		legacyTransferPending,
		legacyTransferUrl,
		setData
	} = props;

	const [authTransferErr, setAuthTransferErr] = useState([]);
	const [retryCount, setRetryCount] = useState(0);

	const handleSubmit = () => {
		setRetryCount(current => current + 1);
		getLegacyTransferData();
	};

	useEffect(() => {
		if (legacyTransferFailed) {
			setAuthTransferErr([{
				id: "legacyTransferError",
				message: SYSTEM_ERROR_MESSAGE_LTK,
				type: ERROR_MESSAGE_TYPE_DEFAULT
			}]);
		} else {
			setAuthTransferErr([]);
		}
	}, [legacyTransferFailed]);

	useEffect(() => {
		if (isAuthenticated && !legacyTransferDataPending && !isStringOfLength(legacyTransferUrl) && retryCount < MAX_RETRIES) {
			getLegacyTransferData();
		}
	}, [
		getLegacyTransferData,
		isAuthenticated,
		legacyTransferDataPending,
		legacyTransferUrl,
		retryCount
	]);

	useEffect(() => {
		if (isStringOfLength(legacyTransferUrl)) {
			setData({ legacyTransferPending: true });
			persistor.purge();
			window.location.href = legacyTransferUrl;
		}
	}, [
		legacyTransferUrl,
		setData
	]);

	return (
		<DefaultTemplate isTask={true}>
			<TaskHeader title="Transferring to Your Account" />
			<ErrorMessages errorMessages={authTransferErr} />
			<Form
				id="defaultFormNarrow"
				onSubmit={handleSubmit}
			>
				{
					(legacyTransferDataPending || legacyTransferPending) && (
						<Box sx={{ alignItems: "center", display: "flex", justifyContent: "center", margin: "2rem auto" }}>
							<CircularIndeterminate />
						</Box>
					)
				}
				<Box
					mb={1.75}
					mt={5.625}
				>
					<Button
						disabled={legacyTransferDataPending || legacyTransferPending || retryCount >= MAX_RETRIES}
						type="submit"
						variant="contained"
					>
						{retryCount >= MAX_RETRIES ? "Try Again Later" : "Retry"}
					</Button>
				</Box>
			</Form>
		</DefaultTemplate>
	);
};

const mapStateToProps = state => {
	return {
		isAuthenticated: state.auth.isAuthenticated,
		legacyTransferDataPending: state.auth.legacyTransferDataPending,
		legacyTransferFailed: state.auth.legacyTransferFailed,
		legacyTransferPending: state.auth.legacyTransferPending,
		legacyTransferUrl: state.auth.legacyTransferUrl
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getLegacyTransferData: data => dispatch(authEvents.getLegacyTransferData(data)),
		setData: data => dispatch(authActions.setData(data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthTransferView);