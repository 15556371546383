import * as authEvents from "../../store/events/auth";
import Box from "@mui/material/Box";
import Button from "../../components/Button/Button";
import DefaultTemplate from "../templates/DefaultTemplate";
import { Form } from "@pheaa/channels-component-library";
import FormTextField from "../../components/Form/FormTextField";
import IAMErrorMessages from "../../components/ErrorMessages/IAMErrorMessages";
import React from "react";
import TaskHeader from "../../components/Task/TaskHeader";
import Typography from "@mui/material/Typography";
import { connect } from "react-redux";
import {
	IAM_FLOW_AUTHENTICATE,
	IAM_FLOW_REGISTER,
	getFlowRootStage,
	getPathForFlowAndStage,
	hasAuthId,
	withAuthCondition
} from "../utils/iamUtils";

const RegistrationVerifyEmailView = props => {

	const {
		isPendingIAM,
		submitVerifyEmail,
		userEmail
	} = props;

	const handleSubmit = (e, formData) => {
		submitVerifyEmail(formData);
	};

	return (
		<DefaultTemplate isTask={true}>
			<TaskHeader
				closeResetsAuthFlow={true}
				closeTaskDestination={`/${getPathForFlowAndStage(IAM_FLOW_AUTHENTICATE, getFlowRootStage(IAM_FLOW_AUTHENTICATE))}`}
				title="Email Address"
			/>
			<Typography
				align="center"
				gutterBottom={true}
				paragraph={true}
			>
				Your email address will be used to send you a code to verify your identity.
			</Typography>
			<Form
				id="defaultFormNarrow"
				onSubmit={handleSubmit}
			>
				<IAMErrorMessages />
				<FormTextField
					name="email"
					required={true}
					title="Email"
					value={userEmail}
				/>
				<Box
					mb={1.75}
					mt={5.625}
				>
					<Button
						disabled={isPendingIAM}
						type="submit"
						variant="contained"
					>
						Continue
					</Button>
				</Box>
			</Form>
		</DefaultTemplate>
	);
};

const mapStateToProps = state => {
	return {
		isPendingIAM: state.auth.isPendingIAM,
		userEmail: state.auth.data.userEmail
	};
};

const mapDispatchToProps = dispatch => {
	return {
		submitVerifyEmail: data => dispatch(authEvents.submitVerifyEmail(data))
	};
};

export default withAuthCondition(connect(mapStateToProps, mapDispatchToProps)(RegistrationVerifyEmailView), hasAuthId, IAM_FLOW_REGISTER);