import { configureStore } from "@reduxjs/toolkit";
import logger from "./logger";
import rootReducer from "./reducers/root";
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistStore } from "redux-persist";

const store = configureStore({
	devTools: process.env.NODE_ENV !== "production",
	middleware: (getDefaultMiddleware) => getDefaultMiddleware({
		serializableCheck: {
			ignoreActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
		}
	}).concat(logger),
	reducer: rootReducer
});

const persistor = persistStore(store);

export default store;
export { persistor };