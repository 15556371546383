import * as authEvents from "../../store/events/auth";
import Box from "@mui/material/Box";
import Button from "../../components/Button/Button";
import DefaultTemplate from "../templates/DefaultTemplate";
import { Form } from "@pheaa/channels-component-library";
import FormTextField from "../../components/Form/FormTextField";
import IAMErrorMessages from "../../components/ErrorMessages/IAMErrorMessages";
import React from "react";
import TaskHeader from "../../components/Task/TaskHeader";
import { connect } from "react-redux";
import {
	HTML_ATTR_PATTERN_PASSWORD,
	HTML_ATTR_PATTERN_USER_ID
} from "../../config/common";
import {
	IAM_FLOW_AUTHENTICATE,
	IAM_FLOW_REGISTER,
	getFlowRootStage,
	getPathForFlowAndStage,
	hasAuthId,
	withAuthCondition
} from "../utils/iamUtils";

const additionalDetailsUserId = `
	<p>Username requirements:</p>
	<ul>
		<li>Must be between 6 to 12 characters</li>
		<li>May contain numbers, letters, and underscores</li>
	</ul>
`;

export const additionalDetailsPassword = `
	<p>Password requirements:</p>
	<ul>
		<li>Be at least eight characters long</li>
		<li>Contain a letter</li>
		<li>Contain a number</li>
		<li>May contain these optional characters:
			<ul>
				<li>! - # $ % * + = , . ? / \\ &amp; _ ^ @</li>
			</ul>
		</li>
	</ul>
	<p>You may use a passphrase if it is at least 21 characters long. Also, the requirements above do not apply.</p>
`;

const RegistrationCreateCredentialsView = props => {

	const {
		isPendingIAM,
		isUserMaxCreateCredentialsAttempts,
		submitCreateCredentials
	} = props;

	const handleSubmit = (e, formData) => {
		submitCreateCredentials(formData);
	};

	return (
		<DefaultTemplate isTask={true}>
			<TaskHeader
				closeResetsAuthFlow={true}
				closeTaskDestination={`/${getPathForFlowAndStage(IAM_FLOW_AUTHENTICATE, getFlowRootStage(IAM_FLOW_AUTHENTICATE))}`}
				title="Create Credentials"
			/>
			<Form
				id="defaultFormNarrow"
				onSubmit={handleSubmit}
			>
				<IAMErrorMessages />
				<FormTextField
					additionalDetails={additionalDetailsUserId}
					additionalDetailsOnFocus={true}
					name="userId"
					pattern={HTML_ATTR_PATTERN_USER_ID}
					required={true}
					title="Username"
				/>
				<FormTextField
					additionalDetails={additionalDetailsPassword}
					additionalDetailsOnFocus={true}
					includeConfirmation={true}
					name="password"
					passwordRevealEnabled={true}
					pattern={HTML_ATTR_PATTERN_PASSWORD}
					required={true}
					title="Password"
					type="password"
				/>
				<Box
					mb={1.75}
					mt={5.625}
				>
					<Button
						disabled={isPendingIAM || isUserMaxCreateCredentialsAttempts}
						type="submit"
						variant="contained"
					>
						Continue
					</Button>
				</Box>
			</Form>
		</DefaultTemplate>
	);
};

const mapStateToProps = state => {
	return {
		isPendingIAM: state.auth.isPendingIAM,
		isUserMaxCreateCredentialsAttempts: state.auth.data.userMaxCreateCredentialsAttempts
	};
};

const mapDispatchToProps = dispatch => {
	return {
		submitCreateCredentials: data => dispatch(authEvents.submitCreateCredentials(data))
	};
};

export default withAuthCondition(connect(mapStateToProps, mapDispatchToProps)(RegistrationCreateCredentialsView), hasAuthId, IAM_FLOW_REGISTER);