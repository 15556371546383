import { IAM_STATE } from "../config/auth";
import { currentClient } from "../config/clients";
import { getMaintenanceStatus } from "../store/events/common";
import { routeMap } from "../components/RouteManager/RouteManager";
import { setData as setDataCommon } from "../store/actions/common";
import store from "../store";
import { verifySession } from "../store/events/auth";
import { CONFIG_FILENAME, currentDate } from "../config/common";
import { isArrayOfLength, isDate, isObject, isStringOfLength } from "@pheaa/channels-component-library";

const applyLinksToHead = () => {
	const headElem = document.querySelector("head");
	const linkConfig = [
		{ href: `${process.env.PUBLIC_URL}/assets/${currentClient}/favicon.ico`, rel: "icon" },
		{ href: `${process.env.PUBLIC_URL}/assets/${currentClient}/apple-touch-icon.png`, rel: "apple-touch-icon" },
		{ href: `${process.env.PUBLIC_URL}/assets/${currentClient}/manifest.json`, rel: "manifest" }
	];
	// Iterate over link config to append links to <head> element;
	linkConfig.forEach(linkConfig => headElem.appendChild(createLink(linkConfig)));
};

const createLink = ({ href, rel } = {}) => {
	const link = document.createElement("link");
	link.href = href;
	link.rel = rel;
	return link;
};

const getConfig = () => {
	return fetch(`${process.env.PUBLIC_URL}/${CONFIG_FILENAME}`);
};

const initializeApplication = async () => {
	const { pathname, search } = window.location;
	const publicRoutes = [];
	const initializationPromises = [];

	const queryParams = new URLSearchParams(search);
	const returnKey = queryParams.get("returnKey");

	applyLinksToHead();

	if (isStringOfLength(returnKey)) {
		store.dispatch(setDataCommon({ returnKey }));
	}

	initializationPromises.push(getConfig());
	initializationPromises.push(store.dispatch(getMaintenanceStatus()));

	IAM_STATE.forEach(state => {
		isStringOfLength(state.path) && publicRoutes.push(`/${state.path}`);
		isStringOfLength(state.pathAlias) && publicRoutes.push(`/${state.pathAlias}`);
	});

	if (!publicRoutes.includes(pathname)) {
		if (routeMap.some(privateRoute => privateRoute.route === `/${pathname}`)) {
			initializationPromises.push(store.dispatch(verifySession()));
		}
	}

	if (isArrayOfLength(initializationPromises)) {
		return Promise.all(initializationPromises).then(async ([configResponse]) => {
			let commonData = { isAppInitialized: true };

			try {
				const body = await configResponse.json();
				const configResponseDate = new Date(configResponse.headers.get("date"));
				isDate(configResponseDate) && currentDate.setCurrentDate(configResponseDate);
				isObject(body) && (commonData.appConfig = body);
			} catch (e) {
				console.warn(`Error parsing application configuration from ${CONFIG_FILENAME}.`, e);
			}

			store.dispatch(setDataCommon(commonData));
		});
	}

	return Promise.resolve();
};

export default initializeApplication;