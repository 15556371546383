import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import React from "react";
import Typography from "@mui/material/Typography";
import classNames from "classnames";
import { connect } from "react-redux";
import { getFlowRootStage } from "../../views/utils/iamUtils";
import { resetData } from "../../store/actions/auth";
import useNavigateQSA from "../../utils/hooks/useNavigateQSA";
import { isFunction, isStringOfLength } from "@pheaa/channels-component-library";

const TaskHeader = props => {

	let backElem = null;
	let closeElem = null;

	const {
		backCallback,
		backDestination,
		backDisabled = false,
		backLabel = "Previous Step",
		className,
		closeCallback,
		closeResetsAuthFlow = false,
		closeTaskDestination,
		closeTaskDisabled = false,
		closeTaskLabel = "Close",
		currentAuthFlow,
		resetAuthData,
		title = "Sign In"
	} = props;

	const isBack = (isStringOfLength(backDestination) || isFunction(backCallback)) && isStringOfLength(backLabel);
	const isClose = (isStringOfLength(closeTaskDestination) || isFunction(closeCallback)) && isStringOfLength(closeTaskLabel);
	const navigate = useNavigateQSA();

	const cmpClassName = classNames({
		"cmp-task-header": true,
		"cmp-task-header--isBack": isBack,
		"cmp-task-header--isClose": isClose,
		[className]: isStringOfLength(className)
	});

	const handleBack = e => {
		isFunction(backCallback) && backCallback();

		if (isStringOfLength(backDestination)) {
			navigate(backDestination, { replace: true });
		}
	};

	const handleClose = e => {
		isFunction(closeCallback) && closeCallback();

		if (isStringOfLength(closeTaskDestination)) {
			navigate(closeTaskDestination, { replace: true });
		}

		if (closeResetsAuthFlow) {
			resetAuthData({
				currentFlow: currentAuthFlow,
				currentStage: getFlowRootStage(currentAuthFlow)
			});
		}
	};

	if (isBack) {
		backElem = (
			<IconButton
				className="cmp-task-header__back"
				color="primary"
				disabled={backDisabled}
				onClick={handleBack}
				sx={{
					justifySelf: "start",
					padding: 0
				}}
			>
				<KeyboardBackspaceIcon fontSize="large" />
				<span className="cmp-task-header__back-label">{backLabel}</span>
			</IconButton>
		);
	}

	if (isClose) {
		closeElem = (
			<IconButton
				className="cmp-task-header__close"
				color="primary"
				disabled={closeTaskDisabled}
				onClick={handleClose}
				sx={{
					justifySelf: "end",
					padding: 0
				}}
			>
				<CloseIcon fontSize="large" />
				<span className="cmp-task-header__close-label">{closeTaskLabel}</span>
			</IconButton>
		);
	}

	return (
		<div className={cmpClassName} id="taskHeader">
			{backElem}

			<Typography
				className="cmp-task-header__label"
				component="h1"
				my={2.25}
				textAlign={isBack ? "center" : "left"}
				variant="h3"
			>
				{title}
			</Typography>
			{closeElem}
		</div>
	);
};

const mapStateToProps = state => {
	return {
		currentAuthFlow: state.auth.currentFlow
	};
};

const mapDispatchToProps = dispatch => {
	return {
		resetAuthData: data => dispatch(resetData(data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskHeader);