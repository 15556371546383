const logger = store => next => action => {

	if (process.env.NODE_ENV !== "production") {
		console.group(action.type);
		console.info("State Prior to Dispatch:", store.getState());
		console.info("Dispatching", action);
	}

	let result = next(action);

	if (process.env.NODE_ENV !== "production") {
		console.info("State After Dispatch:", store.getState());
		console.groupEnd();
	}

	return result;
};

export default logger;