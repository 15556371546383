import { isDate, isNumber } from "@pheaa/channels-component-library";

const getOffsetDate = (initialDateTime, offsetDays) => {

	try {
		offsetDays = parseInt(offsetDays, 10);
		const initDateTime = new Date(initialDateTime);
		if (isDate(initDateTime) && isNumber(offsetDays)) {
			const normalizedInitDate = new Date(initDateTime.toDateString());
			const offsetDate = normalizedInitDate.setDate(normalizedInitDate.getDate() + offsetDays);
			return new Date(offsetDate);
		} else {
			throw new Error("Could not offset date: invalid date or offset.");
		}
	} catch (e) {
		console.error(e.message);
	}
};

export default getOffsetDate;