import Box from "@mui/material/Box";
import Button from "../../components/Button/Button";
import { Form } from "@pheaa/channels-component-library";
import React from "react";
import Typography from "@mui/material/Typography";
import { connect } from "react-redux";
import useNavigateQSA from "../../utils/hooks/useNavigateQSA";
import { getFlowRootStage, getPathForFlowAndStage } from "../../views/utils/iamUtils";

const getAccountConflictDisclaimer = () => {
	let disclaimer = (
		<React.Fragment>
			To resolve this issue, please contact us at <span className="no-wrap">1-800-233-0557</span>.
		</React.Fragment>
	);

	return disclaimer;
};

const AccountConflict = props => {

	const { currentFlow } = props;

	const navigate = useNavigateQSA();

	const handleSubmit = () => {
		navigate(`/${getPathForFlowAndStage(currentFlow, getFlowRootStage(currentFlow))}`, { replace: true });
	};

	return (
		<Form
			id="defaultFormNarrow"
			onSubmit={handleSubmit}
		>
			<Box
				component="img"
				mb={2.8125}
				mt={1.75}
				mx="auto"
				sx={{
					display: "block",
					height: 150,
					width: 150
				}}
				alt="Alert Icon"
				src={`${process.env.PUBLIC_URL}/assets/general/alert-gray.svg`}
			/>
			<Typography
				align="center"
				gutterBottom={true}
				paragraph={true}
				variant="h5"
			>
				Profile discrepancy found.
			</Typography>
			<Typography
				align="center"
				gutterBottom={true}
				paragraph={true}
			>
				{getAccountConflictDisclaimer()}
			</Typography>
			<Box
				mb={1.75}
				mt={5.625}
			>
				<Button
					type="submit"
					variant="contained"
				>
					Return to Sign In
				</Button>
			</Box>
		</Form>
	);
};

const mapStateToProps = state => {
	return {
		currentFlow: state.auth.currentFlow
	};
};

export default connect(mapStateToProps)(AccountConflict);