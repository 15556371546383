import BasicModal from "../Modal/BasicModal";
import Box from "@mui/material/Box";
import Button from "../../components/Button/Button";
import IAMErrorMessages from "../ErrorMessages/IAMErrorMessages";
import { IAM_SESSION_TIMEOUT_ALERT_DURATION } from "../../config/auth";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import { connect } from "react-redux";
import { ps } from "../../components/Application/Application";
import useInterval from "../../utils/hooks/useInterval";
import { PS_EVENT_SESSION_END, PS_EVENT_SESSION_EXPIRED, PS_EVENT_SESSION_EXTEND } from "../../config/common";
import React, { useEffect, useState } from "react";
import { format, isNumber } from "@pheaa/channels-component-library";

// TODO: Confirm variable is set to zero for production environment;
const SESSION_TIMEOUT_OFFSET = process.env.NODE_ENV === "production" ? 0 : 0;

const IconClock = props => {
	return (
		<SvgIcon viewBox="0 0 40 40" {...props}>
			<g fill="white">
				<path d="M19.75 38.75C30.2434 38.75 38.75 30.2434 38.75 19.75C38.75 9.25659 30.2434 0.75 19.75 0.75C9.25659 0.75 0.75 9.25659 0.75 19.75C0.75 30.2434 9.25659 38.75 19.75 38.75Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M20.75 5.75V21.85L30.75 26.75" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
			</g>
		</SvgIcon>
	);
};

const SessionManager = props => {

	let returnElem = null;

	const {
		authAccessExpiresAt,
		children,
		isPendingSessionEnd,
		isPendingSessionExtension
	} = props;

	const [timeoutData, setTimeoutData] = useState({
		displayModal: false,
		secondsToExpiry: null,
		timedOut: false
	});

	const handleEndSession = () => {
		ps.publish(PS_EVENT_SESSION_END);
	};

	const handleExpireSession = () => {
		ps.publish(PS_EVENT_SESSION_EXPIRED, { returnEvent: window.location.pathname });
	};

	const handleExtendSession = () => {
		ps.publish(PS_EVENT_SESSION_EXTEND);
	};

	useEffect(() => {
		// TODO: Confirm process.env.NODE_ENV !== "production" is uncommented following testing;
		if (timeoutData.secondsToExpiry % 10 === 0 && process.env.NODE_ENV !== "production") {
			console.log(timeoutData);
		}
	}, [
		timeoutData
	]);

	useEffect(() => {
		if (timeoutData.timedOut) {
			handleExpireSession();
		}
	}, [timeoutData.timedOut]);

	useInterval(() => {
		if (isNumber(authAccessExpiresAt)) {
			let now = Math.floor(new Date().getTime() / 1000) * 1000;
			let secondsToExpiry = Math.floor((authAccessExpiresAt - now) / 1000) - SESSION_TIMEOUT_OFFSET;
			const timedOut = secondsToExpiry <= 0;

			setTimeoutData(current => ({
				...current,
				displayModal: secondsToExpiry <= IAM_SESSION_TIMEOUT_ALERT_DURATION,
				secondsToExpiry,
				timedOut
			}));
		}
	}, 1000);

	if (isNumber(timeoutData.secondsToExpiry)) {
		let { value, title } = format.asHourMinSec(timeoutData.secondsToExpiry);
		returnElem = (
			<BasicModal open={timeoutData.displayModal}>
				<Typography
					gutterBottom={true}
					mb={1.75}
					variant="h2"
				>
					Session Timeout
				</Typography>
				<Typography paragraph={true}>
					Your session will end in:
				</Typography>
				<Typography paragraph={true} variant="h2" title={title} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
					<IconClock sx={{ height: "40px", marginRight: 1.5, width: "40px" }} /> {value}
				</Typography>
				<Typography paragraph={true}>
					Do you wish to remain signed in?
				</Typography>
				<IAMErrorMessages />
				<Box
					mb={1.75}
					mt={5.625}
				>
					<Button
						align="center"
						disabled={isPendingSessionExtension || isPendingSessionEnd}
						onClick={handleExtendSession}
						sx={{ width: 325 }}
						type="button"
						variant="contained"
					>
						Keep Me Signed In
					</Button>
				</Box>
				<Box mt={1.75}>
					<Button
						align="center"
						disabled={isPendingSessionExtension || isPendingSessionEnd}
						onClick={handleEndSession}
						sx={{ width: 325 }}
						variant="outlined"
					>
						Sign Out
					</Button>
				</Box>
			</BasicModal>
		);
	}


	return (
		<React.Fragment>
			{returnElem}
			{children}
		</React.Fragment>
	);
};

const mapStateToProps = state => {
	return {
		authAccessExpiresAt: state.auth.authAccessExpiresAt,
		isPendingSessionEnd: state.auth.isPendingSessionEnd,
		isPendingSessionExtension: state.auth.isPendingSessionExtension
	};
};

export default connect(mapStateToProps)(SessionManager);