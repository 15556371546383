import Box from "@mui/material/Box";
import Button from "../../components/Button/Button";
import Container from "@mui/material/Container";
import DefaultTemplate from "../templates/DefaultTemplate";
import { PS_EVENT_TASK_PAPERLESS_PREFS_COMPLETE } from "../../config/common";
import React from "react";
import Skeleton from "@mui/material/Skeleton";
import TaskComplete from "../../components/Task/TaskComplete";
import TaskHeader from "../../components/Task/TaskHeader";
import Typography from "@mui/material/Typography";
import { connect } from "react-redux";
import { ps } from "../../components/Application/Application";
import withRequireProfile from "../../utils/withRequireProfile";
import {
	Form,
	format,
	isArrayOfLength,
	isObject
} from "@pheaa/channels-component-library";
import {
	PAPERLESS_CONFIG,
	PAPRELESS_ENROLLMENT_STATUS,
	getPaperlessProgramConfigById,
	getPaperlessProgramIndexById
} from "./PaperlessPreferencesView";

const PaperlessPreferencesUpdatedView = props => {

	let contactEmailAddr = <Skeleton sx={{ display: "inline-block", fontSize: "2.4rem" }} width={180} />;
	let legalMailingAddr = <Skeleton sx={{ display: "inline-block", fontSize: "2.4rem" }} width={180} />;
	let hasFullEnrollment = false;
	let hasContactEmailAddr = false;
	let hasLegalMailingAddr = false;

	const {
		profileData
	} = props;

	let enrollmentData = {
		status: PAPRELESS_ENROLLMENT_STATUS.NONE,
		optIns: PAPERLESS_CONFIG.optIns.slice()
	};

	const handleClose = () => {
		ps.publish(PS_EVENT_TASK_PAPERLESS_PREFS_COMPLETE);
	};

	if (isObject(profileData) && isObject(profileData.ecorrespondence)) {
		let { optIns = [] } = profileData.ecorrespondence;

		if (isArrayOfLength(optIns)) {
			// Iterate over optIns;
			optIns.forEach(optIn => {
				// Set enrollmentData for optIn by index (maintaining sortability);
				enrollmentData.optIns[getPaperlessProgramIndexById(optIn.id)] = {
					...getPaperlessProgramConfigById(optIn.id),
					isEligible: optIn.isEligible,
					isIn: optIn.isIn
				};
			});
		}

		// Filter optIns array to include only those for which this user is eligible;
		enrollmentData.optIns = enrollmentData.optIns.filter(program => program.isEligible);

		hasFullEnrollment = enrollmentData.optIns.filter(program => program.isIn).length === enrollmentData.optIns.length;
	}

	if (isObject(profileData)) {
		try {
			if (isArrayOfLength(profileData.emails)) {
				contactEmailAddr = profileData.emails.find(emailObj => emailObj.id === "contact").email;
				hasContactEmailAddr = true;
			} else {
				contactEmailAddr = "Unknown Contact Email Address";
			}
		} catch (e) {
			console.log("No Contact Email Address in Profile.", e);
			contactEmailAddr = null;
		}

		try {
			if (isArrayOfLength(profileData.addresses)) {
				legalMailingAddr = format.asAddress(profileData.addresses.find(addrObj => addrObj.id === "legal").address);
				hasLegalMailingAddr = true;
			} else {
				legalMailingAddr = "Unknown Legal Mailing Address";
			}
		} catch (e) {
			console.log("No Legal Address in Profile.", e);
			legalMailingAddr = null;
		}
	}

	return (
		<DefaultTemplate isTask={true}>
			<TaskHeader
				closeCallback={handleClose}
				title="Paperless Enrollment"
			/>
			<Container maxWidth="sm">
				<Typography align="center" fontSize={16} paragraph={true}>
					You have successfully updated your Paperless Enrollment.
				</Typography>
				<Typography align="center" fontSize={16} paragraph={true}>
					You can manage this in your profile.
				</Typography>
				<TaskComplete>
					<Form
						id="defaultForm"
						onSubmit={handleClose}
					>
						<Box align="center">
							<Typography
								align="left"
								className="cmp-paperless-programs-list"
								component="ul"
								gutterBottom={true}
								sx={{ display: "inline-block" }}
							>
								{
									enrollmentData.optIns.map((program, key) => {
										let a11yElem = program.isIn ? (<span>Currently Enrolled:</span>) : (<span>Not Enrolled</span>);
										return (
											<Typography
												component="li"
												data-enrolled={program.isIn}
												key={key}
											>
												{a11yElem} {program.title}
											</Typography>
										);
									})
								}
							</Typography>
						</Box>
						{
							hasContactEmailAddr && (
								<Typography align="left" paragraph={true}>
									An email confirming your changes to Paperless Enrollment has been sent to: <b>{contactEmailAddr}</b>. To make sure you receive our email notifications, add ecorr@aessuccess.org to your address book. If you use a spam or junk mail filter, please put us on your safe list.
								</Typography>
							)
						}
						{
							!hasFullEnrollment && hasLegalMailingAddr && (
								<Typography align="left" paragraph={true}>
									Any correspondence you indicate not to receive electronically will be sent by U.S. Mail to the permanent address we have on file: <b>{legalMailingAddr}</b>.
								</Typography>
							)
						}
						<Typography align="left" paragraph={true}>
							We'll send you email reminders with a link each time we deliver these documents to your account inbox for you to review.
						</Typography>
						<Box
							mb={10}
							mt={5.625}
						>
							<Button
								type="submit"
								variant="contained"
							>
								Done
							</Button>
						</Box>
					</Form>
				</TaskComplete>
			</Container>
		</DefaultTemplate>
	);
};

const mapStateToProps = state => {
	return {
		profileData: state.profile.data,
	};
};

export default withRequireProfile(connect(mapStateToProps)(PaperlessPreferencesUpdatedView));