import AuthAccountBlockedView from "../views/authentication/AuthAccountBlockedView";
import AuthAccountConflictView from "../views/authentication/AuthAccountConflictView";
import AuthAccountLockedView from "../views/authentication/AuthAccountLockedView";
import AuthCompleteView from "../views/authentication/AuthCompleteView";
import AuthCredentailsView from "../views/authentication/AuthCredentialsView";
import AuthRememberDeviceView from "../views/authentication/AuthRememberDeviceView";
import AuthSelectDeviceView from "../views/authentication/AuthSelectDeviceView";
import AuthTermsView from "../views/authentication/AuthTermsView";
import AuthTransferView from "../views/authentication/AuthTransferView";
import AuthVerifyInfoView from "../views/authentication/AuthVerifyInfoView";
import AuthVerifyPasscodeView from "../views/authentication/AuthVerifyPasscodeView";
import { PATHNAME_HOME_VIEW_UNAUTHENTICATED } from "./common";
import RecoverIdAccountBlockedView from "../views/recover-user-id/RecoverIdAccountBlockedView";
import RecoverIdCompleteView from "../views/recover-user-id/RecoverIdCompleteView";
import RecoverIdEmailView from "../views/recover-user-id/RecoverIdEmailView";
import RecoverIdVerifyInfoView from "../views/recover-user-id/RecoverIdVerifyInfoView";
import RecoverIdVerifyPasscodeView from "../views/recover-user-id/RecoverIdVerifyPasscodeView";
import RegistrationCompleteView from "../views/registration/RegistrationCompleteView";
import RegistrationCreateCredentialsView from "../views/registration/RegistrationCreateCredentialsView";
import RegistrationIdentificationView from "../views/registration/RegistrationIdentificationView";
import RegistrationRememberDeviceView from "../views/registration/RegistrationRememberDeviceView";
import RegistrationTermsView from "../views/registration/RegistrationTermsView";
import RegistrationVerifyEmailView from "../views/registration/RegistrationVerifyEmailView";
import RegistrationVerifyInfoView from "../views/registration/RegistrationVerifyInfoView";
import RegistrationVerifyPasscodeView from "../views/registration/RegistrationVerifyPasscodeView";
import ResetPasswordAccountBlockedView from "../views/reset-password/ResetPasswordAccountBlockedView";
import ResetPasswordCompleteView from "../views/reset-password/ResetPasswordCompleteView";
import ResetPasswordCreateView from "../views/reset-password/ResetPasswordCreateView";
import ResetPasswordSelectDeviceView from "../views/reset-password/ResetPasswordSelectDeviceView";
import ResetPasswordVerifyIdView from "../views/reset-password/ResetPasswordVerifyIdView";
import ResetPasswordVerifyInfoView from "../views/reset-password/ResetPasswordVerifyInfoView";
import ResetPasswordVerifyPasscodeView from "../views/reset-password/ResetPasswordVerifyPasscodeView";
import { currentClient } from "./clients";
import {
	IAM_FLOW_AUTHENTICATE,
	IAM_FLOW_RECOVER_ID,
	IAM_FLOW_REGISTER,
	IAM_FLOW_RESET_PASSWORD,
	IAM_STG_ACCOUNT_BLOCKED,
	IAM_STG_ACCOUNT_CONFLICT,
	IAM_STG_ACCOUNT_LOCKED,
	IAM_STG_COMPLETE,
	IAM_STG_CREATE,
	IAM_STG_CREDENTIALS,
	IAM_STG_ELIGIBILITY,
	IAM_STG_KEY_CREATE_CREDENTIALS,
	IAM_STG_KEY_CREATE_PASSWORD,
	IAM_STG_KEY_DEVICE,
	IAM_STG_KEY_EMAIL,
	IAM_STG_KEY_FINAL,
	IAM_STG_KEY_IDENTIFICATION,
	IAM_STG_KEY_NPPI,
	IAM_STG_KEY_OTP,
	IAM_STG_KEY_RECOVER_USER_ID,
	IAM_STG_KEY_SECOND_FACTOR_SELECTION,
	IAM_STG_KEY_TOS,
	IAM_STG_REMEMBER_DEVICE,
	IAM_STG_SELECT_DEVICE,
	IAM_STG_TERMS,
	IAM_STG_TRANSFER,
	IAM_STG_VERIFY_EMAIL,
	IAM_STG_VERIFY_INFO,
	IAM_STG_VERIFY_PASSCODE,
	withAuthNavigator
} from "../views/utils/iamUtils";

export const IAM_DURATION_INTERACTION = 900;
export const IAM_DURATION_PROCESS = 2100;
export const IAM_SESSION_TIMEOUT_ALERT_DURATION = 300;

export const IAM_STATE = [{
	flow: IAM_FLOW_AUTHENTICATE,
	flowRoot: true,
	path: `${IAM_FLOW_AUTHENTICATE}`,
	pathAlias: `content/consumer-authentication/${currentClient}/sign-in.html`,
	stage: IAM_STG_CREDENTIALS,
	title: "Sign In",
	view: withAuthNavigator(AuthCredentailsView, IAM_FLOW_AUTHENTICATE, IAM_STG_CREDENTIALS)
},
{
	flow: IAM_FLOW_AUTHENTICATE,
	path: `${IAM_FLOW_AUTHENTICATE}/${IAM_STG_ACCOUNT_BLOCKED}`,
	stage: IAM_STG_ACCOUNT_BLOCKED,
	title: "Account Blocked",
	view: withAuthNavigator(AuthAccountBlockedView, IAM_FLOW_AUTHENTICATE, IAM_STG_ACCOUNT_BLOCKED)
},
{
	flow: IAM_FLOW_AUTHENTICATE,
	path: `${IAM_FLOW_AUTHENTICATE}/${IAM_STG_ACCOUNT_CONFLICT}`,
	stage: IAM_STG_ACCOUNT_CONFLICT,
	title: "Account Conflict",
	view: withAuthNavigator(AuthAccountConflictView, IAM_FLOW_AUTHENTICATE, IAM_STG_ACCOUNT_CONFLICT)
},
{
	flow: IAM_FLOW_AUTHENTICATE,
	path: `${IAM_FLOW_AUTHENTICATE}/${IAM_STG_ACCOUNT_LOCKED}`,
	stage: IAM_STG_ACCOUNT_LOCKED,
	title: "Account Locked",
	view: withAuthNavigator(AuthAccountLockedView, IAM_FLOW_AUTHENTICATE, IAM_STG_ACCOUNT_LOCKED)
},
{
	flow: IAM_FLOW_AUTHENTICATE,
	path: `${IAM_FLOW_AUTHENTICATE}/${IAM_STG_VERIFY_INFO}`,
	stage: IAM_STG_KEY_NPPI,
	title: "Verify Information",
	view: withAuthNavigator(AuthVerifyInfoView, IAM_FLOW_AUTHENTICATE, IAM_STG_KEY_NPPI)
},
{
	flow: IAM_FLOW_AUTHENTICATE,
	path: `${IAM_FLOW_AUTHENTICATE}/${IAM_STG_SELECT_DEVICE}`,
	stage: IAM_STG_KEY_SECOND_FACTOR_SELECTION,
	title: "Select Device",
	view: withAuthNavigator(AuthSelectDeviceView, IAM_FLOW_AUTHENTICATE, IAM_STG_KEY_SECOND_FACTOR_SELECTION)
},
{
	flow: IAM_FLOW_AUTHENTICATE,
	path: `${IAM_FLOW_AUTHENTICATE}/${IAM_STG_VERIFY_PASSCODE}`,
	stage: IAM_STG_KEY_OTP,
	title: "Verify Code",
	view: withAuthNavigator(AuthVerifyPasscodeView, IAM_FLOW_AUTHENTICATE, IAM_STG_KEY_OTP)
},
{
	flow: IAM_FLOW_AUTHENTICATE,
	path: `${IAM_FLOW_AUTHENTICATE}/${IAM_STG_REMEMBER_DEVICE}`,
	stage: IAM_STG_KEY_DEVICE,
	title: "Device Not Recognized",
	view: withAuthNavigator(AuthRememberDeviceView, IAM_FLOW_AUTHENTICATE, IAM_STG_KEY_DEVICE)
},
{
	flow: IAM_FLOW_AUTHENTICATE,
	path: `${IAM_FLOW_AUTHENTICATE}/${IAM_STG_TERMS}`,
	stage: IAM_STG_KEY_TOS,
	title: "Online Services Terms of Use",
	view: withAuthNavigator(AuthTermsView, IAM_FLOW_AUTHENTICATE, IAM_STG_KEY_TOS)
},
{
	flow: IAM_FLOW_AUTHENTICATE,
	path: `${IAM_FLOW_AUTHENTICATE}/${IAM_STG_COMPLETE}`,
	stage: IAM_STG_KEY_FINAL,
	title: "Authentication Complete",
	view: withAuthNavigator(AuthCompleteView, IAM_FLOW_AUTHENTICATE, IAM_STG_KEY_FINAL)
},
{
	flow: IAM_FLOW_REGISTER,
	flowRoot: true,
	path: `${IAM_FLOW_REGISTER}`,
	pathAlias: `content/consumer-authentication/${currentClient}/create-account.html`,
	stage: IAM_STG_ELIGIBILITY,
	title: "Create An Account",
	view: withAuthNavigator(RegistrationIdentificationView, IAM_FLOW_REGISTER, IAM_STG_ELIGIBILITY)
},
{
	flow: IAM_FLOW_REGISTER,
	path: `${IAM_FLOW_REGISTER}/${IAM_STG_CREDENTIALS}`,
	stage: IAM_STG_KEY_CREATE_CREDENTIALS,
	title: "Create Credentials",
	view: withAuthNavigator(RegistrationCreateCredentialsView, IAM_FLOW_REGISTER, IAM_STG_KEY_CREATE_CREDENTIALS)
},
{
	flow: IAM_FLOW_REGISTER,
	path: `${IAM_FLOW_REGISTER}/${IAM_STG_VERIFY_INFO}`,
	stage: IAM_STG_KEY_NPPI,
	title: "Verify Information",
	view: withAuthNavigator(RegistrationVerifyInfoView, IAM_FLOW_REGISTER, IAM_STG_KEY_NPPI)
},
{
	flow: IAM_FLOW_REGISTER,
	path: `${IAM_FLOW_REGISTER}/${IAM_STG_VERIFY_EMAIL}`,
	stage: IAM_STG_KEY_EMAIL,
	title: "Verify Email",
	view: withAuthNavigator(RegistrationVerifyEmailView, IAM_FLOW_REGISTER, IAM_STG_KEY_EMAIL)
},
{
	flow: IAM_FLOW_REGISTER,
	path: `${IAM_FLOW_REGISTER}/${IAM_STG_VERIFY_PASSCODE}`,
	stage: IAM_STG_KEY_OTP,
	title: "Verify Code",
	view: withAuthNavigator(RegistrationVerifyPasscodeView, IAM_FLOW_REGISTER, IAM_STG_KEY_OTP)
},
{
	flow: IAM_FLOW_REGISTER,
	path: `${IAM_FLOW_REGISTER}/${IAM_STG_REMEMBER_DEVICE}`,
	stage: IAM_STG_KEY_DEVICE,
	title: "Device Not Recognized",
	view: withAuthNavigator(RegistrationRememberDeviceView, IAM_FLOW_REGISTER, IAM_STG_KEY_DEVICE)
},
{
	flow: IAM_FLOW_REGISTER,
	path: `${IAM_FLOW_REGISTER}/${IAM_STG_TERMS}`,
	stage: IAM_STG_KEY_TOS,
	title: "Online Services Terms of Use",
	view: withAuthNavigator(RegistrationTermsView, IAM_FLOW_REGISTER, IAM_STG_KEY_TOS)
},
{
	flow: IAM_FLOW_REGISTER,
	path: `${IAM_FLOW_REGISTER}/${IAM_STG_COMPLETE}`,
	stage: IAM_STG_KEY_FINAL,
	title: "Registration Complete",
	view: withAuthNavigator(RegistrationCompleteView, IAM_FLOW_REGISTER, IAM_STG_KEY_FINAL)
},
{
	flow: IAM_FLOW_RECOVER_ID,
	flowRoot: true,
	path: `${IAM_FLOW_RECOVER_ID}`,
	pathAlias: `content/consumer-authentication/${currentClient}/forgot-username.html`,
	stage: IAM_STG_VERIFY_EMAIL,
	title: "Forgot Username",
	view: withAuthNavigator(RecoverIdEmailView, IAM_FLOW_RECOVER_ID, IAM_STG_VERIFY_EMAIL)
},
{
	flow: IAM_FLOW_RECOVER_ID,
	path: `${IAM_FLOW_RECOVER_ID}/${IAM_STG_ACCOUNT_BLOCKED}`,
	stage: IAM_STG_ACCOUNT_BLOCKED,
	title: "Account Blocked",
	view: withAuthNavigator(RecoverIdAccountBlockedView, IAM_FLOW_RECOVER_ID, IAM_STG_ACCOUNT_BLOCKED)
},
{
	flow: IAM_FLOW_RECOVER_ID,
	path: `${IAM_FLOW_RECOVER_ID}/${IAM_STG_VERIFY_PASSCODE}`,
	stage: IAM_STG_KEY_OTP,
	title: "Verify Code",
	view: withAuthNavigator(RecoverIdVerifyPasscodeView, IAM_FLOW_RECOVER_ID, IAM_STG_KEY_OTP)
},
{
	flow: IAM_FLOW_RECOVER_ID,
	path: `${IAM_FLOW_RECOVER_ID}/${IAM_STG_VERIFY_INFO}`,
	stage: IAM_STG_KEY_NPPI,
	title: "Account Information",
	view: withAuthNavigator(RecoverIdVerifyInfoView, IAM_FLOW_RECOVER_ID, IAM_STG_KEY_NPPI)
},
{
	flow: IAM_FLOW_RECOVER_ID,
	path: `${IAM_FLOW_RECOVER_ID}/${IAM_STG_COMPLETE}`,
	stage: IAM_STG_KEY_RECOVER_USER_ID,
	title: "Username Recovery Complete",
	view: withAuthNavigator(RecoverIdCompleteView, IAM_FLOW_RECOVER_ID, IAM_STG_KEY_RECOVER_USER_ID)
},
{
	flow: IAM_FLOW_RESET_PASSWORD,
	flowRoot: true,
	path: `${IAM_FLOW_RESET_PASSWORD}`,
	pathAlias: `content/consumer-authentication/${currentClient}/forgot-password.html`,
	stage: IAM_STG_KEY_IDENTIFICATION,
	title: "Forgot Password",
	view: withAuthNavigator(ResetPasswordVerifyIdView, IAM_FLOW_RESET_PASSWORD, IAM_STG_KEY_IDENTIFICATION)
},
{
	flow: IAM_FLOW_RESET_PASSWORD,
	path: `${IAM_FLOW_RESET_PASSWORD}/${IAM_STG_ACCOUNT_BLOCKED}`,
	stage: IAM_STG_ACCOUNT_BLOCKED,
	title: "Account Blocked",
	view: withAuthNavigator(ResetPasswordAccountBlockedView, IAM_FLOW_RESET_PASSWORD, IAM_STG_ACCOUNT_BLOCKED)
},
{
	flow: IAM_FLOW_RESET_PASSWORD,
	path: `${IAM_FLOW_RESET_PASSWORD}/${IAM_STG_SELECT_DEVICE}`,
	stage: IAM_STG_KEY_SECOND_FACTOR_SELECTION,
	title: "Select Device",
	view: withAuthNavigator(ResetPasswordSelectDeviceView, IAM_FLOW_RESET_PASSWORD, IAM_STG_KEY_SECOND_FACTOR_SELECTION)
},
{
	flow: IAM_FLOW_RESET_PASSWORD,
	path: `${IAM_FLOW_RESET_PASSWORD}/${IAM_STG_VERIFY_PASSCODE}`,
	stage: IAM_STG_KEY_OTP,
	title: "Verify Code",
	view: withAuthNavigator(ResetPasswordVerifyPasscodeView, IAM_FLOW_RESET_PASSWORD, IAM_STG_KEY_OTP)
},
{
	flow: IAM_FLOW_RESET_PASSWORD,
	path: `${IAM_FLOW_RESET_PASSWORD}/${IAM_STG_VERIFY_INFO}`,
	stage: IAM_STG_KEY_NPPI,
	title: "Verify Information",
	view: withAuthNavigator(ResetPasswordVerifyInfoView, IAM_FLOW_RESET_PASSWORD, IAM_STG_KEY_NPPI)
},
{
	flow: IAM_FLOW_RESET_PASSWORD,
	path: `${IAM_FLOW_RESET_PASSWORD}/${IAM_STG_CREATE}`,
	stage: IAM_STG_KEY_CREATE_PASSWORD,
	title: "Create Password",
	view: withAuthNavigator(ResetPasswordCreateView, IAM_FLOW_RESET_PASSWORD, IAM_STG_KEY_CREATE_PASSWORD)
},
{
	flow: IAM_FLOW_RESET_PASSWORD,
	path: `${IAM_FLOW_RESET_PASSWORD}/${IAM_STG_COMPLETE}`,
	stage: IAM_STG_KEY_FINAL,
	title: "Password Recovery Complete",
	view: withAuthNavigator(ResetPasswordCompleteView, IAM_FLOW_RESET_PASSWORD, IAM_STG_KEY_FINAL)
},
{
	flow: IAM_FLOW_AUTHENTICATE,
	path: "transfer",
	stage: IAM_STG_TRANSFER,
	title: "Transferring To Your Account...",
	view: AuthTransferView
}];

export const getLoginLocation = () => {
	return { pathname: PATHNAME_HOME_VIEW_UNAUTHENTICATED };
};

export const getLogoutLocation = isExpiry => {
	return { pathname: PATHNAME_HOME_VIEW_UNAUTHENTICATED, search: { [isExpiry ? "expired" : "logout"]: true } };
};