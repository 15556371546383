import * as profileActions from "../../../store/actions/profile";
import * as profileEvents from "../../../store/events/profile";
import Box from "@mui/material/Box";
import Button from "../../Button/Button";
import CircularProgress from "@mui/material/CircularProgress";
import DOMPurify from "dompurify";
import { Form } from "@pheaa/channels-component-library";
import ProfileErrorMessages from "../../ErrorMessages/ProfileErrorMessages";
import Typography from "@mui/material/Typography";
import { connect } from "react-redux";
import useNavigateQSA from "../../../utils/hooks/useNavigateQSA";
import React, { useEffect, useState } from "react";

export const AGREEMENT_VERSION = "002.002";

const TermsAndConditions = props => {

	let returnElem = null;

	const {
		isUpdatingPaperlessPreferences,
		setData,
		updatePaperlessPreferences
	} = props;

	const [isError, setIsError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [termsAndConditions, setTermsAndConditions] = useState(null);

	const navigate = useNavigateQSA();

	const handleSubmit = () => {
		setData({ paperlessPreferencesPayload: { agreementVersion: AGREEMENT_VERSION } });

		updatePaperlessPreferences(null, {
			successCallback: () => {
				navigate("/profile/paperless/updated");
				setData({ paperlessPreferencesPayload: {} });
			}
		});
	};

	const sendRequest = () => {
		setIsLoading(true);
		fetch(process.env.REACT_APP_PROFILE_PAPERLESS_PREFERENCES_TERMS_AND_CONDITIONS_ENDPOINT).then(async response => {
			const body = await response.text();
			setIsError(false);
			setTermsAndConditions(body);
		}).catch(error => {
			setIsError(true);
		}).finally(() => {
			setIsLoading(false);
		});
	};

	useEffect(() => {
		if (!isLoading) {
			sendRequest();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (isError) {
		returnElem = (
			<div className="cmp-terms-and-conditions__content">
				<Typography
					gutterBottom={true}
					paragraph={true}
					textAlign="center"
				>
					An error occurred while loading the Terms and Conditions.
				</Typography>
				<Typography
					paragraph={true}
					textAlign="center"
				>
					<Button
						disabled={isLoading}
						onClick={sendRequest}
						variant="outlined"
					>
						Try Again
					</Button>
				</Typography>
			</div>
		);
	} else if (isLoading) {
		returnElem = (
			<Box sx={{ display: "flex", justifyContent: "center" }}>
				<CircularProgress />
			</Box>
		);
	} else {
		returnElem = (
			<React.Fragment>
				<Form
					id="defaultForm"
					onSubmit={handleSubmit}
				>
					<ProfileErrorMessages />
					<div
						className="cmp-terms-and-conditions__content"
						dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(termsAndConditions) }}
					/>
					<Typography
						gutterBottom={true}
						paragraph={true}
						textAlign="center"
						variant="h6"
					>
						I have reviewed, understand, and agree to the Online Services Terms of Use.
					</Typography>
					<Box
						mb={1.75}
						mt={5.625}
					>
						<Button
							disabled={isUpdatingPaperlessPreferences}
							type="submit"
							variant="contained"
						>
							Agree & Submit
						</Button>
					</Box>
				</Form>
			</React.Fragment>
		);
	}

	return (
		<section className="cmp-terms-and-conditions">
			{returnElem}
		</section>
	);
};

const mapStateToProps = state => {
	return {
		isUpdatingPaperlessPreferences: state.profile.isUpdatingPaperlessPreferences
	};
};

const mapDispatchToProps = dispatch => {
	return {
		setData: data => dispatch(profileActions.setData(data)),
		updatePaperlessPreferences: (data, config) => dispatch(profileEvents.updatePaperlessPreferences(data, config))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(TermsAndConditions);