import * as authActions from "../../store/actions/auth";
import * as profileEvents from "../../store/events/profile";
import { connect } from "react-redux";
import { useEffect } from "react";

const RequireProfile = props => {

	const {
		children,
		getFullProfile,
		isFetching,
		isFetchFailed,
		setData
	} = props;

	useEffect(() => {
		if (!isFetching && !isFetchFailed) {
			getFullProfile();
		}
	}, [
		getFullProfile,
		isFetching,
		isFetchFailed,
		setData
	]);

	return children;
};

const mapStateToProps = state => {
	return {
		isFetching: state.profile.isFetching,
		isFetchFailed: state.profile.isFetchFailed
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getFullProfile: data => dispatch(profileEvents.getFullProfile(data)),
		setData: data => dispatch(authActions.setData(data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(RequireProfile);