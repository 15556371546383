import { createReducer } from "@reduxjs/toolkit";
import merge from "deepmerge";
import { currentClient, currentClientId, currentClientName } from "../../config/clients";
import { resetData, setData, taskComplete } from "../actions/common";

export const arrayMergeOverwrite = (destinationArray, sourceArray, options) => sourceArray;

const initialState = {
	appConfig: {
		logging: { enabled: false },
		maintenance: { enabled: false, schedule: [] }
	},
	client: currentClient,
	clientId: currentClientId,
	clientName: currentClientName,
	isAppInitialized: false,
	isAppLoading: true,
	maintenance: false,
	maintenanceData: [],
	returnKey: null,
	taskManager: {
		completeTasks: []
	}
};

const commonReducer = createReducer(initialState, (builder) => {
	builder
		.addCase(resetData, (state, action) => {
			return {
				...initialState,
				...action.payload,
				isAppInitialized: state.isAppInitialized,
				returnKey: state.returnKey
			};
		})
		.addCase(setData, (state, action) => {
			return merge(state, action.payload, { arrayMerge: arrayMergeOverwrite });
		})
		.addCase(taskComplete, (state, action) => {
			return {
				...state,
				taskManager: {
					...initialState.taskManager,
					completeTasks: state.taskManager.completeTasks.concat(action.payload)
				}
			};
		});
});

export default commonReducer;