import { TOKEN_HOSTNAME_ENV } from "./tokens";

export const DEV = "dev";
export const QA = "qa";
export const PROD = "prod";

export const getIsLocalEnv = ({ hostname } = window.location) => {
	return hostname === "localhost";
};

export const getEnvFromHostname = () => {
	let { hostname } = window.location;
	let env = PROD;

	if (
		hostname === "d6vh9v6sqyohm.cloudfront.net" ||
		hostname === "access.dev.aessuccess.org" ||
		hostname === "access.dev.elfi.aessuccess.org" ||
		hostname === "localhost"
	) {
		env = DEV;
	} else if (
		hostname === "d38rf44s9ot7vt.cloudfront.net" ||
		hostname === "access.qa.aessuccess.org" ||
		hostname === "access.qa.elfi.aessuccess.org"
	) {
		env = QA;
	}

	return env.toLowerCase();
};

export const replaceEnvTokenInUrl = url => {
	// Set replacement string to empty string;
	let envReplacement = ".";
	// If non-prod environment;
	if (currentEnv !== PROD) {
		// Set replacement string to current env;
		envReplacement = `.${currentEnv}.`;
	}
	// Return URL, replacing token with replacement string;
	return url.replace(TOKEN_HOSTNAME_ENV, envReplacement);
};

export const currentEnv = getEnvFromHostname();
export const isLocalEnv = getIsLocalEnv();