import { arrayMergeOverwrite } from "./common";
import { createReducer } from "@reduxjs/toolkit";
import merge from "deepmerge";
import { resetData, setData } from "../actions/auth";

const initialState = {
	apiInteractionFirst: null,
	apiInteractionLatest: null,
	authAccessExpiresAt: null,
	authAccountBlocked: false,
	authExpiryInteraction: null,
	authExpiryProcess: null,
	authLockExpiry: null,
	contactOptions: null,
	currentFlow: null,
	currentStage: null,
	data: {
		secondFactorDevice: null,
		userBirthDate: null,
		userEmail: null,
		userId: null,
		userMaxCreateCredentialsAttempts: null,
		userMaxInfoVerificationAttempts: null,
		userMaxResetPasswordAttempts: null
	},
	interfaceErrors: [],
	isAuthenticated: false,
	isPendingFlowRestart: false,
	isPendingIAM: false,
	isPendingSessionVerification: false,
	isPendingSessionEnd: false,
	isPendingSessionExtension: false,
	legacyTransferDataPending: false,
	legacyTransferFailed: false,
	legacyTransferPending: false,
	legacyTransferUrl: null,
	lastLoginOn: null,
	responseDataIAM: null,
	systemErrors: [],
	tokenLastVerifiedAt: null
};

const authReducer = createReducer(initialState, (builder) => {
	builder
		.addCase(resetData, (state, action) => {
			return { ...initialState, ...action.payload };
		})
		.addCase(setData, (state, action) => {
			return merge(state, action.payload, { arrayMerge: arrayMergeOverwrite });
		});
});

export default authReducer;