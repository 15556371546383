import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Logo from "./Logo.js";
import React from "react";
import { useTheme } from "@mui/material";
import { CLIENT_ELF, currentClient } from "../../config/clients.js";

const Header = props => {

	const theme = useTheme();
	const backgroundColor = (currentClient === CLIENT_ELF ? "#FFF" : theme.palette.primary.main);

	return (
		<Box
			className="cmp-header"
			component="header"
			data-client={currentClient}
			id="header"
			sx={{
				backgroundColor,
				boxShadow: 3
			}}
		>
			<Container
				className="cmp-header__inner"
				maxWidth="lg"
			>
				<Logo />
			</Container>
		</Box>
	);
};

export default Header;