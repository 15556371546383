import React from "react";

const TaskComplete = props => {

	const { children } = props;

	return (
		<div className="cmp-task-complete">
			{children}
		</div>
	);
};

export default TaskComplete;