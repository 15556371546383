import Box from "@mui/material/Box";
import Button from "../../components/Button/Button";
import { Form } from "@pheaa/channels-component-library";
import Typography from "@mui/material/Typography";
import { connect } from "react-redux";
import useNavigateQSA from "../../utils/hooks/useNavigateQSA";
import { CLIENT_AES, CLIENT_ELF, currentClient } from "../../config/clients";
import {
	IAM_FLOW_AUTHENTICATE,
	getFlowRootStage,
	getPathForFlowAndStage
} from "../../views/utils/iamUtils";
import React, { useMemo } from "react";

const getAccountBlockedConfig = () => ({
	content: {
		contactPhone: {
			[CLIENT_AES]: "1-800-233-0557",
			[CLIENT_ELF]: "1-866-763-6349"
		}
	},
});

const AccountBlocked = props => {

	const {
		isAppInitialized
	} = props;

	const navigate = useNavigateQSA();
	const config = useMemo(() => getAccountBlockedConfig(isAppInitialized), [isAppInitialized]);
	const contactPhone = config.content.contactPhone[currentClient];

	const handleSubmit = () => {
		navigate(`/${getPathForFlowAndStage(IAM_FLOW_AUTHENTICATE, getFlowRootStage(IAM_FLOW_AUTHENTICATE))}`, { replace: true });
	};

	return (
		<Form
			id="defaultFormNarrow"
			onSubmit={handleSubmit}
		>
			<Box
				component="img"
				mb={2.8125}
				mt={1.75}
				mx="auto"
				sx={{
					display: "block",
					height: 150,
					width: 150
				}}
				alt="Alert Icon"
				src={`${process.env.PUBLIC_URL}/assets/general/alert-gray.svg`}
			/>
			<Typography
				align="center"
				gutterBottom={true}
				paragraph={true}
				variant="h5"
			>
				Your account is blocked.
			</Typography>
			<Typography
				align="center"
				gutterBottom={true}
				paragraph={true}
			>
				To unblock your account, please contact us at <span className="no-wrap">{contactPhone}</span>.
			</Typography>
			<Box
				mb={1.75}
				mt={5.625}
			>
				<Button
					type="submit"
					variant="contained"
				>
					Return to Sign In
				</Button>
			</Box>
		</Form>
	);
};

const mapStateToProps = state => {
	return {
		// currentFlow: state.auth.currentFlow,
		isAppInitialized: state.common.isAppInitialized
	};
};

export default connect(mapStateToProps)(AccountBlocked);