import Alert from "@mui/material/Alert";
import React from "react";

export const ERROR_MESSAGE_TYPE_DEFAULT = "error";
export const ERROR_MESSAGE_TYPE_CONFIRM = "confirm";

const ErrorMessages = props => {

	const {
		errorMessages = []
	} = props;

	return (
		<ul className="cmp-error-messages">
			{
				errorMessages.map((errorMsg, i) => {
					return (
						<li
							className="cmp-error-messages__item"
							data-id={errorMsg.id}
							data-type={errorMsg.type}
							key={i}
						>
							<Alert severity={errorMsg.type === ERROR_MESSAGE_TYPE_CONFIRM ? "success" : "error"}>
								{errorMsg.message}
							</Alert>
						</li>
					);
				})
			}
		</ul>
	);
};

export default ErrorMessages;