import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { connect } from "react-redux";
import { currentDate } from "../../config/common";
import { isArrayOfLength } from "@pheaa/channels-component-library";
import { CLIENT_AES, CLIENT_ELF, currentClient } from "../../config/clients";
import React, { useMemo } from "react";
import { styled, useTheme } from "@mui/material/styles";

const ResourceLink = styled(Box)(({ theme }) => ({
	color: theme.palette.primary.contrastText
}));

const getFooterConfig = () => ({
	content: {
		copyright: {
			[CLIENT_AES]: <p>Copyright &copy; {currentDate.getYear()} Pennsylvania Higher Education Assistance Agency (<ResourceLink component="a" href="http://www.nmlsconsumeraccess.org/" rel="noreferrer" target="_blank">NMLS# 1619466 Consumer Access</ResourceLink>). All Rights Reserved. PHEAA conducts its student loan servicing operations commercially as American Education Services (AES).</p>,
			[CLIENT_ELF]: <p>Copyright &copy; {currentDate.getYear()} Pennsylvania Higher Education Assistance Agency. All Rights Reserved. Designated trademarks and brands are the property of their respective owners.</p>
		},
		mobileAppLabel: {
			[CLIENT_AES]: "Go Mobile Today!"
		},
		mobileApp: {
			[CLIENT_AES]: [{
				label: "Download on the App Store",
				link: "https://www.aessuccess.org/Apple",
				imageSrc: `${process.env.PUBLIC_URL}/assets/general/icon-app-store.svg`
			}, {
				label: "Get It On Google Play",
				link: "https://www.aessuccess.org/Android",
				imageSrc: `${process.env.PUBLIC_URL}/assets/general/icon-play-store.svg`
			}]
		},
		resources: {
			[CLIENT_AES]: [{
				label: "Contact Us",
				link: "https://login.aessuccess.org/contactUs/index.cfm?contextId=auth&bid=PA"
			}, {
				label: "About Us",
				link: "https://www.aessuccess.org/about"
			}, {
				label: "Privacy Policy",
				link: "https://www.pheaa.org/about/privacy-policy.shtml"
			}, {
				label: "Office of Consumer Advocacy",
				link: "https://www.pheaa.org/about/contact-us/office-consumer-advocacy.shtml"
			}],
			[CLIENT_ELF]: [{
				label: "Contact Us",
				link: "https://login.aessuccess.org/contactUs/index.cfm?contextId=auth&bid=PA-ES"
			}, {
				label: "About ELFI",
				link: "https://www.elfi.com/about/"
			}, {
				label: "About AES",
				link: "https://www.aessuccess.org/about"
			}]
		}
	}
});

const Footer = props => {

	const { isAppInitialized } = props;

	const theme = useTheme();
	const config = useMemo(() => getFooterConfig(isAppInitialized), [isAppInitialized]);
	const hasMobileAppConfig = isArrayOfLength(config.content.mobileApp[currentClient]);

	return (
		<Box
			className="cmp-footer"
			component="footer"
			id="footer"
			mt="auto"
			pt={10}
			py={3}
			sx={{
				backgroundColor: theme.palette.footer.main,
				color: theme.palette.primary.contrastText,
				display: "flex"
			}}
		>
			<Box className="cmp-footer__inner" sx={{ margin: "0 auto" }}>
				<Grid container maxWidth="lg" spacing={{ xs: 0, lg: 2 }}>
					<Grid item xs={12} lg={hasMobileAppConfig ? 9 : 12}>
						<Box px={theme.spacing(2)} py={theme.spacing(2)}>
							<Box
								className="cmp-footer__resource-list"
								component="ul"
								sx={{ display: { xs: "block", lg: "flex" } }}
							>
								{
									config.content.resources[currentClient].map((resource, key) => {
										return (
											<Box component="li" key={key} py={theme.spacing(0.625)} sx={{ padding: { lg: `0 ${theme.spacing(2)} 0 0` } }}>
												<ResourceLink component="a" href={resource.link} rel="noreferrer" target="_blank">{resource.label}</ResourceLink>
											</Box>
										);
									})
								}
							</Box>
							<Box mt={4} sx={{ lg: { margin: `${theme.spacing(0)} ${theme.spacing(2)}` } }}>
								{config.content.copyright[currentClient]}
							</Box>
						</Box>
					</Grid>
					{
						hasMobileAppConfig && (
							<Grid item xs={12} lg={3}>
								<Box px={theme.spacing(2)} py={theme.spacing(2)}>
									<Typography paragraph={true}>
										{config.content.mobileAppLabel[currentClient]}
									</Typography>
									<Box
										className="cmp-footer__mobile-app-list"
										component="ul"
									>
										{
											config.content.mobileApp[currentClient].map((mobileApp, key) => {
												return (
													<Box className="cmp-footer__mobile-app-list-item" component="li" key={key} mt={1.25}>
														<a href={mobileApp.link} rel="noreferrer" target="_blank">
															<Box alt={mobileApp.label} component="img" src={mobileApp.imageSrc} sx={{ display: "block", maxWidth: "100%" }} />
														</a>
													</Box>
												);
											})
										}
									</Box>
								</Box>
							</Grid>
						)
					}
				</Grid>
			</Box>
		</Box>
	);
};

const mapStateToProps = state => {
	return {
		isAppInitialized: state.common.isAppInitialized
	};
};

export default connect(mapStateToProps)(Footer);