import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import React from "react";

const defaultStyle = {
	bgcolor: "background.paper",
	borderRadius: "10px",
	boxShadow: "0 0 4px rgba(0, 0, 0, 0.25)",
	left: "50%",
	p: 4,
	position: "absolute",
	top: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
};

const BasicModal = props => {

	const {
		ariaDescribedBy,
		ariaLabelledBy,
		children,
		onClose,
		open,
		style = {}
	} = props;

	return (
		<Modal
			aria-describedby={ariaDescribedBy}
			aria-labelledby={ariaLabelledBy}
			className="cmp-modal"
			onClose={onClose}
			open={open}
		>
			<Box sx={{ ...defaultStyle, ...style }}>
				{children}
			</Box>
		</Modal>
	);
};

export default BasicModal;