import DefaultTemplate from "../templates/DefaultTemplate";
import React from "react";
import TaskHeader from "../../components/Task/TaskHeader";
import VerifyPasscode from "../../components/IAM/VerifyPasscode";
import { clearErrors } from "../../store/events/auth";
import { connect } from "react-redux";
import {
	IAM_FLOW_AUTHENTICATE,
	IAM_FLOW_REGISTER,
	IAM_STG_KEY_EMAIL,
	getFlowRootStage,
	getPathForFlowAndStage,
	hasAuthId,
	withAuthCondition
} from "../utils/iamUtils";

const RegistrationVerifyPasscodeView = props => {

	const {
		isPasscodeMaxDeliveryAttempts,
		isPendingFlowRestart,
		isPendingIAM
	} = props;

	return (
		<DefaultTemplate isTask={true}>
			<TaskHeader
				backCallback={clearErrors}
				backDisabled={isPendingIAM || isPendingFlowRestart || isPasscodeMaxDeliveryAttempts}
				backDestination={`/${getPathForFlowAndStage(IAM_FLOW_REGISTER, IAM_STG_KEY_EMAIL)}`}
				closeResetsAuthFlow={true}
				closeTaskDestination={`/${getPathForFlowAndStage(IAM_FLOW_AUTHENTICATE, getFlowRootStage(IAM_FLOW_AUTHENTICATE))}`}
				title="Verification Code"
			/>
			<VerifyPasscode />
		</DefaultTemplate>
	);
};

const mapStateToProps = state => {
	return {
		isPasscodeMaxDeliveryAttempts: state.auth.data.isPasscodeMaxDeliveryAttempts,
		isPendingFlowRestart: state.auth.isPendingFlowRestart,
		isPendingIAM: state.auth.isPendingIAM
	};
};

export default withAuthCondition(connect(mapStateToProps)(RegistrationVerifyPasscodeView), hasAuthId, IAM_FLOW_REGISTER);