import { Link } from "react-router-dom";
import React from "react";

const SkipLinks = props => {

	let linksList = [];

	const { isTask } = props;

	if (!isTask) {
		linksList = [{
			id: "header",
			label: "Skip to Header"
		}, {
			id: "main",
			label: "Skip to Main Content"
		}, {
			id: "footer",
			label: "Skip to Footer"
		}];
	}

	return (
		<div className="a11y-skip-links">
			{
				linksList.map((link, key) => {
					return (
						<Link
							className="a11y-skip-links__item"
							to={`#${link.id}`}
							key={key}
						>
							{link.label}
						</Link>
					);
				})
			}
		</div>
	);
};

export default SkipLinks;