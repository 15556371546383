import commonApiRequest from "../../utils/commonApiRequest";
import { setData } from "../actions/profile";
import {
	API_NSP_OPERATION_PROFILE,
	API_NSP_OPERATION_PROFILE_ECORR,
	API_NSP_OPERATION_PROFILE_EMAIL_CONTACT,
	API_NSP_PROFILE,
	getApiUrl
} from "../../config/api";
import {
	ERROR_MESSAGE_TYPE_CONFIRM,
	ERROR_MESSAGE_TYPE_DEFAULT
} from "../../components/ErrorMessages/ErrorMessages";
import {
	INTERFACE_ERROR_CEU,
	SYSTEM_ERROR_CNX,
	SYSTEM_ERROR_INV
} from "../../config/common";
import {
	REQUEST_METHOD,
	isFunction,
	isNumber,
	isObject,
	isStringOfLength
} from "@pheaa/channels-component-library";

export const getFullProfile = (allowRedundancy = true) => (dispatch, getState) => {

	const { profile } = getState();
	const isProfileAvailable = isObject(profile) && isObject(profile.data) && isStringOfLength(profile.data.id);

	if (!isProfileAvailable) {
		const url = getApiUrl(API_NSP_PROFILE, API_NSP_OPERATION_PROFILE);

		commonApiRequest(url, {
			beforeCallback: () => {
				dispatch(setData({
					isFetchFailed: false,
					isFetching: true
				}));
			},
			errorCallback: ({ body, headers, status }) => {

				if (status !== 401 && allowRedundancy) {
					// Recursively invoke method once more if allowed;
					dispatch(getFullProfile(false));
				} else {
					dispatch(setData({
						isFetchFailed: true,
						isFetching: false
					}));
				}
			},
			failCallback: error => {

				if (allowRedundancy) {
					// Recursively invoke method once more if allowed;
					dispatch(getFullProfile(false));
				} else {
					dispatch(setData({
						isFetchFailed: true,
						isFetching: false
					}));
				}
			},
			method: REQUEST_METHOD.GET,
			successCallback: ({ body }) => {
				dispatch(setData({
					data: body,
					isFetchFailed: false,
					isFetching: false
				}));
			}
		});
	}
};

export const updatePaperlessPreferences = (payload, { alwaysCallback, beforeCallback, errorCallback, failCallback, successCallback } = {}) => (dispatch, getState) => {
	const url = getApiUrl(API_NSP_PROFILE, API_NSP_OPERATION_PROFILE_ECORR);
	const { paperlessPreferencesPayload } = getState().profile;

	commonApiRequest(url, {
		alwaysCallback: () => {
			isFunction(alwaysCallback) && alwaysCallback();
		},
		beforeCallback: () => {
			dispatch(setData({
				interfaceErrors: [],
				isUpdatingPaperlessPreferences: true,
				systemErrors: []
			}));

			isFunction(beforeCallback) && beforeCallback();
		},
		data: isObject(payload) ? payload : paperlessPreferencesPayload,
		errorCallback: ({ body, headers, status }) => {
			if (isNumber(status)) {
				dispatch(setData({
					isUpdatingPaperlessPreferences: false,
					systemErrors: [{ code: status, type: ERROR_MESSAGE_TYPE_DEFAULT }]
				}));
			} else {
				dispatch(setData({
					isUpdatingPaperlessPreferences: false,
					systemErrors: [{ code: SYSTEM_ERROR_INV, type: ERROR_MESSAGE_TYPE_DEFAULT }]
				}));
				throw new Error("Expected HTTP Error Response Code Not Received.");
			}

			isFunction(errorCallback) && errorCallback({ body, headers, status });
		},
		failCallback: error => {
			if (isObject(error) && isStringOfLength(error.message) && error.message.toLowerCase() === "failed to fetch") {
				dispatch(setData({
					isUpdatingPaperlessPreferences: false,
					systemErrors: [{ code: SYSTEM_ERROR_CNX, type: ERROR_MESSAGE_TYPE_DEFAULT }]
				}));
			} else {
				dispatch(setData({
					isUpdatingPaperlessPreferences: false,
					systemErrors: [{ code: SYSTEM_ERROR_INV, type: ERROR_MESSAGE_TYPE_DEFAULT }]
				}));
			}

			isFunction(failCallback) && failCallback(error);
		},
		method: REQUEST_METHOD.PATCH,
		successCallback: ({ body, headers, status }) => {
			dispatch(setData({
				data: { ecorrespondence: body },
				isUpdatingPaperlessPreferences: false
			}));

			isFunction(successCallback) && successCallback({ body, headers, status });
		}
	});
};

export const updateContactEmailAddress = (formData, { alwaysCallback, beforeCallback, errorCallback, failCallback, successCallback } = {}) => (dispatch, getState) => {
	const url = getApiUrl(API_NSP_PROFILE, API_NSP_OPERATION_PROFILE_EMAIL_CONTACT);

	commonApiRequest(url, {
		alwaysCallback: () => {
			isFunction(alwaysCallback) && alwaysCallback();
		},
		beforeCallback: () => {
			dispatch(setData({
				interfaceErrors: [],
				isUpdatingContactEmail: true,
				systemErrors: []
			}));

			isFunction(beforeCallback) && beforeCallback();
		},
		errorCallback: ({ body, headers, status }) => {
			if (isNumber(status)) {
				dispatch(setData({
					isUpdatingContactEmail: false,
					systemErrors: [{ code: status, type: ERROR_MESSAGE_TYPE_DEFAULT }]
				}));
			} else {
				dispatch(setData({
					isUpdatingContactEmail: false,
					systemErrors: [{ code: SYSTEM_ERROR_INV, type: ERROR_MESSAGE_TYPE_DEFAULT }]
				}));
				throw new Error("Expected HTTP Error Response Code Not Received.");
			}

			isFunction(errorCallback) && errorCallback({ body, headers, status });
		},
		data: { email: formData.email },
		failCallback: error => {
			if (isObject(error) && isStringOfLength(error.message) && error.message.toLowerCase() === "failed to fetch") {
				dispatch(setData({
					isUpdatingContactEmail: false,
					systemErrors: [{ code: SYSTEM_ERROR_CNX, type: ERROR_MESSAGE_TYPE_DEFAULT }]
				}));
			} else {
				dispatch(setData({
					isUpdatingContactEmail: false,
					systemErrors: [{ code: SYSTEM_ERROR_INV, type: ERROR_MESSAGE_TYPE_DEFAULT }]
				}));
			}

			isFunction(failCallback) && failCallback(error);
		},
		method: REQUEST_METHOD.PUT,
		successCallback: ({ body, headers, status }) => {
			const { data } = getState().profile;
			const contactEmailIndex = data.emails.findIndex(emailObj => emailObj.id === "contact");
			let emails = [...data.emails];

			if (contactEmailIndex >= 0) {
				emails[contactEmailIndex] = body;
			}

			dispatch(setData({
				data: { emails },
				interfaceErrors: [{ code: INTERFACE_ERROR_CEU, type: ERROR_MESSAGE_TYPE_CONFIRM }],
				isUpdatingContactEmail: false,
			}));

			isFunction(successCallback) && successCallback({ body, headers, status });
		}
	});
};