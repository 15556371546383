import * as authActions from "../../store/actions/auth";
import BasicModal from "../Modal/BasicModal";
import Box from "@mui/material/Box";
import Button from "../../components/Button/Button";
import { ERROR_MESSAGE_TYPE_DEFAULT } from "../ErrorMessages/ErrorMessages";
import { PS_EVENT_AUTH_INTERACTION_RESET_DISMISS } from "../../config/common";
import Typography from "@mui/material/Typography";
import { connect } from "react-redux";
import { ps } from "../Application/Application";
import useInterval from "../../utils/hooks/useInterval";
import useNavigateQSA from "../../utils/hooks/useNavigateQSA";
import {
	IAM_FAILURE_REASONS_CODE_PTO,
	IAM_FLOW_AUTHENTICATE,
	getFlowRootStage,
	getPathForFlowAndStage,
	isAuthView
} from "../../views/utils/iamUtils";
import React, { useEffect, useState } from "react";
import { format, isNumber } from "@pheaa/channels-component-library";

const TIMEOUT_INTERACTION = "interaction";
const TIMEOUT_PROCESS = "process";
const INTERACTION_PROCESS_TIMEOUT_ALERT_DURATION = 300;
const INTERACTION_PROCESS_TIMEOUT_OFFSET = 0;

const InteractionProcessTimeout = props => {

	const {
		authExpiryInteraction,
		authExpiryProcess,
		resetData
	} = props;

	const [timeoutData, setTimeoutData] = useState({
		dismissed: false,
		displayModal: false,
		secondsToExpiry: null,
		timedOut: false,
		type: TIMEOUT_INTERACTION
	});

	const navigate = useNavigateQSA();

	useEffect(() => {
		const subscriptionId = ps.subscribe(PS_EVENT_AUTH_INTERACTION_RESET_DISMISS, () => {
			setTimeoutData(current => ({ ...current, dismissed: false }));
		});

		return () => {
			ps.unsubscribe(PS_EVENT_AUTH_INTERACTION_RESET_DISMISS, subscriptionId);
		};
	}, []);

	useInterval(() => {
		if (isNumber(authExpiryInteraction) && isNumber(authExpiryProcess)) {
			const now = Math.floor(new Date() / 1000) * 1000;
			const expiryInteraction = new Date(Math.floor(authExpiryInteraction / 1000) * 1000);
			const expiryProcess = new Date(Math.floor(authExpiryProcess / 1000) * 1000);
			const secondsToExpiryInteraction = Math.max(0, (expiryInteraction - now) / 1000);
			const secondsToExpiryProcess = Math.max(0, (expiryProcess - now) / 1000);
			const type = secondsToExpiryInteraction < secondsToExpiryProcess ? TIMEOUT_INTERACTION : TIMEOUT_PROCESS;
			const secondsToExpiry = (type === TIMEOUT_INTERACTION ? secondsToExpiryInteraction : secondsToExpiryProcess) - INTERACTION_PROCESS_TIMEOUT_OFFSET;
			const timedOut = secondsToExpiry <= 0;

			setTimeoutData(current => ({
				...current,
				displayModal: (!current.dismissed && secondsToExpiry <= INTERACTION_PROCESS_TIMEOUT_ALERT_DURATION) || timedOut,
				secondsToExpiry,
				timedOut,
				type
			}));
		}
	}, 1000);

	const resetAuthProcess = () => {
		resetData({ interfaceErrors: [{ code: IAM_FAILURE_REASONS_CODE_PTO, type: ERROR_MESSAGE_TYPE_DEFAULT }] });
		navigate(`/${getPathForFlowAndStage(IAM_FLOW_AUTHENTICATE, getFlowRootStage(IAM_FLOW_AUTHENTICATE))}`, { replace: true });
	};

	const handleClick = () => {
		if (timeoutData.timedOut) {
			resetAuthProcess();
		} else {
			setTimeoutData(current => ({ ...current, dismissed: true }));
		}
	};

	const handleEndSession = () => {
		resetAuthProcess();
	};

	const getMessage = () => {
		let message = (
			<Typography align="center" component="p">
				Return to Sign In to start your task.
			</Typography>
		);

		if (timeoutData.secondsToExpiry && !timeoutData.timedOut) {
			let { value, title } = format.asHourMinSec(timeoutData.secondsToExpiry);

			message = (
				<React.Fragment>
					<Typography paragraph={true}>
						{
							timeoutData.type === TIMEOUT_INTERACTION ?
								"Complete your task within" :
								"You have"
						}
					</Typography>
					<Typography component="p" gutterBottom={true} variant="h2" title={title}>
						{value}
					</Typography>
					<Typography paragraph={true}>
						{
							timeoutData.type === TIMEOUT_INTERACTION ?
								"or your session will time out and you will have to start over." :
								"to take an action on this screen before it times out."
						}
					</Typography>
				</React.Fragment>
			);
		}

		return message;
	};

	return isAuthView() && (
		<BasicModal open={timeoutData.displayModal}>
			<Typography
				align={timeoutData.timedOut ? "center" : "left"}
				gutterBottom={true}
				mb={1.75}
				variant={timeoutData.timedOut ? "h2" : "h4"}
			>
				{timeoutData.timedOut ? "Session Timed Out" : "Session Timeout"}
			</Typography>
			{getMessage()}
			<Box
				mb={1.75}
				mt={5.625}
			>
				<Button
					align="center"
					onClick={handleClick}
					sx={{ width: 325 }}
					type="button"
					variant="contained"
				>
					{timeoutData.timedOut ? "Return to Sign In" : "Continue"}
				</Button>
			</Box>
			{
				!timeoutData.timedOut &&
				(
					<Box mt={1.75}>
						<Button
							align="center"
							onClick={handleEndSession}
							sx={{ width: 325 }}
							variant="outlined"
						>
							End Session
						</Button>
					</Box>
				)
			}
		</BasicModal>
	);
};

const mapStateToProps = state => {
	return {
		authExpiryInteraction: state.auth.authExpiryInteraction,
		authExpiryProcess: state.auth.authExpiryProcess
	};
};

const mapDispatchToProps = dispatch => {
	return {
		resetData: data => dispatch(authActions.resetData(data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(InteractionProcessTimeout);
export { INTERACTION_PROCESS_TIMEOUT_ALERT_DURATION };