import React from "react";
import { createVirtualPageViewGA4 } from "./analytics";
import useDocumentTitle from "./hooks/useDocumentTitle";

const withViewEnhancements = (WrappedComponent, title, routePath) => props => {

	useDocumentTitle(title);

	React.useEffect(() => {
		createVirtualPageViewGA4();
	}, []);

	return <WrappedComponent {...props} />;
};

export default withViewEnhancements;