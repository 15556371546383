import DefaultTemplate from "../templates/DefaultTemplate";
import React from "react";
import TaskHeader from "../../components/Task/TaskHeader";
import VerifyPasscode from "../../components/IAM/VerifyPasscode";
import { clearErrors } from "../../store/events/auth";
import {
	IAM_FLOW_AUTHENTICATE,
	IAM_FLOW_RESET_PASSWORD,
	IAM_STG_KEY_SECOND_FACTOR_SELECTION,
	getFlowRootStage,
	getPathForFlowAndStage,
	hasAuthId,
	withAuthCondition
} from "../utils/iamUtils";

const ResetPasswordVerifyPasscodeView = props => {

	const {
		isPendingFlowRestart,
		isPendingIAM
	} = props;

	return (
		<DefaultTemplate isTask={true}>
			<TaskHeader
				backCallback={clearErrors}
				backDisabled={isPendingIAM || isPendingFlowRestart}
				backDestination={`/${getPathForFlowAndStage(IAM_FLOW_RESET_PASSWORD, IAM_STG_KEY_SECOND_FACTOR_SELECTION)}`}
				closeResetsAuthFlow={true}
				closeTaskDestination={`/${getPathForFlowAndStage(IAM_FLOW_AUTHENTICATE, getFlowRootStage(IAM_FLOW_AUTHENTICATE))}`}
				title="Verification Code"
			/>
			<VerifyPasscode />
		</DefaultTemplate>
	);
};

export default withAuthCondition(ResetPasswordVerifyPasscodeView, hasAuthId, IAM_FLOW_RESET_PASSWORD);