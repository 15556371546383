import * as profileActions from "../../store/actions/profile";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import DefaultTemplate from "../templates/DefaultTemplate";
import { PS_EVENT_TASK_PAPERLESS_PREFS_COMPLETE } from "../../config/common";
import React from "react";
import TaskHeader from "../../components/Task/TaskHeader";
import TermsAndConditions from "../../components/Profile/PaperlessPreferences/TermsAndConditions";
import { connect } from "react-redux";
import { ps } from "../../components/Application/Application";
import useNavigateQSA from "../../utils/hooks/useNavigateQSA";
import withRequireProfile from "../../utils/withRequireProfile";

const PaperlessPreferencesTermsView = props => {

	const { setData } = props;

	const navigate = useNavigateQSA();

	const handleClose = () => {
		ps.publish(PS_EVENT_TASK_PAPERLESS_PREFS_COMPLETE);
	};

	return (
		<DefaultTemplate isTask={true}>
			<TaskHeader
				backCallback={() => {
					navigate(-1);
					setData({ interfaceErrors: [], systemErrors: [] });
				}}
				closeCallback={handleClose}
				title="Paperless Enrollment Terms & Conditions"
			/>
			<Container maxWidth="sm">
				<Box m="20px auto" p="20px 0 0">
					<TermsAndConditions />
				</Box>
			</Container>
		</DefaultTemplate>
	);
};

const mapStateToProps = state => {
	return {};
};

const mapDispatchToProps = dispatch => {
	return {
		setData: data => dispatch(profileActions.setData(data))
	};
};

export default withRequireProfile(connect(mapStateToProps, mapDispatchToProps)(PaperlessPreferencesTermsView));