import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import { Container } from "@mui/material";
import ExpandMore from "../ExpandMore/ExpandMore";
import PropTypes from "prop-types";
import { RE_LOCALHOST } from "../../config/common";
import React from "react";
import Typography from "@mui/material/Typography";
import { connect } from "react-redux";
import { currentClientId } from "../../config/clients";
import {
	API_NSP_ERROR_REPORTING,
	API_NSP_OPERATION_INCIDENTS_ANONYMOUS,
	getApiUrl
} from "../../config/api";
import {
	REQUEST_METHOD,
	errorReporting,
	isStringOfLength
} from "@pheaa/channels-component-library";

const errorStyles = {
	background: "#F2F4F6",
	fontFamily: "'Courier New', 'Courier', Arial, Helvetica, sans-serif",
	margin: "8px 0 12px",
	overflow: "auto",
	padding: 1
};

class ErrorBoundary extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			error: false,
			errorData: null,
			expanded: false
		};
	}

	static getDerivedStateFromError(error) {
		return { error: true, errorData: error };
	}

	componentDidCatch(error, errorInfo) {

		if (this.props.loggingEnabled) {
			const { componentStack } = errorInfo;
			const url = getApiUrl(API_NSP_ERROR_REPORTING, API_NSP_OPERATION_INCIDENTS_ANONYMOUS);

			let payload = {
				category: errorReporting.category.UI,
				client: {
					id: currentClientId,
					metaData: window.btoa(componentStack)
				},
				message: error.message.replace(RE_LOCALHOST, "").substring(0, 5000),
				resources: [],
				severity: errorReporting.severity.HIGH
			};

			fetch(url, {
				body: JSON.stringify(payload),
				headers: { "Content-Type": "application/json" },
				method: REQUEST_METHOD.POST
			}).then(response => response.json()).then(response => {
				if (isStringOfLength(response.incidentId)) {
					this.setState({ incidentId: response.incidentId });
				}
			});
		}
	}

	render() {

		if (this.state.error) {
			const { errorData, incidentId } = this.state;
			let incidentIdElem = null;

			const handleExpandClick = () => {
				this.setState({ expanded: !this.state.expanded });
			};

			if (isStringOfLength(incidentId)) {
				incidentIdElem = (
					<React.Fragment>
						<Typography component="h2" variant="h2">Incident Id</Typography>
						<Typography component="pre" sx={errorStyles}>{incidentId}</Typography>
						<Typography paragraph>This incident has been logged for further investigation.</Typography>
					</React.Fragment>
				);
			}

			return (
				<Container role="main" sx={{ display: "flex", flexDirection: "column", height: "100vh", justifyContent: "center" }}>
					<Box maxWidth="sm" mx="auto">
						<Typography component="h1" gutterBottom={true} variant="h1">An Error Occurred</Typography>
						<Typography paragraph>We're sorry, but an unexpected error has occurred. We apologize for the inconvenience and appreciate your patience.</Typography>
						<ExpandMore
							expand={this.state.expanded}
							onClick={handleExpandClick}
							label="More Details"
						/>
						<Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
							<Typography component="h2" variant="h2">Error Message</Typography>
							<Typography component="pre" sx={errorStyles}>{errorData.message}</Typography>
							{incidentIdElem}
						</Collapse>
					</Box>
				</Container>
			);
		}

		return this.props.children;
	}
}

// Define propTypes;
ErrorBoundary.propTypes = {
	children: PropTypes.node
};

// Define defaultProps;
ErrorBoundary.defaultProps = {};

const mapStateToProps = state => {
	return {
		loggingEnabled: state.common.appConfig.logging.enabled
	};
};

// Export component as default;
export default connect(mapStateToProps)(ErrorBoundary);