import * as authEvents from "../../store/events/auth";
import Box from "@mui/material/Box";
import Button from "../../components/Button/Button";
import { Form } from "@pheaa/channels-component-library";
import FormTextField from "../../components/Form/FormTextField";
import IAMErrorMessages from "../../components/ErrorMessages/IAMErrorMessages";
import React from "react";
import Typography from "@mui/material/Typography";
import { connect } from "react-redux";
import {
	HTML_ATTR_PATTERN_DOB,
	HTML_ATTR_PATTERN_NAME_LAST,
	HTML_ATTR_PATTERN_SSN_ACCOUNT_NO,
	HTML_ATTR_PATTERN_SSN_LAST_4_ACCOUNT_NO,
} from "../../config/common";

const VerifyInfo = props => {

	const {
		fullSSN,
		isPendingIAM,
		isUserMaxInfoVerificationAttempts,
		submitVerifyInfo,
		suppressDOB
	} = props;

	const handleSubmit = (e, formData) => {
		submitVerifyInfo(formData);
	};

	return (
		<React.Fragment>
			<Typography
				align="center"
				gutterBottom={true}
				paragraph={true}
			>
				Let's verify it's you. Please provide the following information.
			</Typography>
			<Form
				id="defaultFormNarrow"
				onSubmit={handleSubmit}
			>
				<IAMErrorMessages />
				<FormTextField
					name="accountIdentifier"
					pattern={fullSSN ? HTML_ATTR_PATTERN_SSN_ACCOUNT_NO : HTML_ATTR_PATTERN_SSN_LAST_4_ACCOUNT_NO}
					required={true}
					title={`Account Number or ${!fullSSN ? "Last Four of " : ""}SSN`}
				/>
				<FormTextField
					name="lastName"
					pattern={HTML_ATTR_PATTERN_NAME_LAST}
					required={true}
					title="Last Name"
				/>
				{!suppressDOB && <FormTextField
					helpMessage="MM/DD/YYYY"
					name="birthDate"
					pattern={HTML_ATTR_PATTERN_DOB}
					required={true}
					title="Date of Birth"
				/>
				}
				<Box
					mb={1.75}
					mt={5.625}
				>
					<Button
						disabled={isPendingIAM || isUserMaxInfoVerificationAttempts}
						type="submit"
						variant="contained"
					>
						Continue
					</Button>
				</Box>
			</Form>
		</React.Fragment>
	);
};

const mapStateToProps = state => {
	return {
		isPendingIAM: state.auth.isPendingIAM,
		isUserMaxInfoVerificationAttempts: state.auth.data.userMaxInfoVerificationAttempts
	};
};

const mapDispatchToProps = dispatch => {
	return {
		submitVerifyInfo: data => dispatch(authEvents.submitVerifyInfo(data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyInfo);