import authReducer from "./auth";
import { combineReducers } from "@reduxjs/toolkit";
import commonReducer from "./common";
import { persistReducer } from "redux-persist";
import profileReducer from "./profile";
import storageSession from "redux-persist/lib/storage/session";

const persistConfigAuth = {
	key: "auth",
	storage: storageSession,
	whitelist: ["authAccessExpiresAt", "lastLoginOn"]
};

const persistConfigCommon = {
	key: "common",
	storage: storageSession,
	whitelist: ["taskManager"]
};

const persistConfigProfile = {
	key: "profile",
	storage: storageSession,
	whitelist: ["data", "paperlessPreferencesPayload"]
};

const rootReducer = combineReducers({
	auth: persistReducer(persistConfigAuth, authReducer),
	common: persistReducer(persistConfigCommon, commonReducer),
	profile: persistReducer(persistConfigProfile, profileReducer)
});

export default rootReducer;