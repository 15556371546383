import { IAM_STATE } from "../../config/auth";
// import LoanDetailsView from "../../views/loans/LoanDetailsView";
// import LoanSummaryView from "../../views/loans/LoanSummaryView";
import MaintenanceView from "../../views/maintenance/MaintenanceView";
import NoContentView from "../../views/NoContentView";
import { PATHNAME_HOME_VIEW_UNAUTHENTICATED } from "../../config/common";
import PaperlessPreferencesAdvancedView from "../../views/profile/PaperlessPreferencesAdvancedView";
import PaperlessPreferencesConfirmEmailView from "../../views/profile/PaperlessPreferencesConfirmEmailView";
import PaperlessPreferencesTermsView from "../../views/profile/PaperlessPreferencesTermsView";
import PaperlessPreferencesUpdatedView from "../../views/profile/PaperlessPreferencesUpdatedView";
import PaperlessPreferencesView from "../../views/profile/PaperlessPreferencesView";
import PostAuthTaskQueue from "../../components/PostAuthTaskQueue/PostAuthTaskQueue";
import ProfileView from "../../views/profile/ProfileView";
import React from "react";
import { connect } from "react-redux";
import getManualMaintenanceStatus from "../../utils/getManualMaintenanceStatus";
import withSessionManager from "../../utils/withSessionManager";
import withViewEnhancements from "../../utils/withViewEnhancements";
import {
	Navigate,
	Outlet,
	Route,
	Routes
} from "react-router-dom";
import { isString, isStringOfLength } from "@pheaa/channels-component-library";

// Remember to update /lib/consumer-servicing-portal-routes.js with any changes to route paths;
const routeMap = [{
	route: "post-auth",
	title: "Transferring...",
	view: PostAuthTaskQueue
}, {
	pathAlias: ["content/consumer-portal/aes/profile.html", "content/consumer-portal/elfi/profile.html"],
	route: "profile",
	title: "Account Summary",
	view: ProfileView
}, {
	route: "profile/paperless",
	title: "Paperless Preferences",
	view: PaperlessPreferencesView
}, {
	route: "profile/paperless/advanced",
	view: PaperlessPreferencesAdvancedView
}, {
	route: "profile/paperless/confirm-email",
	view: PaperlessPreferencesConfirmEmailView
}, {
	route: "profile/paperless/terms",
	view: PaperlessPreferencesTermsView
}, {
	route: "profile/paperless/updated",
	view: PaperlessPreferencesUpdatedView
	// }, {
	// 	route: "loans",
	// 	view: LoanSummaryView
	// }, {
	// 	route: "loans/:loanId",
	// 	view: LoanDetailsView
}];

const RouteManager = props => {

	let returnElem = null;

	const {
		isAppInitialized,
		isInMaintenance
	} = props;

	if (isAppInitialized) {

		if (isInMaintenance) {
			returnElem = (
				<Routes>
					<Route path="*" element={<MaintenanceView />} />
				</Routes>
			);
		} else {
			returnElem = (
				<Routes>
					<Route path="/" element={<Navigate to={PATHNAME_HOME_VIEW_UNAUTHENTICATED} replace={true} />} />
					<Route path="/*" element={<Outlet />}>

						{/** Authentication, Registration, Recover Id, Reset Password Views */}
						{
							IAM_STATE.map(state => {
								return [state.path, state.pathAlias].filter(p => isStringOfLength(p)).map(path => {
									const ViewElement = withViewEnhancements(state.view, state.title, path);
									return (
										<Route
											element={<ViewElement />}
											key={`${state.flow}::${state.stage}`}
											path={path}
										/>
									);
								});
							})
						}

						{
							routeMap.map(routeData => {
								const ViewElement = withSessionManager(withViewEnhancements(routeData.view, routeData.title, routeData.route));
								let { pathAlias = [] } = routeData;
								isString(pathAlias) && (pathAlias = [pathAlias]);
								return [routeData.route, ...pathAlias].filter(p => isStringOfLength(p)).map(path => {
									return (
										<Route
											element={<ViewElement />}
											key={`${path}`}
											path={path}
										/>
									);
								});
							})
						}

						{/** Catch-All Views */}
						<Route
							element={<NoContentView />}
							path="*"
						/>
					</Route>
				</Routes>
			);
		}
	}

	return returnElem;
};

const mapStateToProps = state => {
	return {
		isAppInitialized: state.common.isAppInitialized,
		// Dynamic maintenance status from APIs, static status from appConfig;
		isInMaintenance: (state.common.maintenance || getManualMaintenanceStatus(state.common.appConfig.maintenance))
	};
};

export default connect(mapStateToProps)(RouteManager);
export { routeMap };