import RequireProfile from "../components/Profile/RequireProfile";
import store from "../store";
import { verifySession } from "../store/events/auth";
import React, { useEffect } from "react";

const withRequireProfile = WrappingComponent => props => {

	useEffect(() => {
		const {
			authAccessExpiresAt,
			isPendingSessionVerification
		} = store.getState().auth;

		if (authAccessExpiresAt === null && !isPendingSessionVerification) {
			store.dispatch(verifySession());
		}
	}, []);

	return (
		<RequireProfile>
			<WrappingComponent {...props} />
		</RequireProfile>
	);
};

export default withRequireProfile;