import * as ENV from "./environment";
import { isArrayOfLength } from "@pheaa/channels-component-library";

export const API_NSP_AUTH = "authentication";
export const API_NSP_ERROR_REPORTING = "error-reporting";
export const API_NSP_PROFILE = "profile";
export const API_NSP_TRANSFER = "transfer";

export const API_NSP_OPERATION_MAINTENANCE = "maintenance";
export const API_NSP_OPERATION_AUTHENTICATE = "authenticate";
export const API_NSP_OPERATION_REGISTRATION = "registration";
export const API_NSP_OPERATION_RECOVER_ID = "recover-user-id";
export const API_NSP_OPERATION_RECOVER_ID_CONTACT_IDENTIFIER = "recover-user-id.contact-identifier";
export const API_NSP_OPERATION_RECOVER_ID_NPPI = "recover-user-id.nppi";
export const API_NSP_OPERATION_RESET_PASSWORD = "reset-password";
export const API_NSP_OPERATION_CURRENT_DATETIME = "current-date-time";
export const API_NSP_OPERATION_SESSION = "session";
export const API_NSP_OPERATION_SESSION_EXTEND = "session.extend";
export const API_NSP_OPERATION_INCIDENTS = "incidents";
export const API_NSP_OPERATION_INCIDENTS_ANONYMOUS = "anonymous-incidents";
export const API_NSP_OPERATION_AUTH_TO_PORTAL = "auth-to-portal";
export const API_NSP_OPERATION_LEGACY_PORTAL = "legacy-portal";
export const API_NSP_OPERATION_PROFILE = "profile";
export const API_NSP_OPERATION_PROFILE_EMAIL_CONTACT = "profile.email.contact";
export const API_NSP_OPERATION_PROFILE_ECORR = "profile.ecorr";

export const MAINTENANCE_TAG_AUTH = "auth";
export const MAINTENANCE_TAG_PROFILE = "profile";

export const api = {
	[`${API_NSP_AUTH}.${API_NSP_OPERATION_MAINTENANCE}`]: {
		default: {
			maintenanceTags: [MAINTENANCE_TAG_AUTH],
			url: "https://api.{{env}}.aessuccess.org/v1/maintenance"
		}
	},
	[`${API_NSP_AUTH}.${API_NSP_OPERATION_AUTHENTICATE}`]: {
		default: {
			url: "https://api.{{env}}.aessuccess.org/v1/authentication/authenticate"
		}
	},
	[`${API_NSP_AUTH}.${API_NSP_OPERATION_REGISTRATION}`]: {
		default: {
			url: "https://api.{{env}}.aessuccess.org/v1/authentication/registration"
		}
	},
	[`${API_NSP_AUTH}.${API_NSP_OPERATION_RECOVER_ID}`]: {
		default: {
			url: "https://api.{{env}}.aessuccess.org/v1/authentication/recover-user-id"
		}
	},
	[`${API_NSP_AUTH}.${API_NSP_OPERATION_RECOVER_ID_CONTACT_IDENTIFIER}`]: {
		default: {
			url: "https://api.{{env}}.aessuccess.org/v1/authentication/recover-user-id/contact-identifier"
		}
	},
	[`${API_NSP_AUTH}.${API_NSP_OPERATION_RECOVER_ID_NPPI}`]: {
		default: {
			url: "https://api.{{env}}.aessuccess.org/v1/authentication/recover-user-id/nppi"
		}
	},
	[`${API_NSP_AUTH}.${API_NSP_OPERATION_RESET_PASSWORD}`]: {
		default: {
			url: "https://api.{{env}}.aessuccess.org/v1/authentication/reset-password"
		}
	},
	[`${API_NSP_AUTH}.${API_NSP_OPERATION_CURRENT_DATETIME}`]: {
		default: {
			url: "https://api.{{env}}.aessuccess.org/v1/authentication/current-datetime"
		}
	},
	[`${API_NSP_AUTH}.${API_NSP_OPERATION_SESSION}`]: {
		default: {
			url: "https://api.{{env}}.aessuccess.org/v1/authentication/session"
		}
	},
	[`${API_NSP_AUTH}.${API_NSP_OPERATION_SESSION_EXTEND}`]: {
		default: {
			url: "https://api.{{env}}.aessuccess.org/v1/authentication/session/extend"
		}
	},
	[`${API_NSP_ERROR_REPORTING}.${API_NSP_OPERATION_INCIDENTS}`]: {
		default: {
			url: "https://api.{{env}}.aessuccess.org/error-reporting/v1/incidents"
		}
	},
	[`${API_NSP_ERROR_REPORTING}.${API_NSP_OPERATION_INCIDENTS_ANONYMOUS}`]: {
		default: {
			url: "https://api.{{env}}.aessuccess.org/error-reporting/v1/anonymous-incidents"
		}
	},
	[`${API_NSP_TRANSFER}.${API_NSP_OPERATION_MAINTENANCE}`]: {
		default: {
			maintenanceTags: [MAINTENANCE_TAG_AUTH],
			url: "https://api.{{env}}.aessuccess.org/transfer/v1/maintenance"
		}
	},
	[`${API_NSP_TRANSFER}.${API_NSP_OPERATION_AUTH_TO_PORTAL}`]: {
		default: {
			url: "https://api.{{env}}.aessuccess.org/transfer/v1/auth-to-portal"
		}
	},
	[`${API_NSP_TRANSFER}.${API_NSP_OPERATION_LEGACY_PORTAL}`]: {
		default: {
			url: "https://account.{{env}}.aessuccess.org/accountAccess/index.cfm"
		},
		[ENV.PROD]: {
			url: "https://login.aessuccess.org/accountAccess/index.cfm"
		}
	},
	[`${API_NSP_PROFILE}.${API_NSP_OPERATION_MAINTENANCE}`]: {
		default: {
			maintenanceTags: [MAINTENANCE_TAG_PROFILE],
			url: "https://profile.api.{{env}}.aessuccess.org/v1/maintenance"
		}
	},
	[`${API_NSP_PROFILE}.${API_NSP_OPERATION_PROFILE}`]: {
		default: {
			url: "https://profile.api.{{env}}.aessuccess.org/v1/profiles/me"
		}
	},
	[`${API_NSP_PROFILE}.${API_NSP_OPERATION_PROFILE_EMAIL_CONTACT}`]: {
		default: {
			url: "https://profile.api.{{env}}.aessuccess.org/v1/profiles/me/emails/contact"
		}
	},
	[`${API_NSP_PROFILE}.${API_NSP_OPERATION_PROFILE_ECORR}`]: {
		default: {
			url: "https://profile.api.{{env}}.aessuccess.org/v1/profiles/me/ecorrespondence"
		}
	}
};

export const getApiConfigForKey = key => {
	try {
		const config = api[key];
		return config.hasOwnProperty(ENV.currentEnv) ? config[ENV.currentEnv] : config.default;
	} catch (e) {
		console.warn("Cannot retrieve API configuration:", e);
		return { url: false, method: false };
	}
};

export const getApiConfigForNspAndOperation = (nsp, nspOperation) => {
	try {
		const config = api[`${nsp.toLowerCase()}.${nspOperation.toLowerCase()}`];
		return config.hasOwnProperty(ENV.currentEnv) ? config[ENV.currentEnv] : config.default;
	} catch (e) {
		console.warn("Cannot retrieve API configuration:", e);
		return { url: false, method: false };
	}
};

export const getApiUrl = (nsp, nspOperation) => {
	try {
		const config = getApiConfigForNspAndOperation(nsp, nspOperation);
		return ENV.replaceEnvTokenInUrl(config.url);
	} catch (e) {
		console.warn("Cannot retrieve API URL:", e);
		return "";
	}
};

export const getApiUrlForKey = key => {
	try {
		const config = getApiConfigForKey(key);
		return ENV.replaceEnvTokenInUrl(config.url);
	} catch (e) {
		console.warn("Cannot retrieve API URL:", e);
		return "";
	}
};

export const getMaintEndpoints = (uniqueHostnameOnly = false, requiredTags = [MAINTENANCE_TAG_AUTH]) => {
	// Create array for maintenance hostnames;
	let maintHostnames = [];
	let maintHostnamesUnique = [];

	try {
		// Iterate over api configurations;
		maintHostnames = Object.keys(api).filter(key => key.endsWith(`.${API_NSP_OPERATION_MAINTENANCE}`)).filter(key => {
			let apiConfig = getApiConfigForKey(key);
			return isArrayOfLength(apiConfig.maintenanceTags) && requiredTags.some(requiredTag => apiConfig.maintenanceTags.includes(requiredTag));
		}).map(key => {
			// Set hostname from configuration URL in current environment;
			const { hostname, href } = new URL(getApiUrlForKey(key));

			if (uniqueHostnameOnly) {

				let hasMatchInUniqueHostnameArray = false;

				maintHostnamesUnique.forEach(uniqueHostnameObj => {
					if (uniqueHostnameObj.hostname === hostname) {
						hasMatchInUniqueHostnameArray = true;
					}
				});

				!hasMatchInUniqueHostnameArray && maintHostnamesUnique.push({ hostname, url: href });
			}
			// Return object of hostname and url;
			return { hostname, url: href };
		});

	} catch (e) {
		console.warn("Error collecting maintenance hostnames from config.");
		console.error(e);
	}

	return uniqueHostnameOnly ? maintHostnamesUnique : maintHostnames;
};