import * as authEvents from "../../store/events/auth";
import Box from "@mui/material/Box";
import Button from "../../components/Button/Button";
import DefaultTemplate from "../templates/DefaultTemplate";
import { Form } from "@pheaa/channels-component-library";
import FormTextField from "../../components/Form/FormTextField";
import IAMErrorMessages from "../../components/ErrorMessages/IAMErrorMessages";
import { InteractionProcessTimeoutA11y } from "../../components/IAM/A11y";
import React from "react";
import Typography from "@mui/material/Typography";
import { connect } from "react-redux";
import { setData as setAuthData } from "../../store/actions/auth";
import useNavigateQSA from "../../utils/hooks/useNavigateQSA";
import {
	IAM_FLOW_RECOVER_ID,
	IAM_FLOW_REGISTER,
	IAM_FLOW_RESET_PASSWORD,
	getFlowRootStage,
	getPathForFlowAndStage
} from "../utils/iamUtils";

const AuthCredentialsView = props => {

	const {
		isPendingIAM,
		setData,
		submitCredentials,
	} = props;

	const navigate = useNavigateQSA();

	const handleCreateAccount = e => {
		setData({ interfaceErrors: [], systemErrors: [] });
		navigate(`/${getPathForFlowAndStage(IAM_FLOW_REGISTER, getFlowRootStage(IAM_FLOW_REGISTER))}`);
	};

	const handleForgotUsername = e => {
		setData({ interfaceErrors: [], systemErrors: [] });
		navigate(`/${getPathForFlowAndStage(IAM_FLOW_RECOVER_ID, getFlowRootStage(IAM_FLOW_RECOVER_ID))}`);
	};

	const handleForgotPassword = e => {
		setData({ interfaceErrors: [], systemErrors: [] });
		navigate(`/${getPathForFlowAndStage(IAM_FLOW_RESET_PASSWORD, getFlowRootStage(IAM_FLOW_RESET_PASSWORD))}`);
	};

	const handleSubmit = (e, formData) => {
		submitCredentials(formData);
	};

	return (
		<DefaultTemplate>
			<Typography
				component="h1"
				my={5}
				textAlign="center"
				variant="h1"
			>
				Sign In to Account Access
			</Typography>
			<Form
				id="defaultFormNarrow"
				onSubmit={handleSubmit}
			>
				<IAMErrorMessages />
				<InteractionProcessTimeoutA11y />
				<FormTextField
					name="userId"
					required={true}
					title="Username"
				/>
				<FormTextField
					name="password"
					passwordRevealEnabled={true}
					required={true}
					title="Password"
					type="password"
				/>
				<Box
					mb={1.75}
					mt={5.625}
				>
					<Button
						disabled={isPendingIAM}
						type="submit"
						variant="contained"
					>
						Sign In
					</Button>
				</Box>
				<Box mt={1.75}>
					<Button
						disabled={isPendingIAM}
						onClick={handleCreateAccount}
						variant="outlined"
					>
						Create an Account
					</Button>
				</Box>
				<Box mt={1.75}>
					<Button
						disabled={isPendingIAM}
						onClick={handleForgotUsername}
						variant="text"
					>
						Forgot Username?
					</Button>
				</Box>
				<Box
					mb={10}
					mt={0}
				>
					<Button
						disabled={isPendingIAM}
						onClick={handleForgotPassword}
						variant="text"
					>
						Forgot Password?
					</Button>
				</Box>
			</Form>
		</DefaultTemplate>
	);
};

const mapStateToProps = state => {
	return {
		isPendingIAM: state.auth.isPendingIAM
	};
};

const mapDispatchToProps = dispatch => {
	return {
		setData: data => dispatch(setAuthData(data)),
		submitCredentials: data => dispatch(authEvents.submitCredentials(data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthCredentialsView);