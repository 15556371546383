import * as authEvents from "../../store/events/auth";
import Alert from "@mui/material/Alert";
import Button from "../../components/Button/Button";
import React from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import {
	isArrayOfLength,
	isStringOfLength
} from "@pheaa/channels-component-library";

const SelectDevice = props => {

	let deviceListElem = null;

	const {
		contactOptions,
		includePhoneContactMethods = false,
		isPendingIAM,
		secondFactorDevice,
		submitSelectedDevice
	} = props;

	const cmpClassName = classNames({
		"cmp-select-device": true
	});

	const getDescriptionMessage = (contactOptionType, contactOptionDescription) => {
		let descriptionMessage = "";

		if (contactOptionType === "email") {
			descriptionMessage = `Your code will be emailed to ${contactOptionDescription}`;
		} else if (contactOptionType === "sms") {
			descriptionMessage = `Your code will be sent via SMS to ${contactOptionDescription}`;
		} else if (contactOptionType === "voice") {
			descriptionMessage = `You will receive a voice call containing your code at ${contactOptionDescription}`;
		}

		return descriptionMessage;
	};

	const getContactOption = (contactOption, key) => {
		let contactOptionElem = null;
		let descriptionMessage = getDescriptionMessage(contactOption.type, contactOption.description);

		if (isStringOfLength(descriptionMessage)) {

			contactOptionElem = (
				<li
					className="cmp-select-device__item"
					data-type={contactOption.type}
					key={key}
				>
					<div className="cmp-select-device__item-icon"></div>
					<div className="cmp-select-device__item-desc">
						{descriptionMessage}
					</div>
					<Button
						disabled={isPendingIAM}
						onClick={e => {
							submitSelectedDevice({
								channel: contactOption.type,
								description: contactOption.description,
								id: contactOption.id
							});
						}}
						size="large"
						type="submit"
					>
						{(isPendingIAM && contactOption.type === secondFactorDevice.channel && contactOption.id === secondFactorDevice.contactId) ? "Processing..." : "Send Code"}
					</Button>
				</li>
			);
		}

		return contactOptionElem;
	};

	if (isArrayOfLength(contactOptions)) {
		deviceListElem = (
			<ul className={cmpClassName}>
				{
					contactOptions.map((contactOption, i) => {

						if (isArrayOfLength(contactOption.channels)) {
							return contactOption.channels.map((channel, j) => {
								let iterationContactOption = Object.assign({}, contactOption, {
									type: channel
								});
								return (!includePhoneContactMethods && ["sms", "voice"].includes(channel)) ? null : getContactOption(iterationContactOption, (i + "_" + j));
							});
						} else {
							return getContactOption(contactOption, (i + "_0"));
						}
					})
				}
			</ul>
		);
	} else {
		deviceListElem = (
			<ul className={cmpClassName}>
				<li className="cmp-select-device__item cmp-select-device__error">
					<Alert severity="warning">Something went wrong. Your device list could not be found.</Alert>
				</li>
			</ul>
		);
	}

	return deviceListElem;
};

const mapStateToProps = state => {
	return {
		contactOptions: state.auth.contactOptions,
		isPendingIAM: state.auth.isPendingIAM,
		secondFactorDevice: state.auth.data.secondFactorDevice,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		submitSelectedDevice: data => dispatch(authEvents.submitSelectedDevice(data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectDevice);