import React from "react";
import {
	IAM_FLOW_REGISTER,
	hasAuthId,
	withAuthCondition
} from "../utils/iamUtils";

const RegistrationVerifyInfoView = props => {
	return (
		<div>RegistrationVerifyInfoView</div>
	);
};

export default withAuthCondition(RegistrationVerifyInfoView, hasAuthId, IAM_FLOW_REGISTER);