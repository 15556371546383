import DefaultTemplate from "../templates/DefaultTemplate";
import React from "react";
import TaskHeader from "../../components/Task/TaskHeader";
import TermsAndConditions from "../../components/IAM/TermsAndConditions";
import {
	IAM_FLOW_AUTHENTICATE,
	getFlowRootStage,
	getPathForFlowAndStage,
	hasAuthId,
	withAuthCondition
} from "../utils/iamUtils";

const AuthTermsView = props => {
	return (
		<DefaultTemplate isTask={true}>
			<TaskHeader
				closeResetsAuthFlow={true}
				closeTaskDestination={`/${getPathForFlowAndStage(IAM_FLOW_AUTHENTICATE, getFlowRootStage(IAM_FLOW_AUTHENTICATE))}`}
				title="Online Services Terms of Use"
			/>
			<TermsAndConditions />
		</DefaultTemplate>
	);
};

export default withAuthCondition(AuthTermsView, hasAuthId, IAM_FLOW_AUTHENTICATE);