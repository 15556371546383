import CssBaseline from "@mui/material/CssBaseline";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
import React from "react";
import RouteManager from "../RouteManager/RouteManager";
import { BrowserRouter as Router } from "react-router-dom";
import SubscriptionManager from "../PubSub/SubscriptionManager";
import { currentClientTheme } from "../../config/clients";
import initializeApplication from "../../utils/initializeApplication";
import { publisherSubscriber } from "../../utils/publisherSubscriber";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const customTheme = createTheme(currentClientTheme);
const ps = publisherSubscriber();

initializeApplication();

const Application = () => {
	return (
		<ThemeProvider theme={customTheme}>
			<CssBaseline />
			<ErrorBoundary>
				<Router>
					<SubscriptionManager ps={ps} />
					<RouteManager />
				</Router>
			</ErrorBoundary>
		</ThemeProvider>
	);
};

export default Application;
export { ps };