import DefaultTemplate from "../templates/DefaultTemplate";
import React from "react";
import TaskHeader from "../../components/Task/TaskHeader";
import VerifyPasscode from "../../components/IAM/VerifyPasscode";
import {
	IAM_FLOW_AUTHENTICATE,
	IAM_FLOW_RECOVER_ID,
	getFlowRootStage,
	getPathForFlowAndStage,
	hasAuthId,
	withAuthCondition
} from "../utils/iamUtils";

const RecoverIdVerifyPasscodeView = props => {
	return (
		<DefaultTemplate isTask={true}>
			<TaskHeader
				closeResetsAuthFlow={true}
				closeTaskDestination={`/${getPathForFlowAndStage(IAM_FLOW_AUTHENTICATE, getFlowRootStage(IAM_FLOW_AUTHENTICATE))}`}
				title="Verification Code"
			/>
			<VerifyPasscode />
		</DefaultTemplate>
	);
};

export default withAuthCondition(RecoverIdVerifyPasscodeView, hasAuthId, IAM_FLOW_RECOVER_ID);