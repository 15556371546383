import commonApiRequest from "../../utils/commonApiRequest";
import { currentClientId } from "../../config/clients";
import { setData } from "../actions/common";
import {
	API_NSP_ERROR_REPORTING,
	API_NSP_OPERATION_INCIDENTS_ANONYMOUS,
	getApiUrl,
	getMaintEndpoints
} from "../../config/api";
import {
	REQUEST_METHOD,
	errorReporting,
	isArrayOfLength,
	isObject
} from "@pheaa/channels-component-library";

export const getMaintenanceStatus = () => (dispatch, getState) => {
	const maintEndpoints = getMaintEndpoints(true);
	const maintRequests = maintEndpoints.map(({ url }) => {
		return commonApiRequest(url, {
			errorCallback: ({ body, headers, status }) => {
				const urlErrorReport = getApiUrl(API_NSP_ERROR_REPORTING, API_NSP_OPERATION_INCIDENTS_ANONYMOUS);
				commonApiRequest(urlErrorReport, {
					data: {
						category: errorReporting.category.OTHER,
						client: { id: currentClientId },
						message: "Error checking maintenance status for resource",
						resources: [{
							method: REQUEST_METHOD.GET,
							responseStatus: status,
							responseBody: body,
							resource: url.replace(/localhost(:\d+)?/gi, ""),
							traceContext: headers.get("trace-context") || ""
						}],
						severity: errorReporting.severity.LOW
					},
					method: REQUEST_METHOD.POST
				});
			}
		});
	});

	return Promise.all(maintRequests).then(maintResponses => {
		// Define array of aggregated responses;
		let aggregatedResponses = [];

		if (isArrayOfLength(maintResponses)) {
			// Iterate over endpoints to get index;
			maintEndpoints.forEach((endpoint, index) => {
				// If maintResponses[index] is an object that can be destructured;
				if (isObject(maintResponses[index])) {
					// This is weird because API returns [] instead of {} as JSON;
					const { body = [] } = maintResponses[index];
					// Aggregate response body [] with others response body arrays;
					isArrayOfLength(body) && (aggregatedResponses = aggregatedResponses.concat(body));
				}
			});
		}

		dispatch(setData({
			maintenance: aggregatedResponses.filter(({ status }) => status === "maintenance").length > 0,
			maintenanceData: aggregatedResponses
		}));
	});
};