import { currentClientId } from "../config/clients";
import { sendRequest } from "@pheaa/channels-component-library";

const commonApiRequest = (endpoint, { headers = {}, ...rest } = {}) => {

	return sendRequest(endpoint, {
		...rest,
		headers: { ...headers, "X-Client-Id": currentClientId }
	});
};

export default commonApiRequest;