import * as authEvents from "../../store/events/auth";
import Box from "@mui/material/Box";
import Button from "../../components/Button/Button";
import DefaultTemplate from "../templates/DefaultTemplate";
import { Form } from "@pheaa/channels-component-library";
import FormTextField from "../../components/Form/FormTextField";
import { HTML_ATTR_PATTERN_EMAIL } from "../../config/common";
import IAMErrorMessages from "../../components/ErrorMessages/IAMErrorMessages";
import { InteractionProcessTimeoutA11y } from "../../components/IAM/A11y";
import React from "react";
import TaskHeader from "../../components/Task/TaskHeader";
import Typography from "@mui/material/Typography";
import { connect } from "react-redux";
import { setData as setAuthData } from "../../store/actions/auth";
import useNavigateQSA from "../../utils/hooks/useNavigateQSA";
import {
	IAM_FLOW_AUTHENTICATE,
	IAM_FLOW_RECOVER_ID,
	IAM_STG_KEY_NPPI,
	getFlowRootStage,
	getPathForFlowAndStage
} from "../utils/iamUtils";

const RecoverIdEmailView = props => {

	const {
		isPendingIAM,
		setData,
		submitRecoverIdEmail
	} = props;

	const navigate = useNavigateQSA();

	const handleSubmit = (e, formData) => {
		submitRecoverIdEmail(formData);
	};

	return (
		<DefaultTemplate isTask={true}>
			<TaskHeader
				closeResetsAuthFlow={true}
				closeTaskDestination={`/${getPathForFlowAndStage(IAM_FLOW_AUTHENTICATE, getFlowRootStage(IAM_FLOW_AUTHENTICATE))}`}
				title="Forgot Username"
			/>
			<Typography
				align="center"
				gutterBottom={true}
				paragraph={true}
			>
				Please enter the email address associated with your account.
			</Typography>
			<Form
				id="defaultFormNarrow"
				onSubmit={handleSubmit}
			>
				<IAMErrorMessages />
				<InteractionProcessTimeoutA11y />
				<FormTextField
					name="contactIdentifier"
					pattern={HTML_ATTR_PATTERN_EMAIL}
					required={true}
					title="Email Address"
				/>
				<Box
					mb={1.75}
					mt={5.625}
				>
					<Button
						disabled={isPendingIAM}
						type="submit"
						variant="contained"
					>
						Send Verification
					</Button>
				</Box>
				<Typography
					align="center"
					paragraph={true}
				>
					I do not remember this information.
				</Typography>
				<Box mb={1.75}>
					<Button
						disabled={isPendingIAM}
						onClick={() => {
							setData({ interfaceErrors: [], systemErrors: [] });
							navigate(`/${getPathForFlowAndStage(IAM_FLOW_RECOVER_ID, IAM_STG_KEY_NPPI)}`, { replace: true });
						}}
						variant="text"
					>
						Try Another Method
					</Button>
				</Box>
			</Form>
		</DefaultTemplate>
	);
};

const mapStateToProps = state => {
	return {
		isPendingIAM: state.auth.isPendingIAM
	};
};

const mapDispatchToProps = dispatch => {
	return {
		setData: data => dispatch(setAuthData(data)),
		submitRecoverIdEmail: data => dispatch(authEvents.submitRecoverIdEmail(data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(RecoverIdEmailView);