import React from "react";
import { connect } from "react-redux";
import { getErrorMessageForCode } from "../../config/common";
import ErrorMessages, { ERROR_MESSAGE_TYPE_DEFAULT } from "./ErrorMessages";

const ProfileErrorMessages = props => {

	const {
		interfaceErrors,
		interfaceErrorMessageOverrides = {},
		systemErrors,
		systemErrorMessageOverrides = {}
	} = props;

	return (
		<React.Fragment>
			<ErrorMessages
				errorMessages={systemErrors.map(err => {
					return {
						id: err.code,
						message: getErrorMessageForCode(err.code, systemErrorMessageOverrides),
						type: ERROR_MESSAGE_TYPE_DEFAULT
					};
				})}
			/>
			<ErrorMessages
				errorMessages={interfaceErrors.map(err => {
					return {
						id: err.code,
						message: getErrorMessageForCode(err.code, interfaceErrorMessageOverrides),
						type: err.type
					};
				})}
			/>
		</React.Fragment>
	);
};

const mapStateToProps = state => {
	return {
		interfaceErrors: state.profile.interfaceErrors,
		systemErrors: state.profile.systemErrors
	};
};

export default connect(mapStateToProps)(ProfileErrorMessages);