import Box from "@mui/material/Box";
import Button from "../../components/Button/Button";
import DefaultTemplate from "../templates/DefaultTemplate";
import { Form } from "@pheaa/channels-component-library";
import React from "react";
import TaskHeader from "../../components/Task/TaskHeader";
import Typography from "@mui/material/Typography";
import { connect } from "react-redux";
import { resetData } from "../../store/actions/auth";
import useNavigateQSA from "../../utils/hooks/useNavigateQSA";
import {
	IAM_FLOW_AUTHENTICATE,
	IAM_FLOW_RECOVER_ID,
	getFlowRootStage,
	getPathForFlowAndStage,
	hasAuthDataUserId,
	withAuthCondition
} from "../utils/iamUtils";

const RecoverIdCompleteView = props => {

	const { authData, resetAuthData } = props;

	const navigate = useNavigateQSA();

	const handleSubmit = () => {
		resetAuthData({
			currentFlow: IAM_FLOW_AUTHENTICATE,
			currentStage: getFlowRootStage(IAM_FLOW_AUTHENTICATE)
		});
		navigate(`/${getPathForFlowAndStage(IAM_FLOW_AUTHENTICATE, getFlowRootStage(IAM_FLOW_AUTHENTICATE))}`);
	};

	return (
		<DefaultTemplate isTask={true}>
			<TaskHeader
				closeResetsAuthFlow={true}
				closeTaskDestination={`/${getPathForFlowAndStage(IAM_FLOW_AUTHENTICATE, getFlowRootStage(IAM_FLOW_AUTHENTICATE))}`}
				title="Forgot Username"
			/>
			<Typography
				align="center"
				gutterBottom={true}
				paragraph={true}
			>
				Your username is:
			</Typography>
			<Form
				id="defaultFormNarrow"
				onSubmit={handleSubmit}
			>
				<Typography
					align="center"
					gutterBottom={true}
					paragraph={true}
					variant="h4"
				>
					{authData.userId}
				</Typography>
				<Box
					mb={1.75}
					mt={5.625}
				>
					<Button
						type="submit"
						variant="contained"
					>
						Return to Sign In
					</Button>
				</Box>
			</Form>
		</DefaultTemplate>
	);
};

const mapStateToProps = state => {
	return {
		authData: state.auth.data
	};
};

const mapDispatchToProps = dispatch => {
	return {
		resetAuthData: data => dispatch(resetData(data))
	};
};

export default withAuthCondition(connect(mapStateToProps, mapDispatchToProps)(RecoverIdCompleteView), hasAuthDataUserId, IAM_FLOW_RECOVER_ID);