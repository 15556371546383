import store from "../../store";
import { useNavigate } from "react-router-dom";
import { isNumber, isObject, isStringOfLength } from "@pheaa/channels-component-library";

const useNavigateQSA = () => {

	const navigate = useNavigate();

	return (to, navigateOptions = {}) => {
		let pathname = to, search;
		const queryParameters = new URLSearchParams(window.location.search);
		const { returnKey } = store.getState().common;

		// Clean up single-use query parameters;
		queryParameters.delete("expired");
		queryParameters.delete("logout");
		queryParameters.delete("returnKey");

		if (isNumber(to)) {
			// Preserve ability to sequentially navigate history stack;
			navigate(to);
		} else {

			isObject(to) && ({ pathname, search } = to);

			if (isObject(search)) {
				Object.keys(search).forEach(key => {
					queryParameters.set(key, search[key]);
				});
			}

			if (isStringOfLength(returnKey) && !queryParameters.has("returnKey")) {
				queryParameters.append("returnKey", returnKey);
			}

			navigate({ pathname, search: queryParameters.toString() }, navigateOptions);
		}
	};
};

export default useNavigateQSA;