import { RE_LOCALHOST } from "../config/common";
import commonApiRequest from "./commonApiRequest";
import { currentClientId } from "../config/clients";
import {
	API_NSP_ERROR_REPORTING,
	API_NSP_OPERATION_INCIDENTS_ANONYMOUS,
	getApiUrl
} from "../config/api";
import {
	REQUEST_METHOD,
	errorReporting,
	isStringOfLength
} from "@pheaa/channels-component-library";

const cleanMessage = message => {
	return message.replace(RE_LOCALHOST, "").substring(0, 5000);
};

export const sendErrorReport = (error, { category = errorReporting.category.OTHER, message, severity = errorReporting.severity.LOW }) => {
	const urlErrorReport = getApiUrl(API_NSP_ERROR_REPORTING, API_NSP_OPERATION_INCIDENTS_ANONYMOUS);
	commonApiRequest(urlErrorReport, {
		data: {
			category,
			client: {
				id: currentClientId,
				metaData: window.btoa(error)
			},
			message: isStringOfLength(message) ? cleanMessage(message) : (isStringOfLength(error.message) ? cleanMessage(error.message) : "An error has been identified."),
			resources: [],
			severity
		},
		method: REQUEST_METHOD.POST
	});
};