import AccountBlocked from "../../components/IAM/AccountBlocked";
import DefaultTemplate from "../templates/DefaultTemplate";
import React from "react";
import TaskHeader from "../../components/Task/TaskHeader";
import {
	IAM_FLOW_AUTHENTICATE,
	getFlowRootStage,
	getPathForFlowAndStage,
	hasAuthAccountBlocked,
	withAuthCondition
} from "../utils/iamUtils";

const AuthAccountBlockedView = props => {
	return (
		<DefaultTemplate isTask={true}>
			<TaskHeader
				closeResetsAuthFlow={true}
				closeTaskDestination={`/${getPathForFlowAndStage(IAM_FLOW_AUTHENTICATE, getFlowRootStage(IAM_FLOW_AUTHENTICATE))}`}
				title="Account Blocked"
			/>
			<AccountBlocked />
		</DefaultTemplate>
	);
};

export default withAuthCondition(AuthAccountBlockedView, hasAuthAccountBlocked, IAM_FLOW_AUTHENTICATE);