import * as profileActions from "../../store/actions/profile";
import * as profileEvents from "../../store/events/profile";
import BasicModal from "../../components/Modal/BasicModal";
import Box from "@mui/material/Box";
import Button from "../../components/Button/Button";
import CloseIcon from "@mui/icons-material/Close";
import Container from "@mui/material/Container";
import DefaultTemplate from "../templates/DefaultTemplate";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import IconButton from "@mui/material/IconButton";
import { PS_EVENT_TASK_PAPERLESS_PREFS_COMPLETE } from "../../config/common";
import ProfileErrorMessages from "../../components/ErrorMessages/ProfileErrorMessages";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Skeleton from "@mui/material/Skeleton";
import TaskHeader from "../../components/Task/TaskHeader";
import Typography from "@mui/material/Typography";
import { connect } from "react-redux";
import getOffsetDate from "../../utils/getOffsetDate";
import { ps } from "../../components/Application/Application";
import useNavigateQSA from "../../utils/hooks/useNavigateQSA";
import withRequireProfile from "../../utils/withRequireProfile";
import {
	Form,
	generateId,
	isArrayOfLength,
	isObject
} from "@pheaa/channels-component-library";
import React, { useState } from "react";

const PAPRELESS_ENROLLMENT_STATUS = {
	FULL: "full",
	NONE: "none",
	PART: "partial"
};

const PAPERLESS_PROGRAM_KEY_BILLING = "ebilling";
const PAPERLESS_PROGRAM_KEY_LETTERS = "eletter";
const PAPERLESS_PROGRAM_KEY_TAX = "etax";

const PAPERLESS_CONFIG = {

	optIns: [{
		description: "Receive monthly email reminders with a secure link to view and pay your bill when it's due.",
		id: PAPERLESS_PROGRAM_KEY_BILLING,
		title: "Billing Statements"
	}, {
		description: "Receive letters and forms faster by accessing them in electronic format.",
		id: PAPERLESS_PROGRAM_KEY_LETTERS,
		title: "Letters and Forms"
	}, {
		description: "Receive 1098-E and 1099-C tax statements in electronic format.",
		id: PAPERLESS_PROGRAM_KEY_TAX,
		title: "Tax Statements"
	}],

	content: {
		heading: {
			[PAPRELESS_ENROLLMENT_STATUS.FULL]: "Review your electronic correspondence.",
			[PAPRELESS_ENROLLMENT_STATUS.NONE]: "Sign up for electronic correspondence.",
			[PAPRELESS_ENROLLMENT_STATUS.PART]: "Add to your electronic correspondence."
		},
		description: {
			[PAPRELESS_ENROLLMENT_STATUS.FULL]: "You are already enrolled in all electronic correspondence options.",
			[PAPRELESS_ENROLLMENT_STATUS.NONE]: "Go paperless, and say goodbye to paper mail! We'll send you an email each time we deliver these communications to your account inbox:",
			[PAPRELESS_ENROLLMENT_STATUS.PART]: "You have already enrolled in some items electronically. Consider enrolling in all of the options."
		},
		additionalDetails: "Don't worry, it's FREE, and you can change your mind at anytime!"
	},

	reminderOffsetDaysSignUp: 365,
	reminderOffsetDaysNoThanks: 365,
	reminderOffsetDaysAdvanced: 365,
	reminderOffsets: [{
		label: "One (1) Month",
		offsetDays: 30
	}, {
		label: "Six (6) Months",
		offsetDays: 180
	}, {
		label: "One (1) Year",
		offsetDays: 365
	}]
};

const getPaperlessProgramConfigById = id => {
	return PAPERLESS_CONFIG.optIns.find(program => program.id === id);
};

const getPaperlessProgramIndexById = id => {
	return PAPERLESS_CONFIG.optIns.findIndex(program => program.id === id);
};

const PaperlessPreferencesView = props => {

	let additionalDetails = <Skeleton sx={{ fontSize: "2.4rem" }} />;
	let contentHeading = <Skeleton sx={{ fontSize: "2.4rem", margin: "0 auto" }} width={250} />;
	let description = <Skeleton sx={{ fontSize: "2.4rem" }} />;
	let displayAdvancedOptions = false;
	let enrollmentData = {
		status: PAPRELESS_ENROLLMENT_STATUS.NONE,
		optIns: PAPERLESS_CONFIG.optIns.slice()
	};

	const {
		isFetchingProfile,
		isUpdatingPaperlessPreferences,
		profileData,
		setData,
		tokenLastVerifiedAt,
		updatePaperlessPreferences
	} = props;

	const [displayModal, setDisplayModal] = useState(false);
	const [idFormLabel] = useState(generateId());
	const navigate = useNavigateQSA();

	const handleClose = () => {
		ps.publish(PS_EVENT_TASK_PAPERLESS_PREFS_COMPLETE);
	};

	const handleSubmit = () => {
		// Update payload in store;
		setData({
			paperlessPreferencesPayload: {
				optIns: enrollmentData.optIns.map(program => {
					// Setting payload to opt in all programs;
					return { id: program.id, isIn: true };
				}),
				presentmentDeferredUntil: getOffsetDate(tokenLastVerifiedAt, PAPERLESS_CONFIG.reminderOffsetDaysSignUp).toISOString()
			}
		});
		// Navigate to email address confirmation;
		navigate("/profile/paperless/confirm-email");
	};

	const handleSubmitRemindMeLater = (e, formData) => {
		const payload = { presentmentDeferredUntil: getOffsetDate(tokenLastVerifiedAt, parseInt(formData.offsetDays, 10)).toISOString() };
		// Update payload in store;
		setData({ paperlessPreferencesPayload: payload });
		// Invoke API to update paperless preferences;
		updatePaperlessPreferences(payload, {
			successCallback: () => {
				ps.publish(PS_EVENT_TASK_PAPERLESS_PREFS_COMPLETE);
			}
		});
	};

	const handleRemindMeLater = () => {
		setDisplayModal(true);
	};

	const handleNoThanks = () => {
		const payload = { presentmentDeferredUntil: getOffsetDate(tokenLastVerifiedAt, PAPERLESS_CONFIG.reminderOffsetDaysNoThanks).toISOString() };
		// Update payload in store;
		setData({ paperlessPreferencesPayload: payload });
		// Invoke API to update paperless preferences;
		updatePaperlessPreferences(payload, {
			successCallback: () => {
				setData({ paperlessPreferencesPayload: {} });
				ps.publish(PS_EVENT_TASK_PAPERLESS_PREFS_COMPLETE);
			}
		});
	};

	const handleAdvancedOptions = () => {
		// Update payload in store;
		setData({
			paperlessPreferencesPayload: {
				presentmentDeferredUntil: getOffsetDate(tokenLastVerifiedAt, PAPERLESS_CONFIG.reminderOffsetDaysAdvanced).toISOString()
			}
		});
		// Navigate to advanced options view;
		navigate("/profile/paperless/advanced");
	};

	if (isObject(profileData) && isObject(profileData.ecorrespondence)) {
		let { optIns = [] } = profileData.ecorrespondence;
		let programsEligible = 0;
		let programsOptedIn = 0;

		if (isArrayOfLength(optIns)) {
			// Iterate over optIns;
			optIns.forEach(optIn => {
				// Set enrollmentData for optIn by index (maintaining sortability);
				enrollmentData.optIns[getPaperlessProgramIndexById(optIn.id)] = {
					...getPaperlessProgramConfigById(optIn.id),
					isEligible: optIn.isEligible,
					isIn: optIn.isIn
				};
			});
		}

		// Filter optIns array to include only those for which this user is eligible;
		enrollmentData.optIns = enrollmentData.optIns.filter(program => program.isEligible);
		// Set count of eligible programs, used to determine display of advanced options;
		programsEligible = enrollmentData.optIns.filter(program => program.isEligible).length;
		// Set count of programs opted in, used to determine enrollment status;
		programsOptedIn = enrollmentData.optIns.filter(program => program.isIn).length;
		// Set flag for display of advanced options;
		displayAdvancedOptions = programsEligible - programsOptedIn > 1;

		if (programsOptedIn > 0 && programsOptedIn < enrollmentData.optIns.length) {
			enrollmentData.status = PAPRELESS_ENROLLMENT_STATUS.PART;
		} else if (programsOptedIn >= enrollmentData.optIns.length) {
			enrollmentData.status = PAPRELESS_ENROLLMENT_STATUS.FULL;
		}

		contentHeading = PAPERLESS_CONFIG.content.heading[enrollmentData.status];
		description = PAPERLESS_CONFIG.content.description[enrollmentData.status];
		({ additionalDetails } = PAPERLESS_CONFIG.content);
	}

	return (
		<DefaultTemplate isTask={true}>
			<TaskHeader
				closeCallback={handleClose}
				title="Go Paperless"
			/>
			<Container maxWidth="xs">
				<Box align="center" m="20px auto">
					<img
						alt="Mailbox Icon"
						src={`${process.env.PUBLIC_URL}/assets/general/icon-mailbox.svg`}
					/>
				</Box>
				<Form
					id="defaultFormNarrow"
					onSubmit={handleSubmit}
				>
					<Typography align="center" component="h2" gutterBottom={true} mb={1.5} variant="h5">
						{contentHeading}
					</Typography>
					<Typography paragraph={true}>
						{description}
					</Typography>
					<Typography className="cmp-paperless-programs-list" component="ul" gutterBottom={true}>
						{
							enrollmentData.optIns.map((program, key) => {
								let a11yElem = program.isIn ? (<span>Currently Enrolled:</span>) : (<span>Not Enrolled</span>);
								return (
									<Typography
										component="li"
										data-enrolled={program.isIn}
										key={key}
									>
										{a11yElem} {program.title}
									</Typography>
								);
							})
						}
					</Typography>
					<Typography paragraph={true}>
						{additionalDetails}
					</Typography>
					<Box
						mb={1.75}
						mt={5.625}
					>
						<Button
							disabled={isFetchingProfile || isUpdatingPaperlessPreferences}
							type="submit"
							variant="contained"
						>
							Get Started
						</Button>
					</Box>
					<Box mt={1.75}>
						<Button
							disabled={isFetchingProfile || isUpdatingPaperlessPreferences}
							onClick={handleRemindMeLater}
							variant="outlined"
						>
							Remind Me Later
						</Button>
					</Box>
					<Box mt={1.75}>
						<Button
							disabled={isFetchingProfile || isUpdatingPaperlessPreferences}
							onClick={handleNoThanks}
							variant="text"
						>
							No Thanks
						</Button>
					</Box>
					<Box
						mb={10}
						mt={0}
					>

						{
							displayAdvancedOptions &&
							<Button
								disabled={isFetchingProfile || isUpdatingPaperlessPreferences}
								onClick={handleAdvancedOptions}
								variant="text"
							>
								Advanced Options
							</Button>
						}
					</Box>
				</Form>
			</Container>
			<BasicModal
				open={displayModal}
				style={{ maxWidth: 480, width: "100%" }}
			>
				<Box align="right">
					<IconButton
						align="right"
						color="primary"
						onClick={() => {
							setData({ interfaceErrors: [], systemErrors: [] });
							setDisplayModal(false);
						}}
						sx={{
							justifySelf: "end",
							padding: 0
						}}
					>
						<CloseIcon fontSize="large" />
					</IconButton>
				</Box>
				<Form
					id="defaultForm"
					onSubmit={handleSubmitRemindMeLater}
				>
					<ProfileErrorMessages />
					<FormControl sx={{ width: "100%" }}>
						<FormLabel id={idFormLabel}>
							<Typography
								component="p"
								mb={2}
								mt={2}
								variant="h5"
							>
								We will remind you later. When would you like to be reminded?
							</Typography>
						</FormLabel>
						<RadioGroup
							aria-labelledby={idFormLabel}
							name="offsetDays"
						>
							{
								PAPERLESS_CONFIG.reminderOffsets.map((offset, key) => {
									return (
										<FormControlLabel
											control={<Radio required size="small" />}
											key={key}
											label={offset.label}
											value={offset.offsetDays}
										/>
									);
								})
							}
						</RadioGroup>
					</FormControl>
					<Box
						mb={1.75}
						mt={5.625}
					>
						<Button
							disabled={isUpdatingPaperlessPreferences}
							type="submit"
							variant="contained"
						>
							Save
						</Button>
					</Box>
				</Form>
			</BasicModal>
		</DefaultTemplate>
	);
};

const mapStateToProps = state => {
	return {
		isFetchingProfile: state.profile.isFetching,
		isUpdatingPaperlessPreferences: state.profile.isUpdatingPaperlessPreferences,
		profileData: state.profile.data,
		tokenLastVerifiedAt: state.auth.tokenLastVerifiedAt
	};
};

const mapDispatchToProps = dispatch => {
	return {
		setData: data => dispatch(profileActions.setData(data)),
		updatePaperlessPreferences: (data, config) => dispatch(profileEvents.updatePaperlessPreferences(data, config))
	};
};

export default withRequireProfile(connect(mapStateToProps, mapDispatchToProps)(PaperlessPreferencesView));
export {
	PAPERLESS_CONFIG,
	PAPRELESS_ENROLLMENT_STATUS,
	getPaperlessProgramConfigById,
	getPaperlessProgramIndexById
};