import * as profileActions from "../../store/actions/profile";
import Box from "@mui/material/Box";
import Button from "../../components/Button/Button";
import CheckIcon from "@mui/icons-material/Check";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import DefaultTemplate from "../templates/DefaultTemplate";
import { ERROR_MESSAGE_TYPE_DEFAULT } from "../../components/ErrorMessages/ErrorMessages";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import ProfileErrorMessages from "../../components/ErrorMessages/ProfileErrorMessages";
import React from "react";
import TaskHeader from "../../components/Task/TaskHeader";
import Typography from "@mui/material/Typography";
import { connect } from "react-redux";
import { ps } from "../../components/Application/Application";
import useNavigateQSA from "../../utils/hooks/useNavigateQSA";
import { useTheme } from "@mui/material";
import withRequireProfile from "../../utils/withRequireProfile";
import {
	Form,
	isArrayOfLength,
	isObject
} from "@pheaa/channels-component-library";
import {
	INTERFACE_ERROR_SRQ,
	PS_EVENT_TASK_PAPERLESS_PREFS_COMPLETE
} from "../../config/common";
import {
	PAPERLESS_CONFIG,
	PAPRELESS_ENROLLMENT_STATUS,
	getPaperlessProgramConfigById,
	getPaperlessProgramIndexById
} from "./PaperlessPreferencesView";

const PaperlessPreferencesAdvancedView = props => {

	const {
		paperlessPreferencesPayload,
		profileData,
		setData
	} = props;

	const navigate = useNavigateQSA();
	const theme = useTheme();

	const handleClose = () => {
		ps.publish(PS_EVENT_TASK_PAPERLESS_PREFS_COMPLETE);
	};

	let enrollmentData = {
		status: PAPRELESS_ENROLLMENT_STATUS.NONE,
		optIns: PAPERLESS_CONFIG.optIns.slice()
	};

	const handleInputChange = e => {
		const { checked, value } = e.target;
		// Update payload in store (remember selections for if a user navigates forward/backward);
		setData({
			paperlessPreferencesPayload: {
				optIns: enrollmentData.optIns.map(program => {
					let isIn = program.id === value ? checked : program.isIn;
					return { id: program.id, isIn };
				})
			}
		});
	};

	const handleSubmit = () => {
		const enrollmentCount = enrollmentData.optIns.filter(program => {
			return !program.isDisabled && program.isIn;
		}).length;

		// TODO: (Future Enhancement) When Paperless Presentment is no longer the only use-case of Paperless Preferences;
		// If user is opting in/out and never had a previous agreementVersion, confirm email and show terms;

		// If current agreementVersion does not match previous agreementVersion, we must confirm email/present terms;
		// regardless of optIn or optOut scenario;

		// If user is only opting out and has a previous agreementVersion, there is no need to confirm email/present terms;

		// For Paperless Presentment, if an enrollment is not selected, display interface error;
		if (enrollmentCount > 0) {
			// Set payload in store (it's possible no user interaction has triggered handleInputChange);
			setData({
				interfaceErrors: [],
				paperlessPreferencesPayload: {
					optIns: enrollmentData.optIns.map(program => {
						return { id: program.id, isIn: program.isIn };
					})
				},
				systemErrors: []
			});
			// Navigate to email address confirmation;
			navigate("/profile/paperless/confirm-email");
		} else {
			setData({
				interfaceErrors: [{ code: INTERFACE_ERROR_SRQ, type: ERROR_MESSAGE_TYPE_DEFAULT }]
			});
		}
	};

	if (isObject(profileData) && isObject(profileData.ecorrespondence)) {
		let { optIns = [] } = profileData.ecorrespondence;

		if (isArrayOfLength(optIns)) {
			// Iterate over optIns;
			optIns.forEach(optIn => {
				// Set enrollmentData for optIn by index (maintaining sortability);
				enrollmentData.optIns[getPaperlessProgramIndexById(optIn.id)] = {
					...getPaperlessProgramConfigById(optIn.id),
					isDisabled: optIn.isIn,
					isEligible: optIn.isEligible,
					isIn: true
				};
			});
		}

		if (isObject(paperlessPreferencesPayload) && isArrayOfLength(paperlessPreferencesPayload.optIns)) {
			paperlessPreferencesPayload.optIns.forEach(program => {
				enrollmentData.optIns[getPaperlessProgramIndexById(program.id)].isIn = program.isIn;
			});
		}

		// Filter optIns array to include only those for which this user is eligible;
		enrollmentData.optIns = enrollmentData.optIns.filter(program => program.isEligible);
	}

	return (
		<DefaultTemplate isTask={true}>
			<TaskHeader
				backDestination="/profile/paperless"
				closeCallback={handleClose}
				title="Go Paperless"
			/>
			<Container maxWidth="xs">
				<Box align="center" m="20px auto" p="20px 0 0">
					<img
						alt="Mailbox Icon"
						src={`${process.env.PUBLIC_URL}/assets/general/icon-mailbox.svg`}
					/>
				</Box>
				<Form id="defaultForm" onSubmit={handleSubmit}>
					<Typography align="center" component="h2" gutterBottom={true} mb={1.5} variant="h5">
						Advanced Paperless Options
					</Typography>
					<Typography mt={4} paragraph={true}>
						I would like to receive the following electronically:
					</Typography>
					<ProfileErrorMessages />
					<FormGroup>
						{
							isObject(profileData) && isObject(profileData.ecorrespondence) && enrollmentData.optIns.map((program, key) => {
								let checkboxStyles = { alignSelf: "flex-start" };
								let CheckElem = program.isDisabled ? CheckIcon : Checkbox;

								if (program.isDisabled) {
									checkboxStyles["&.Mui-disabled"] = { color: theme.palette.success.main };
									checkboxStyles["margin"] = "9px";
								}

								return (
									<FormControlLabel
										control={
											<CheckElem
												checked={program.isDisabled || program.isIn}
												color={program.isDisabled ? "success" : "primary"}
												disabled={program.isDisabled}
												onChange={handleInputChange}
												sx={checkboxStyles}
												value={program.id}
											/>
										}
										icon={program.isDisabled ? <CheckIcon /> : null}
										key={key}
										label={(
											<React.Fragment>
												<Typography
													gutterBottom={true}
													mt="12px"
													sx={program.isDisabled ? { color: theme.palette.text.primary } : null}
													variant="h5"
												>
													{program.title}
												</Typography>
												<Typography
													gutterBottom={true}
													sx={program.isDisabled ? { color: theme.palette.text.primary } : null}
												>
													{program.description}
												</Typography>
											</React.Fragment>
										)}
									/>
								);
							})
						}
					</FormGroup>
					<Box
						mb={10}
						mt={5.625}
					>
						<Button
							type="submit"
							variant="contained"
						>
							Get Started
						</Button>
					</Box>
				</Form>
			</Container>
		</DefaultTemplate>
	);
};

const mapStateToProps = state => {
	return {
		paperlessPreferencesPayload: state.profile.paperlessPreferencesPayload,
		profileData: state.profile.data,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		setData: data => dispatch(profileActions.setData(data))
	};
};

export default withRequireProfile(connect(mapStateToProps, mapDispatchToProps)(PaperlessPreferencesAdvancedView));