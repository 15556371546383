import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import React from "react";
import SkipLinks from "../../components/SkipLinks/SkipLinks";
import {
	TEMPLATE_ID_DEFAULT,
	TEMPLATE_ID_TASK
} from "../../config/templates";

const DefaultTemplate = props => {

	const {
		children,
		isTask = false
	} = props;

	return (
		<React.Fragment>
			<SkipLinks isTask={isTask} />
			{!isTask && <Header />}
			<Box
				component="main"
				data-template-id={isTask ? TEMPLATE_ID_TASK : TEMPLATE_ID_DEFAULT}
				id="main"
				sx={{ minHeight: "50vh" }}
			>
				<Container
					disableGutters={isTask}
					maxWidth={isTask ? "md" : "lg"}
				>
					{children}
				</Container>
			</Box>
			{!isTask && <Footer />}
		</React.Fragment>
	);
};

export default DefaultTemplate;