import * as commonActions from "../../store/actions/common";
import Box from "@mui/material/Box";
import CircularIndeterminate from "../Loading/CircularIndeterminate";
import { connect } from "react-redux";
import useNavigateQSA from "../../utils/hooks/useNavigateQSA";
import withRequireProfile from "../../utils/withRequireProfile";
import React, { useEffect } from "react";
import { isArrayOfLength, isBoolean, isObject, isStringOfLength } from "@pheaa/channels-component-library";

export const QUEUE_TASK_ROOT = "root";
export const QUEUE_TASK_PAPERLESS_PRESENTMENT = "paperless-presentment";

const taskManagerConfig = {
	[QUEUE_TASK_PAPERLESS_PRESENTMENT]: {
		onEndTask: "/post-auth"
	}
};

const navigateConfig = { replace: true };

const PostAuthTaskQueue = props => {

	const {
		profile,
		setDataCommon,
		taskManager
	} = props;

	const [returnElem, setReturnElem] = React.useState(null);
	const navigate = useNavigateQSA();

	useEffect(() => {

		if (!profile.isFetching && !profile.isFetchFailed) {

			let taskQueue = [];

			if (isObject(profile) && isObject(profile.data) && isStringOfLength(profile.data.id)) {
				if (isObject(profile.data.ecorrespondence) && isBoolean(profile.data.ecorrespondence.isPresentmentNeeded)) {
					profile.data.ecorrespondence.isPresentmentNeeded && taskQueue.push(QUEUE_TASK_PAPERLESS_PRESENTMENT);
				}
			}

			taskQueue = taskQueue.filter(taskId => {
				return !taskManager.completeTasks.includes(taskId);
			});

			if (isArrayOfLength(taskQueue)) {
				switch (taskQueue[0]) {
					case QUEUE_TASK_PAPERLESS_PRESENTMENT:
						setDataCommon({
							taskManager: {
								currentId: QUEUE_TASK_PAPERLESS_PRESENTMENT,
								...taskManagerConfig[QUEUE_TASK_PAPERLESS_PRESENTMENT]
							}
						});
						navigate("/profile/paperless", navigateConfig);
						break;
					default:
						break;
				}
			} else {
				navigate("/transfer", navigateConfig);
			}
			setReturnElem(null);
		} else if (!profile.isFetching) {
			navigate("/transfer", navigateConfig);
			setReturnElem(null);
		} else {
			setReturnElem(
				<Box sx={{ alignItems: "center", display: "flex", justifyContent: "center", margin: "2rem auto" }}>
					<CircularIndeterminate />
				</Box>
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		profile,
		setDataCommon
	]);

	return returnElem;
};

const mapStateToProps = state => {
	return {
		profile: state.profile,
		taskManager: state.common.taskManager
	};
};

const mapDispatchToProps = dispatch => {
	return {
		setDataCommon: data => dispatch(commonActions.setData(data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRequireProfile(PostAuthTaskQueue));