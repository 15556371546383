import * as profileActions from "../../store/actions/profile";
import * as profileEvents from "../../store/events/profile";
import BasicModal from "../../components/Modal/BasicModal";
import Box from "@mui/material/Box";
import Button from "../../components/Button/Button";
import CloseIcon from "@mui/icons-material/Close";
import Container from "@mui/material/Container";
import DefaultTemplate from "../templates/DefaultTemplate";
import FormTextField from "../../components/Form/FormTextField";
import IconButton from "@mui/material/IconButton";
import ProfileErrorMessages from "../../components/ErrorMessages/ProfileErrorMessages";
import Skeleton from "@mui/material/Skeleton";
import TaskHeader from "../../components/Task/TaskHeader";
import Typography from "@mui/material/Typography";
import { connect } from "react-redux";
import { ps } from "../../components/Application/Application";
import useNavigateQSA from "../../utils/hooks/useNavigateQSA";
import withRequireProfile from "../../utils/withRequireProfile";
import {
	Form,
	isObject
} from "@pheaa/channels-component-library";
import {
	HTML_ATTR_PATTERN_EMAIL,
	PS_EVENT_TASK_PAPERLESS_PREFS_COMPLETE
} from "../../config/common";
import React, { useState } from "react";

const PaperlessPreferencesConfirmEmailView = props => {

	let contactEmailAddr = <Skeleton sx={{ fontSize: "2.4rem", margin: "0 auto" }} width={250} />;
	let contactEmailNotFound = false;

	const {
		isUpdatingContactEmail,
		profileData,
		setData,
		updateContactEmailAddress
	} = props;

	const [displayModal, setDisplayModal] = useState(false);
	const navigate = useNavigateQSA();

	const handleClose = () => {
		ps.publish(PS_EVENT_TASK_PAPERLESS_PREFS_COMPLETE);
	};

	const handleSubmit = () => {
		setData({ interfaceErrors: [], systemErrors: [] });
		navigate("/profile/paperless/terms");
	};

	const handleUpdate = (e, formData) => {
		updateContactEmailAddress(formData, {
			successCallback: () => {
				setDisplayModal(false);
			}
		});
	};

	if (isObject(profileData)) {
		try {
			contactEmailAddr = profileData.emails.find(emailObj => emailObj.id === "contact").email;
		} catch (e) {
			contactEmailAddr = "Email Address Not Found";
			contactEmailNotFound = true;
		}
	}

	return (
		<DefaultTemplate isTask={true}>
			<TaskHeader
				backCallback={() => {
					navigate(-1);
					setData({ interfaceErrors: [], systemErrors: [] });
				}}
				closeCallback={handleClose}
				title="Confirm Email"
			/>
			<Container maxWidth="sm">
				<Box align="center" m="20px auto" p="20px 0 0">
					<img
						alt="Mailbox Icon"
						src={`${process.env.PUBLIC_URL}/assets/general/icon-mailbox.svg`}
					/>
				</Box>
				<Typography paragraph={true}>
					We use your contact email address in your profile to send you an email each time we deliver these
					communications. If you update your email address it will be updated in your profile.
				</Typography>
				<Form id="defaultFormNarrow" onSubmit={handleSubmit}>
					<ProfileErrorMessages />
					<Typography align="center" mt={4} paragraph={true} variant="h5">
						{contactEmailAddr}
					</Typography>
					<Box
						mb={1.75}
						mt={5.625}
					>
						<Button
							disabled={contactEmailNotFound}
							type="submit"
							variant="contained"
						>
							Email is Correct
						</Button>
					</Box>
					<Box
						mb={10}
						mt={1.75}
					>
						<Button
							onClick={() => {
								setData({ interfaceErrors: [], systemErrors: [] });
								setDisplayModal(true);
							}}
							variant="outlined"
						>
							Change Email
						</Button>
					</Box>
				</Form>
			</Container>
			<BasicModal
				open={displayModal}
				style={{ maxWidth: 480, width: "100%" }}
			>
				<Box align="right">
					<IconButton
						align="right"
						color="primary"
						onClick={() => {
							setData({ interfaceErrors: [], systemErrors: [] });
							setDisplayModal(false);
						}}
						sx={{
							justifySelf: "end",
							padding: 0
						}}
					>
						<CloseIcon fontSize="large" />
					</IconButton>
				</Box>
				<Typography
					gutterBottom={true}
					mb={1.75}
					variant="h4"
				>
					Update Contact Email
				</Typography>
				<Typography paragraph={true}>
					This will update the email in your profile.
				</Typography>
				<Form
					id="defaultFormNarrow"
					onSubmit={handleUpdate}
				>
					<ProfileErrorMessages
						systemErrorMessageOverrides={{ 400: { message: "Please provide a valid email address." } }}
					/>
					<FormTextField
						includeConfirmation={true}
						name="email"
						pattern={HTML_ATTR_PATTERN_EMAIL}
						required={true}
						title="Email"
					/>
					<Box
						mb={1.75}
						mt={5.625}
					>
						<Button
							disabled={isUpdatingContactEmail}
							type="submit"
							variant="contained"
						>
							Save
						</Button>
					</Box>
				</Form>
			</BasicModal>
		</DefaultTemplate>
	);
};

const mapStateToProps = state => {
	return {
		isUpdatingContactEmail: state.profile.isUpdatingContactEmail,
		profileData: state.profile.data
	};
};

const mapDispatchToProps = dispatch => {
	return {
		setData: data => dispatch(profileActions.setData(data)),
		updateContactEmailAddress: (data, config) => dispatch(profileEvents.updateContactEmailAddress(data, config))
	};
};

export default withRequireProfile(connect(mapStateToProps, mapDispatchToProps)(PaperlessPreferencesConfirmEmailView));