import DefaultTemplate from "../templates/DefaultTemplate";
import React from "react";
import TaskHeader from "../../components/Task/TaskHeader";
import VerifyInfo from "../../components/IAM/VerifyInfo";
import {
	IAM_FLOW_AUTHENTICATE,
	getFlowRootStage,
	getPathForFlowAndStage,
	hasAuthId,
	withAuthCondition
} from "../utils/iamUtils";

const AuthVerifyInfoView = () => {

	return (
		<DefaultTemplate isTask={true}>
			<TaskHeader
				closeResetsAuthFlow={true}
				closeTaskDestination={`/${getPathForFlowAndStage(IAM_FLOW_AUTHENTICATE, getFlowRootStage(IAM_FLOW_AUTHENTICATE))}`}
				title="Personal Information"
			/>
			<VerifyInfo />
		</DefaultTemplate>
	);
};

export default withAuthCondition(AuthVerifyInfoView, hasAuthId, IAM_FLOW_AUTHENTICATE);