import * as commonEvents from "../../store/events/common";
import Box from "@mui/material/Box";
import DefaultTemplate from "../templates/DefaultTemplate";
import { MAINTENANCE_RECHECK_THRESHOLD } from "../../config/common";
import React from "react";
import Typography from "@mui/material/Typography";
import { connect } from "react-redux";
import useInterval from "../../utils/hooks/useInterval";

const MaintenanceView = props => {

	const { getMaintenanceStatus } = props;

	useInterval(() => {
		getMaintenanceStatus();
	}, MAINTENANCE_RECHECK_THRESHOLD);

	return (
		<DefaultTemplate>
			<Box
				sx={{
					alignItems: "center",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					margin: "7rem auto 4rem",
					maxWidth: "sm"
				}}
			>
				<Box
					alt="Down for Maintenance"
					component="img"
					src={`${process.env.PUBLIC_URL}/assets/general/icon-maintenance.svg`}
					sx={{
						display: "block",
						height: 169,
						margin: "0 auto 2rem",
						width: 175
					}}
				/>
				<Typography align="center" fontSize={24} gutterBottom={true} component="h1" variant="h2">
					System Maintenance in Progress
				</Typography>
				<Typography align="center" fontSize={16} gutterBottom={true} paragraph={true} >
					The site is currently down for maintenance. We apologize for any inconvenience and thank you for your patience.
				</Typography>
			</Box>
		</DefaultTemplate>
	);
};

const mapStateToProps = state => {
	return {};
};

const mapDispatchToProps = dispatch => {
	return {
		getMaintenanceStatus: () => dispatch(commonEvents.getMaintenanceStatus())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceView);