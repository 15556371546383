import * as authEvents from "../../store/events/auth";
import Box from "@mui/material/Box";
import Button from "../../components/Button/Button";
import DefaultTemplate from "../templates/DefaultTemplate";
import { Form } from "@pheaa/channels-component-library";
import FormTextField from "../../components/Form/FormTextField";
import IAMErrorMessages from "../../components/ErrorMessages/IAMErrorMessages";
import { InteractionProcessTimeoutA11y } from "../../components/IAM/A11y";
import React from "react";
import TaskHeader from "../../components/Task/TaskHeader";
import { connect } from "react-redux";
import {
	HTML_ATTR_PATTERN_DOB,
	HTML_ATTR_PATTERN_NAME_FIRST,
	HTML_ATTR_PATTERN_NAME_LAST,
	HTML_ATTR_PATTERN_SSN_ACCOUNT_NO
} from "../../config/common";
import {
	IAM_FLOW_AUTHENTICATE,
	getFlowRootStage,
	getPathForFlowAndStage
} from "../utils/iamUtils";

const RegistrationIdentificationView = props => {

	const {
		isPendingIAM,
		submitRegistrationIdentification
	} = props;

	const handleSubmit = (e, formData) => {
		submitRegistrationIdentification(formData);
	};

	return (
		<DefaultTemplate isTask={true}>
			<TaskHeader
				closeResetsAuthFlow={true}
				closeTaskDestination={`/${getPathForFlowAndStage(IAM_FLOW_AUTHENTICATE, getFlowRootStage(IAM_FLOW_AUTHENTICATE))}`}
				title="Create an Account"
			/>
			<Form
				id="defaultFormNarrow"
				onSubmit={handleSubmit}
			>
				<IAMErrorMessages />
				<InteractionProcessTimeoutA11y />
				<FormTextField
					includeConfirmation={true}
					name="accountIdentifier"
					pattern={HTML_ATTR_PATTERN_SSN_ACCOUNT_NO}
					required={true}
					title="Account Number or SSN"
				/>
				<FormTextField
					name="firstName"
					pattern={HTML_ATTR_PATTERN_NAME_FIRST}
					required={true}
					title="First Name"
				/>
				<FormTextField
					name="lastName"
					pattern={HTML_ATTR_PATTERN_NAME_LAST}
					required={true}
					title="Last Name"
				/>
				<FormTextField
					helpMessage="MM/DD/YYYY"
					name="birthDate"
					pattern={HTML_ATTR_PATTERN_DOB}
					required={true}
					title="Date of Birth"
				/>
				<Box
					mb={1.75}
					mt={5.625}
				>
					<Button
						disabled={isPendingIAM}
						type="submit"
						variant="contained"
					>
						Continue
					</Button>
				</Box>
			</Form>
		</DefaultTemplate>
	);
};

const mapStateToProps = state => {
	return {
		isPendingIAM: state.auth.isPendingIAM
	};
};

const mapDispatchToProps = dispatch => {
	return {
		submitRegistrationIdentification: data => dispatch(authEvents.submitRegistrationIdentification(data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationIdentificationView);