import { isObject, isStringOfLength } from "@pheaa/channels-component-library";

export const CONFIG_FILENAME = "config.json";

export const currentDate = (() => {
	let _currentDate = new Date();

	return {
		getTime: () => {
			return _currentDate.getTime();
		},
		getYear: () => {
			return _currentDate.getUTCFullYear();
		},
		setCurrentDate: date => {
			_currentDate = date;
			return _currentDate;
		}
	};
})();

// eslint-disable-next-line no-useless-escape
export const HTML_ATTR_PATTERN_DOB = "(?:(?:0?[1-9]|1[012])[\\\/.,\\-](?:0?[1-9]|1\\d|2[0-8])|(?:0?[13456789]|1[012])[\\\/.,\\-](?:29|30)|(?:0?[13578]|1[02])[\\\/.,\\-]31)[\\\/.,\\-](?:19|[2-9]\\d)\\d{2}|0?2[\\\/.,\\-]29[\\\/.,\\-](?:(?:19|[2-9]\\d)(?:0[48]|[2468][048]|[13579][26])|(?:(?:[2468][048]|[3579][26])00))";
// eslint-disable-next-line no-useless-escape
export const HTML_ATTR_PATTERN_EMAIL = "[a-zA-Z0-9!#$%&'*+\\\/=?^_`\\{\\}~\\-]+(?:\.[a-zA-Z0-9!#$%&'*+\\\/=?^_`\\{\\}~\\-]+)*@[a-zA-Z0-9]+(?:[\\-\.]{1}[a-z0-9]+)*\\.[a-zA-Z]{2,18}";
// eslint-disable-next-line no-useless-escape
export const HTML_ATTR_PATTERN_NAME_FIRST = "[a-zA-Z'\\-\\s]{1,13}";
// eslint-disable-next-line no-useless-escape
export const HTML_ATTR_PATTERN_NAME_LAST = "[a-zA-Z'\\-\\s]{1,35}";
// eslint-disable-next-line no-useless-escape
export const HTML_ATTR_PATTERN_PASSCODE = "[0-9]{6}";
// eslint-disable-next-line no-useless-escape
export const HTML_ATTR_PATTERN_PASSWORD = "(?!\\s)(?=.*[A-Za-z])(?=.*\\d)[a-zA-Z0-9!\\\-#$%*+=,\\.?\\/\\\\&_^@]{8,20}(?<!\\s)$|^(?!\\s).{21,256}(?<!\\s)";
// eslint-disable-next-line no-useless-escape
export const HTML_ATTR_PATTERN_SSN = "([0-9]{9}|[0-9]{3}-[0-9]{2}-[0-9]{4})";
// eslint-disable-next-line no-useless-escape
export const HTML_ATTR_PATTERN_SSN_ACCOUNT_NO = "([0-9]{9}|[0-9]{3}-[0-9]{2}-[0-9]{4}|[0-9]{10})";
// eslint-disable-next-line no-useless-escape
export const HTML_ATTR_PATTERN_SSN_LAST_4_ACCOUNT_NO = "([0-9]{4}|[0-9]{10})";
// eslint-disable-next-line no-useless-escape
export const HTML_ATTR_PATTERN_USER_ID = "[a-zA-Z0-9_]{6,12}";

export const INTERFACE_ERROR_CEU = "contactEmailUpdated";
export const INTERFACE_ERROR_MESSAGE_CEU = "Email has been updated.";
export const INTERFACE_ERROR_MESSAGE_SRQ = "Please make a selection.";
export const INTERFACE_ERROR_SRQ = "selectionRequired";
export const INTERFACE_ERROR_TYPE_DEFAULT = "error";
export const INTERFACE_ERROR_TYPE_CONFIRM = "confirm";

export const INTERFACE_ERRORS = {
	[INTERFACE_ERROR_CEU]: { message: INTERFACE_ERROR_MESSAGE_CEU },
	[INTERFACE_ERROR_SRQ]: { message: INTERFACE_ERROR_MESSAGE_SRQ }
};

export const MAINTENANCE_RECHECK_THRESHOLD = 60000;

export const PATHNAME_HOME_VIEW_AUTHENTICATED = "/profile";
export const PATHNAME_HOME_VIEW_UNAUTHENTICATED = "/sign-in";

export const PS_EVENT_AUTH_INTERACTION_RESET_DISMISS = "authInteraction:resetDismiss";
export const PS_EVENT_LOGOUT_INVALID_TOKEN = "logout:invalidToken";
export const PS_EVENT_SESSION_END = "session:end";
export const PS_EVENT_SESSION_EXPIRED = "session:expired";
export const PS_EVENT_SESSION_EXTEND = "session:extend";
export const PS_EVENT_TASK_PAPERLESS_PREFS_COMPLETE = "task:paperless-preferences:complete";

export const RE_LOCALHOST = /localhost:\d+/g;

export const RETURN_KEY_CONSUMER_PORTAL = "CONSUMER-PORTAL";

export const SYSTEM_ERROR_400 = 400;
export const SYSTEM_ERROR_401 = 401;
export const SYSTEM_ERROR_403 = 403;
export const SYSTEM_ERROR_404 = 404;
export const SYSTEM_ERROR_409 = 409;
export const SYSTEM_ERROR_500 = 500;
export const SYSTEM_ERROR_502 = 502;
export const SYSTEM_ERROR_503 = 503;
export const SYSTEM_ERROR_CNX = "sysErrConnection";
export const SYSTEM_ERROR_INV = "sysErrInvalid";
export const SYSTEM_ERROR_LTK = "sysErrLegacyTransferKey";
export const SYSTEM_ERROR_RSP = "sysErrInvalidResponse";
export const SYSTEM_ERROR_MESSAGE_502 = "We're sorry, but the service is unavailable at this time. We apologize for any inconvenience. Please try again later.";
export const SYSTEM_ERROR_MESSAGE_503 = "We're sorry, but the service is unavailable at this time. We apologize for any inconvenience. Please try again later.";
export const SYSTEM_ERROR_MESSAGE_CNX = "We're sorry, but a connection could not be established. We apologize for any inconvenience and thank you for your patience.";
export const SYSTEM_ERROR_MESSAGE_DEFAULT = "We're sorry, but something went wrong. We apologize for any inconvenience. Please try again later.";
export const SYSTEM_ERROR_MESSAGE_INV = "We're sorry, but the request was invalid. We apologize for any inconvenience. Please try again later.";
export const SYSTEM_ERROR_MESSAGE_MNT = "The site is currently down for maintenance. We apologize for any inconvenience and thank you for your patience.";
export const SYSTEM_ERROR_MESSAGE_RSP = "An invalid response was received. We apologize for any inconvenience and thank you for your patience.";
export const SYSTEM_ERROR_MESSAGE_LTK = "We're sorry, but an unexpected error occurred while getting your transfer token. We apologize for any inconvenience and thank you for your patience.";

export const SYSTEM_ERRORS = {
	[SYSTEM_ERROR_400]: { message: SYSTEM_ERROR_MESSAGE_DEFAULT },
	[SYSTEM_ERROR_401]: { message: SYSTEM_ERROR_MESSAGE_DEFAULT },
	[SYSTEM_ERROR_403]: { message: SYSTEM_ERROR_MESSAGE_DEFAULT },
	[SYSTEM_ERROR_404]: { message: SYSTEM_ERROR_MESSAGE_DEFAULT },
	[SYSTEM_ERROR_409]: { message: SYSTEM_ERROR_MESSAGE_MNT },
	[SYSTEM_ERROR_500]: { message: SYSTEM_ERROR_MESSAGE_DEFAULT },
	[SYSTEM_ERROR_502]: { message: SYSTEM_ERROR_MESSAGE_502 },
	[SYSTEM_ERROR_503]: { message: SYSTEM_ERROR_MESSAGE_503 },
	[SYSTEM_ERROR_CNX]: { message: SYSTEM_ERROR_MESSAGE_CNX },
	[SYSTEM_ERROR_INV]: { message: SYSTEM_ERROR_MESSAGE_INV },
	[SYSTEM_ERROR_LTK]: { message: SYSTEM_ERROR_MESSAGE_LTK },
	[SYSTEM_ERROR_RSP]: { message: SYSTEM_ERROR_MESSAGE_RSP }
};

export const clearInputValue = inputSelector => {
	document.querySelector(inputSelector).value = null;
};

export const getErrorMessageForCode = (code, customErrors = {}) => {
	const errors = { ...SYSTEM_ERRORS, ...INTERFACE_ERRORS, ...customErrors };
	return (isObject(errors[code]) && isStringOfLength(errors[code].message)) ? errors[code].message : SYSTEM_ERROR_MESSAGE_DEFAULT;
};